import { Component, Input, OnInit } from '@angular/core';
import { Product } from '../../../model/Product';
import { Store } from '@ngrx/store';
import { CartState } from '../../../store/cart/CartReducer';
import { Router } from '@angular/router';
import { CategoryState } from '../../../store/category/CategoryReducer';

@Component({
  selector: 'app-product',
  templateUrl: './product.component.html'
})
export class ProductComponent implements OnInit {

  @Input()
  product: Product;
  @Input()
  link: string

  constructor(private readonly cartStore: Store<CartState>,
              private readonly categoryStore: Store<CategoryState>,
              private router: Router) {
  }

  ngOnInit(): void {
  }

  navigateToProduct() {
    this.router.navigateByUrl(`${this.link}/${this.product.articleNumber}`);
  }
}
