export const FILTERS = {
  filters: {
    0: {
      priceRange: {
        min: 100,
        max: 20000
      },
      properties: {
        manufacturers: [
          'Gladio',
          'Trabucco',
          'Haldorádó',
          'Karp',
          'Drake'
        ],
        u_hossz: [
          '1 m',
          '3 m',
          '35 mm',
          '60 cm',
          '70 mm',
          '90 mm',
          '93 mm',
          '200 cm',
          '390 mm'
        ],
        u_atmero: [
          '2,5 mm',
          '3,0 mm',
          '4 mm',
          '5 mm',
          '6 mm',
          '10 mm'
        ],
        u_anyaga: [
          'PE',
          'Nylon',
          'Gumi',
          'ALU',
          'carbon'
        ]
      }
    },
    1: {
      priceRange: {
        min: 100,
        max: 20000
      },
      properties: {
        manufacturers: [
          'Gladio',
          'Trabucco',
          'Haldorádó',
          'Karp',
          'Drake'
        ],
        u_hossz: [
          '1 m',
          '3 m',
          '35 mm',
          '60 cm',
          '70 mm',
          '90 mm',
          '93 mm',
          '200 cm',
          '390 mm'
        ],
        u_atmero: [
          '2,5 mm',
          '3,0 mm',
          '4 mm',
          '5 mm',
          '6 mm',
          '10 mm'
        ],
        u_anyaga: [
          'PE',
          'Nylon',
          'Gumi',
          'ALU',
          'carbon'
        ]
      }
    },
    2: {
      priceRange: {
        min: 100,
        max: 20000
      },
      properties: {
        manufacturers: [
          'Gladio',
          'Trabucco',
          'Haldorádó',
          'Karp',
          'Drake'
        ],
        u_hossz: [
          '1 m',
          '3 m',
          '35 mm',
          '60 cm',
          '70 mm',
          '90 mm',
          '93 mm',
          '200 cm',
          '390 mm'
        ],
        u_atmero: [
          '2,5 mm',
          '3,0 mm',
          '4 mm',
          '5 mm',
          '6 mm',
          '10 mm'
        ],
        u_anyaga: [
          'PE',
          'Nylon',
          'Gumi',
          'ALU',
          'carbon'
        ]
      }
    },
    3: {
      priceRange: {
        min: 100,
        max: 20000
      },
      properties: {
        manufacturers: [
          'Gladio',
          'Trabucco',
          'Haldorádó',
          'Karp',
          'Drake'
        ],
        u_hossz: [
          '1 m',
          '3 m',
          '35 mm',
          '60 cm',
          '70 mm',
          '90 mm',
          '93 mm',
          '200 cm',
          '390 mm'
        ],
        u_atmero: [
          '2,5 mm',
          '3,0 mm',
          '4 mm',
          '5 mm',
          '6 mm',
          '10 mm'
        ],
        u_anyaga: [
          'PE',
          'Nylon',
          'Gumi',
          'ALU',
          'carbon'
        ]
      }
    },
    4: {
      priceRange: {
        min: 100,
        max: 20000
      },
      properties: {
        manufacturers: [
          'Gladio',
          'Trabucco',
          'Haldorádó',
          'Karp',
          'Drake'
        ],
        u_hossz: [
          '1 m',
          '3 m',
          '35 mm',
          '60 cm',
          '70 mm',
          '90 mm',
          '93 mm',
          '200 cm',
          '390 mm'
        ],
        u_atmero: [
          '2,5 mm',
          '3,0 mm',
          '4 mm',
          '5 mm',
          '6 mm',
          '10 mm'
        ],
        u_anyaga: [
          'PE',
          'Nylon',
          'Gumi',
          'ALU',
          'carbon'
        ]
      }
    },
    5: {
      priceRange: {
        min: 100,
        max: 20000
      },
      properties: {
        manufacturers: [
          'Gladio',
          'Trabucco',
          'Haldorádó',
          'Karp',
          'Drake'
        ],
        u_hossz: [
          '1 m',
          '3 m',
          '35 mm',
          '60 cm',
          '70 mm',
          '90 mm',
          '93 mm',
          '200 cm',
          '390 mm'
        ],
        u_atmero: [
          '2,5 mm',
          '3,0 mm',
          '4 mm',
          '5 mm',
          '6 mm',
          '10 mm'
        ],
        u_anyaga: [
          'PE',
          'Nylon',
          'Gumi',
          'ALU',
          'carbon'
        ]
      }
    },
    6: {
      priceRange: {
        min: 100,
        max: 20000
      },
      properties: {
        manufacturers: [
          'Gladio',
          'Trabucco',
          'Haldorádó',
          'Karp',
          'Drake'
        ],
        u_hossz: [
          '1 m',
          '3 m',
          '35 mm',
          '60 cm',
          '70 mm',
          '90 mm',
          '93 mm',
          '200 cm',
          '390 mm'
        ],
        u_atmero: [
          '2,5 mm',
          '3,0 mm',
          '4 mm',
          '5 mm',
          '6 mm',
          '10 mm'
        ],
        u_anyaga: [
          'PE',
          'Nylon',
          'Gumi',
          'ALU',
          'carbon'
        ]
      }
    },
    7: {
      priceRange: {
        min: 100,
        max: 20000
      },
      properties: {
        manufacturers: [
          'Gladio',
          'Trabucco',
          'Haldorádó',
          'Karp',
          'Drake'
        ],
        u_hossz: [
          '1 m',
          '3 m',
          '35 mm',
          '60 cm',
          '70 mm',
          '90 mm',
          '93 mm',
          '200 cm',
          '390 mm'
        ],
        u_atmero: [
          '2,5 mm',
          '3,0 mm',
          '4 mm',
          '5 mm',
          '6 mm',
          '10 mm'
        ],
        u_anyaga: [
          'PE',
          'Nylon',
          'Gumi',
          'ALU',
          'carbon'
        ]
      }
    },
    8: {
      priceRange: {
        min: 100,
        max: 20000
      },
      properties: {
        manufacturers: [
          'Gladio',
          'Trabucco',
          'Haldorádó',
          'Karp',
          'Drake'
        ],
        u_hossz: [
          '1 m',
          '3 m',
          '35 mm',
          '60 cm',
          '70 mm',
          '90 mm',
          '93 mm',
          '200 cm',
          '390 mm'
        ],
        u_atmero: [
          '2,5 mm',
          '3,0 mm',
          '4 mm',
          '5 mm',
          '6 mm',
          '10 mm'
        ],
        u_anyaga: [
          'PE',
          'Nylon',
          'Gumi',
          'ALU',
          'carbon'
        ]
      }
    },
    9: {
      priceRange: {
        min: 100,
        max: 20000
      },
      properties: {
        manufacturers: [
          'Gladio',
          'Trabucco',
          'Haldorádó',
          'Karp',
          'Drake'
        ],
        u_hossz: [
          '1 m',
          '3 m',
          '35 mm',
          '60 cm',
          '70 mm',
          '90 mm',
          '93 mm',
          '200 cm',
          '390 mm'
        ],
        u_atmero: [
          '2,5 mm',
          '3,0 mm',
          '4 mm',
          '5 mm',
          '6 mm',
          '10 mm'
        ],
        u_anyaga: [
          'PE',
          'Nylon',
          'Gumi',
          'ALU',
          'carbon'
        ]
      }
    },
    111: {
      priceRange: {
        min: 100,
        max: 20000
      },
      properties: {
        manufacturers: [
          'Gladio',
          'Trabucco',
          'Haldorádó',
          'Karp',
          'Drake'
        ],
        u_hossz: [
          '1 m',
          '3 m',
          '35 mm',
          '60 cm',
          '70 mm',
          '90 mm',
          '93 mm',
          '200 cm',
          '390 mm'
        ],
        u_atmero: [
          '2,5 mm',
          '3,0 mm',
          '4 mm',
          '5 mm',
          '6 mm',
          '10 mm'
        ],
        u_anyaga: [
          'PE',
          'Nylon',
          'Gumi',
          'ALU',
          'carbon'
        ]
      }
    },
    12: {
      priceRange: {
        min: 100,
        max: 20000
      },
      properties: {
        manufacturers: [
          'Gladio',
          'Trabucco',
          'Haldorádó',
          'Karp',
          'Drake'
        ],
        u_hossz: [
          '1 m',
          '3 m',
          '35 mm',
          '60 cm',
          '70 mm',
          '90 mm',
          '93 mm',
          '200 cm',
          '390 mm'
        ],
        u_atmero: [
          '2,5 mm',
          '3,0 mm',
          '4 mm',
          '5 mm',
          '6 mm',
          '10 mm'
        ],
        u_anyaga: [
          'PE',
          'Nylon',
          'Gumi',
          'ALU',
          'carbon'
        ]
      }
    },
    13: {
      priceRange: {
        min: 100,
        max: 20000
      },
      properties: {
        manufacturers: [
          'Gladio',
          'Trabucco',
          'Haldorádó',
          'Karp',
          'Drake'
        ],
        u_hossz: [
          '1 m',
          '3 m',
          '35 mm',
          '60 cm',
          '70 mm',
          '90 mm',
          '93 mm',
          '200 cm',
          '390 mm'
        ],
        u_atmero: [
          '2,5 mm',
          '3,0 mm',
          '4 mm',
          '5 mm',
          '6 mm',
          '10 mm'
        ],
        u_anyaga: [
          'PE',
          'Nylon',
          'Gumi',
          'ALU',
          'carbon'
        ]
      }
    },
    14: {
      priceRange: {
        min: 100,
        max: 20000
      },
      properties: {
        manufacturers: [
          'Gladio',
          'Trabucco',
          'Haldorádó',
          'Karp',
          'Drake'
        ],
        u_hossz: [
          '1 m',
          '3 m',
          '35 mm',
          '60 cm',
          '70 mm',
          '90 mm',
          '93 mm',
          '200 cm',
          '390 mm'
        ],
        u_atmero: [
          '2,5 mm',
          '3,0 mm',
          '4 mm',
          '5 mm',
          '6 mm',
          '10 mm'
        ],
        u_anyaga: [
          'PE',
          'Nylon',
          'Gumi',
          'ALU',
          'carbon'
        ]
      }
    },
    15: {
      priceRange: {
        min: 100,
        max: 20000
      },
      properties: {
        manufacturers: [
          'Gladio',
          'Trabucco',
          'Haldorádó',
          'Karp',
          'Drake'
        ],
        u_hossz: [
          '1 m',
          '3 m',
          '35 mm',
          '60 cm',
          '70 mm',
          '90 mm',
          '93 mm',
          '200 cm',
          '390 mm'
        ],
        u_atmero: [
          '2,5 mm',
          '3,0 mm',
          '4 mm',
          '5 mm',
          '6 mm',
          '10 mm'
        ],
        u_anyaga: [
          'PE',
          'Nylon',
          'Gumi',
          'ALU',
          'carbon'
        ]
      }
    },
    16: {
      priceRange: {
        min: 100,
        max: 20000
      },
      properties: {
        manufacturers: [
          'Gladio',
          'Trabucco',
          'Haldorádó',
          'Karp',
          'Drake'
        ],
        u_hossz: [
          '1 m',
          '3 m',
          '35 mm',
          '60 cm',
          '70 mm',
          '90 mm',
          '93 mm',
          '200 cm',
          '390 mm'
        ],
        u_atmero: [
          '2,5 mm',
          '3,0 mm',
          '4 mm',
          '5 mm',
          '6 mm',
          '10 mm'
        ],
        u_anyaga: [
          'PE',
          'Nylon',
          'Gumi',
          'ALU',
          'carbon'
        ]
      }
    },
    17: {
      priceRange: {
        min: 100,
        max: 20000
      },
      properties: {
        manufacturers: [
          'Gladio',
          'Trabucco',
          'Haldorádó',
          'Karp',
          'Drake'
        ],
        u_hossz: [
          '1 m',
          '3 m',
          '35 mm',
          '60 cm',
          '70 mm',
          '90 mm',
          '93 mm',
          '200 cm',
          '390 mm'
        ],
        u_atmero: [
          '2,5 mm',
          '3,0 mm',
          '4 mm',
          '5 mm',
          '6 mm',
          '10 mm'
        ],
        u_anyaga: [
          'PE',
          'Nylon',
          'Gumi',
          'ALU',
          'carbon'
        ]
      }
    },
    18: {
      priceRange: {
        min: 100,
        max: 20000
      },
      properties: {
        manufacturers: [
          'Gladio',
          'Trabucco',
          'Haldorádó',
          'Karp',
          'Drake'
        ],
        u_hossz: [
          '1 m',
          '3 m',
          '35 mm',
          '60 cm',
          '70 mm',
          '90 mm',
          '93 mm',
          '200 cm',
          '390 mm'
        ],
        u_atmero: [
          '2,5 mm',
          '3,0 mm',
          '4 mm',
          '5 mm',
          '6 mm',
          '10 mm'
        ],
        u_anyaga: [
          'PE',
          'Nylon',
          'Gumi',
          'ALU',
          'carbon'
        ]
      }
    },
    19: {
      priceRange: {
        min: 100,
        max: 20000
      },
      properties: {
        manufacturers: [
          'Gladio',
          'Trabucco',
          'Haldorádó',
          'Karp',
          'Drake'
        ],
        u_hossz: [
          '1 m',
          '3 m',
          '35 mm',
          '60 cm',
          '70 mm',
          '90 mm',
          '93 mm',
          '200 cm',
          '390 mm'
        ],
        u_atmero: [
          '2,5 mm',
          '3,0 mm',
          '4 mm',
          '5 mm',
          '6 mm',
          '10 mm'
        ],
        u_anyaga: [
          'PE',
          'Nylon',
          'Gumi',
          'ALU',
          'carbon'
        ]
      }
    },
    20: {
      priceRange: {
        min: 100,
        max: 20000
      },
      properties: {
        manufacturers: [
          'Gladio',
          'Trabucco',
          'Haldorádó',
          'Karp',
          'Drake'
        ],
        u_hossz: [
          '1 m',
          '3 m',
          '35 mm',
          '60 cm',
          '70 mm',
          '90 mm',
          '93 mm',
          '200 cm',
          '390 mm'
        ],
        u_atmero: [
          '2,5 mm',
          '3,0 mm',
          '4 mm',
          '5 mm',
          '6 mm',
          '10 mm'
        ],
        u_anyaga: [
          'PE',
          'Nylon',
          'Gumi',
          'ALU',
          'carbon'
        ]
      }
    },
    21: {
      priceRange: {
        min: 100,
        max: 20000
      },
      properties: {
        manufacturers: [
          'Gladio',
          'Trabucco',
          'Haldorádó',
          'Karp',
          'Drake'
        ],
        u_hossz: [
          '1 m',
          '3 m',
          '35 mm',
          '60 cm',
          '70 mm',
          '90 mm',
          '93 mm',
          '200 cm',
          '390 mm'
        ],
        u_atmero: [
          '2,5 mm',
          '3,0 mm',
          '4 mm',
          '5 mm',
          '6 mm',
          '10 mm'
        ],
        u_anyaga: [
          'PE',
          'Nylon',
          'Gumi',
          'ALU',
          'carbon'
        ]
      }
    },
    22: {
      priceRange: {
        min: 100,
        max: 20000
      },
      properties: {
        manufacturers: [
          'Gladio',
          'Trabucco',
          'Haldorádó',
          'Karp',
          'Drake'
        ],
        u_hossz: [
          '1 m',
          '3 m',
          '35 mm',
          '60 cm',
          '70 mm',
          '90 mm',
          '93 mm',
          '200 cm',
          '390 mm'
        ],
        u_atmero: [
          '2,5 mm',
          '3,0 mm',
          '4 mm',
          '5 mm',
          '6 mm',
          '10 mm'
        ],
        u_anyaga: [
          'PE',
          'Nylon',
          'Gumi',
          'ALU',
          'carbon'
        ]
      }
    },
    23: {
      priceRange: {
        min: 100,
        max: 20000
      },
      properties: {
        manufacturers: [
          'Gladio',
          'Trabucco',
          'Haldorádó',
          'Karp',
          'Drake'
        ],
        u_hossz: [
          '1 m',
          '3 m',
          '35 mm',
          '60 cm',
          '70 mm',
          '90 mm',
          '93 mm',
          '200 cm',
          '390 mm'
        ],
        u_atmero: [
          '2,5 mm',
          '3,0 mm',
          '4 mm',
          '5 mm',
          '6 mm',
          '10 mm'
        ],
        u_anyaga: [
          'PE',
          'Nylon',
          'Gumi',
          'ALU',
          'carbon'
        ]
      }
    },
    24: {
      priceRange: {
        min: 100,
        max: 20000
      },
      properties: {
        manufacturers: [
          'Gladio',
          'Trabucco',
          'Haldorádó',
          'Karp',
          'Drake'
        ],
        u_hossz: [
          '1 m',
          '3 m',
          '35 mm',
          '60 cm',
          '70 mm',
          '90 mm',
          '93 mm',
          '200 cm',
          '390 mm'
        ],
        u_atmero: [
          '2,5 mm',
          '3,0 mm',
          '4 mm',
          '5 mm',
          '6 mm',
          '10 mm'
        ],
        u_anyaga: [
          'PE',
          'Nylon',
          'Gumi',
          'ALU',
          'carbon'
        ]
      }
    },
    25: {
      priceRange: {
        min: 100,
        max: 20000
      },
      properties: {
        manufacturers: [
          'Gladio',
          'Trabucco',
          'Haldorádó',
          'Karp',
          'Drake'
        ],
        u_hossz: [
          '1 m',
          '3 m',
          '35 mm',
          '60 cm',
          '70 mm',
          '90 mm',
          '93 mm',
          '200 cm',
          '390 mm'
        ],
        u_atmero: [
          '2,5 mm',
          '3,0 mm',
          '4 mm',
          '5 mm',
          '6 mm',
          '10 mm'
        ],
        u_anyaga: [
          'PE',
          'Nylon',
          'Gumi',
          'ALU',
          'carbon'
        ]
      }
    },
    26: {
      priceRange: {
        min: 100,
        max: 20000
      },
      properties: {
        manufacturers: [
          'Gladio',
          'Trabucco',
          'Haldorádó',
          'Karp',
          'Drake'
        ],
        u_hossz: [
          '1 m',
          '3 m',
          '35 mm',
          '60 cm',
          '70 mm',
          '90 mm',
          '93 mm',
          '200 cm',
          '390 mm'
        ],
        u_atmero: [
          '2,5 mm',
          '3,0 mm',
          '4 mm',
          '5 mm',
          '6 mm',
          '10 mm'
        ],
        u_anyaga: [
          'PE',
          'Nylon',
          'Gumi',
          'ALU',
          'carbon'
        ]
      }
    },
    27: {
      priceRange: {
        min: 100,
        max: 20000
      },
      properties: {
        manufacturers: [
          'Gladio',
          'Trabucco',
          'Haldorádó',
          'Karp',
          'Drake'
        ],
        u_hossz: [
          '1 m',
          '3 m',
          '35 mm',
          '60 cm',
          '70 mm',
          '90 mm',
          '93 mm',
          '200 cm',
          '390 mm'
        ],
        u_atmero: [
          '2,5 mm',
          '3,0 mm',
          '4 mm',
          '5 mm',
          '6 mm',
          '10 mm'
        ],
        u_anyaga: [
          'PE',
          'Nylon',
          'Gumi',
          'ALU',
          'carbon'
        ]
      }
    },
    28: {
      priceRange: {
        min: 100,
        max: 20000
      },
      properties: {
        manufacturers: [
          'Gladio',
          'Trabucco',
          'Haldorádó',
          'Karp',
          'Drake'
        ],
        u_hossz: [
          '1 m',
          '3 m',
          '35 mm',
          '60 cm',
          '70 mm',
          '90 mm',
          '93 mm',
          '200 cm',
          '390 mm'
        ],
        u_atmero: [
          '2,5 mm',
          '3,0 mm',
          '4 mm',
          '5 mm',
          '6 mm',
          '10 mm'
        ],
        u_anyaga: [
          'PE',
          'Nylon',
          'Gumi',
          'ALU',
          'carbon'
        ]
      }
    },
    29: {
      priceRange: {
        min: 100,
        max: 20000
      },
      properties: {
        manufacturers: [
          'Gladio',
          'Trabucco',
          'Haldorádó',
          'Karp',
          'Drake'
        ],
        u_hossz: [
          '1 m',
          '3 m',
          '35 mm',
          '60 cm',
          '70 mm',
          '90 mm',
          '93 mm',
          '200 cm',
          '390 mm'
        ],
        u_atmero: [
          '2,5 mm',
          '3,0 mm',
          '4 mm',
          '5 mm',
          '6 mm',
          '10 mm'
        ],
        u_anyaga: [
          'PE',
          'Nylon',
          'Gumi',
          'ALU',
          'carbon'
        ]
      }
    },
    331: {
      priceRange: {
        min: 100,
        max: 20000
      },
      properties: {
        manufacturers: [
          'Gladio',
          'Trabucco',
          'Haldorádó',
          'Karp',
          'Drake'
        ],
        u_hossz: [
          '1 m',
          '3 m',
          '35 mm',
          '60 cm',
          '70 mm',
          '90 mm',
          '93 mm',
          '200 cm',
          '390 mm'
        ],
        u_atmero: [
          '2,5 mm',
          '3,0 mm',
          '4 mm',
          '5 mm',
          '6 mm',
          '10 mm'
        ],
        u_anyaga: [
          'PE',
          'Nylon',
          'Gumi',
          'ALU',
          'carbon'
        ]
      }
    },
    32: {
      priceRange: {
        min: 100,
        max: 20000
      },
      properties: {
        manufacturers: [
          'Gladio',
          'Trabucco',
          'Haldorádó',
          'Karp',
          'Drake'
        ],
        u_hossz: [
          '1 m',
          '3 m',
          '35 mm',
          '60 cm',
          '70 mm',
          '90 mm',
          '93 mm',
          '200 cm',
          '390 mm'
        ],
        u_atmero: [
          '2,5 mm',
          '3,0 mm',
          '4 mm',
          '5 mm',
          '6 mm',
          '10 mm'
        ],
        u_anyaga: [
          'PE',
          'Nylon',
          'Gumi',
          'ALU',
          'carbon'
        ]
      }
    },
    33: {
      priceRange: {
        min: 100,
        max: 20000
      },
      properties: {
        manufacturers: [
          'Gladio',
          'Trabucco',
          'Haldorádó',
          'Karp',
          'Drake'
        ],
        u_hossz: [
          '1 m',
          '3 m',
          '35 mm',
          '60 cm',
          '70 mm',
          '90 mm',
          '93 mm',
          '200 cm',
          '390 mm'
        ],
        u_atmero: [
          '2,5 mm',
          '3,0 mm',
          '4 mm',
          '5 mm',
          '6 mm',
          '10 mm'
        ],
        u_anyaga: [
          'PE',
          'Nylon',
          'Gumi',
          'ALU',
          'carbon'
        ]
      }
    },
    34: {
      priceRange: {
        min: 100,
        max: 20000
      },
      properties: {
        manufacturers: [
          'Gladio',
          'Trabucco',
          'Haldorádó',
          'Karp',
          'Drake'
        ],
        u_hossz: [
          '1 m',
          '3 m',
          '35 mm',
          '60 cm',
          '70 mm',
          '90 mm',
          '93 mm',
          '200 cm',
          '390 mm'
        ],
        u_atmero: [
          '2,5 mm',
          '3,0 mm',
          '4 mm',
          '5 mm',
          '6 mm',
          '10 mm'
        ],
        u_anyaga: [
          'PE',
          'Nylon',
          'Gumi',
          'ALU',
          'carbon'
        ]
      }
    },
    35: {
      priceRange: {
        min: 100,
        max: 20000
      },
      properties: {
        manufacturers: [
          'Gladio',
          'Trabucco',
          'Haldorádó',
          'Karp',
          'Drake'
        ],
        u_hossz: [
          '1 m',
          '3 m',
          '35 mm',
          '60 cm',
          '70 mm',
          '90 mm',
          '93 mm',
          '200 cm',
          '390 mm'
        ],
        u_atmero: [
          '2,5 mm',
          '3,0 mm',
          '4 mm',
          '5 mm',
          '6 mm',
          '10 mm'
        ],
        u_anyaga: [
          'PE',
          'Nylon',
          'Gumi',
          'ALU',
          'carbon'
        ]
      }
    },
    36: {
      priceRange: {
        min: 100,
        max: 20000
      },
      properties: {
        manufacturers: [
          'Gladio',
          'Trabucco',
          'Haldorádó',
          'Karp',
          'Drake'
        ],
        u_hossz: [
          '1 m',
          '3 m',
          '35 mm',
          '60 cm',
          '70 mm',
          '90 mm',
          '93 mm',
          '200 cm',
          '390 mm'
        ],
        u_atmero: [
          '2,5 mm',
          '3,0 mm',
          '4 mm',
          '5 mm',
          '6 mm',
          '10 mm'
        ],
        u_anyaga: [
          'PE',
          'Nylon',
          'Gumi',
          'ALU',
          'carbon'
        ]
      }
    },
    37: {
      priceRange: {
        min: 100,
        max: 20000
      },
      properties: {
        manufacturers: [
          'Gladio',
          'Trabucco',
          'Haldorádó',
          'Karp',
          'Drake'
        ],
        u_hossz: [
          '1 m',
          '3 m',
          '35 mm',
          '60 cm',
          '70 mm',
          '90 mm',
          '93 mm',
          '200 cm',
          '390 mm'
        ],
        u_atmero: [
          '2,5 mm',
          '3,0 mm',
          '4 mm',
          '5 mm',
          '6 mm',
          '10 mm'
        ],
        u_anyaga: [
          'PE',
          'Nylon',
          'Gumi',
          'ALU',
          'carbon'
        ]
      }
    },
    38: {
      priceRange: {
        min: 100,
        max: 20000
      },
      properties: {
        manufacturers: [
          'Gladio',
          'Trabucco',
          'Haldorádó',
          'Karp',
          'Drake'
        ],
        u_hossz: [
          '1 m',
          '3 m',
          '35 mm',
          '60 cm',
          '70 mm',
          '90 mm',
          '93 mm',
          '200 cm',
          '390 mm'
        ],
        u_atmero: [
          '2,5 mm',
          '3,0 mm',
          '4 mm',
          '5 mm',
          '6 mm',
          '10 mm'
        ],
        u_anyaga: [
          'PE',
          'Nylon',
          'Gumi',
          'ALU',
          'carbon'
        ]
      }
    },
    39: {
      priceRange: {
        min: 100,
        max: 20000
      },
      properties: {
        manufacturers: [
          'Gladio',
          'Trabucco',
          'Haldorádó',
          'Karp',
          'Drake'
        ],
        u_hossz: [
          '1 m',
          '3 m',
          '35 mm',
          '60 cm',
          '70 mm',
          '90 mm',
          '93 mm',
          '200 cm',
          '390 mm'
        ],
        u_atmero: [
          '2,5 mm',
          '3,0 mm',
          '4 mm',
          '5 mm',
          '6 mm',
          '10 mm'
        ],
        u_anyaga: [
          'PE',
          'Nylon',
          'Gumi',
          'ALU',
          'carbon'
        ]
      }
    },
    441: {
      priceRange: {
        min: 100,
        max: 20000
      },
      properties: {
        manufacturers: [
          'Gladio',
          'Trabucco',
          'Haldorádó',
          'Karp',
          'Drake'
        ],
        u_hossz: [
          '1 m',
          '3 m',
          '35 mm',
          '60 cm',
          '70 mm',
          '90 mm',
          '93 mm',
          '200 cm',
          '390 mm'
        ],
        u_atmero: [
          '2,5 mm',
          '3,0 mm',
          '4 mm',
          '5 mm',
          '6 mm',
          '10 mm'
        ],
        u_anyaga: [
          'PE',
          'Nylon',
          'Gumi',
          'ALU',
          'carbon'
        ]
      }
    },
    42: {
      priceRange: {
        min: 100,
        max: 20000
      },
      properties: {
        manufacturers: [
          'Gladio',
          'Trabucco',
          'Haldorádó',
          'Karp',
          'Drake'
        ],
        u_hossz: [
          '1 m',
          '3 m',
          '35 mm',
          '60 cm',
          '70 mm',
          '90 mm',
          '93 mm',
          '200 cm',
          '390 mm'
        ],
        u_atmero: [
          '2,5 mm',
          '3,0 mm',
          '4 mm',
          '5 mm',
          '6 mm',
          '10 mm'
        ],
        u_anyaga: [
          'PE',
          'Nylon',
          'Gumi',
          'ALU',
          'carbon'
        ]
      }
    },
    43: {
      priceRange: {
        min: 100,
        max: 20000
      },
      properties: {
        manufacturers: [
          'Gladio',
          'Trabucco',
          'Haldorádó',
          'Karp',
          'Drake'
        ],
        u_hossz: [
          '1 m',
          '3 m',
          '35 mm',
          '60 cm',
          '70 mm',
          '90 mm',
          '93 mm',
          '200 cm',
          '390 mm'
        ],
        u_atmero: [
          '2,5 mm',
          '3,0 mm',
          '4 mm',
          '5 mm',
          '6 mm',
          '10 mm'
        ],
        u_anyaga: [
          'PE',
          'Nylon',
          'Gumi',
          'ALU',
          'carbon'
        ]
      }
    },
    44: {
      priceRange: {
        min: 100,
        max: 20000
      },
      properties: {
        manufacturers: [
          'Gladio',
          'Trabucco',
          'Haldorádó',
          'Karp',
          'Drake'
        ],
        u_hossz: [
          '1 m',
          '3 m',
          '35 mm',
          '60 cm',
          '70 mm',
          '90 mm',
          '93 mm',
          '200 cm',
          '390 mm'
        ],
        u_atmero: [
          '2,5 mm',
          '3,0 mm',
          '4 mm',
          '5 mm',
          '6 mm',
          '10 mm'
        ],
        u_anyaga: [
          'PE',
          'Nylon',
          'Gumi',
          'ALU',
          'carbon'
        ]
      }
    },
    45: {
      priceRange: {
        min: 100,
        max: 20000
      },
      properties: {
        manufacturers: [
          'Gladio',
          'Trabucco',
          'Haldorádó',
          'Karp',
          'Drake'
        ],
        u_hossz: [
          '1 m',
          '3 m',
          '35 mm',
          '60 cm',
          '70 mm',
          '90 mm',
          '93 mm',
          '200 cm',
          '390 mm'
        ],
        u_atmero: [
          '2,5 mm',
          '3,0 mm',
          '4 mm',
          '5 mm',
          '6 mm',
          '10 mm'
        ],
        u_anyaga: [
          'PE',
          'Nylon',
          'Gumi',
          'ALU',
          'carbon'
        ]
      }
    },
    46: {
      priceRange: {
        min: 100,
        max: 20000
      },
      properties: {
        manufacturers: [
          'Gladio',
          'Trabucco',
          'Haldorádó',
          'Karp',
          'Drake'
        ],
        u_hossz: [
          '1 m',
          '3 m',
          '35 mm',
          '60 cm',
          '70 mm',
          '90 mm',
          '93 mm',
          '200 cm',
          '390 mm'
        ],
        u_atmero: [
          '2,5 mm',
          '3,0 mm',
          '4 mm',
          '5 mm',
          '6 mm',
          '10 mm'
        ],
        u_anyaga: [
          'PE',
          'Nylon',
          'Gumi',
          'ALU',
          'carbon'
        ]
      }
    },
    47: {
      priceRange: {
        min: 100,
        max: 20000
      },
      properties: {
        manufacturers: [
          'Gladio',
          'Trabucco',
          'Haldorádó',
          'Karp',
          'Drake'
        ],
        u_hossz: [
          '1 m',
          '3 m',
          '35 mm',
          '60 cm',
          '70 mm',
          '90 mm',
          '93 mm',
          '200 cm',
          '390 mm'
        ],
        u_atmero: [
          '2,5 mm',
          '3,0 mm',
          '4 mm',
          '5 mm',
          '6 mm',
          '10 mm'
        ],
        u_anyaga: [
          'PE',
          'Nylon',
          'Gumi',
          'ALU',
          'carbon'
        ]
      }
    },
    48: {
      priceRange: {
        min: 100,
        max: 20000
      },
      properties: {
        manufacturers: [
          'Gladio',
          'Trabucco',
          'Haldorádó',
          'Karp',
          'Drake'
        ],
        u_hossz: [
          '1 m',
          '3 m',
          '35 mm',
          '60 cm',
          '70 mm',
          '90 mm',
          '93 mm',
          '200 cm',
          '390 mm'
        ],
        u_atmero: [
          '2,5 mm',
          '3,0 mm',
          '4 mm',
          '5 mm',
          '6 mm',
          '10 mm'
        ],
        u_anyaga: [
          'PE',
          'Nylon',
          'Gumi',
          'ALU',
          'carbon'
        ]
      }
    },
    49: {
      priceRange: {
        min: 100,
        max: 20000
      },
      properties: {
        manufacturers: [
          'Gladio',
          'Trabucco',
          'Haldorádó',
          'Karp',
          'Drake'
        ],
        u_hossz: [
          '1 m',
          '3 m',
          '35 mm',
          '60 cm',
          '70 mm',
          '90 mm',
          '93 mm',
          '200 cm',
          '390 mm'
        ],
        u_atmero: [
          '2,5 mm',
          '3,0 mm',
          '4 mm',
          '5 mm',
          '6 mm',
          '10 mm'
        ],
        u_anyaga: [
          'PE',
          'Nylon',
          'Gumi',
          'ALU',
          'carbon'
        ]
      }
    },
    551: {
      priceRange: {
        min: 100,
        max: 20000
      },
      properties: {
        manufacturers: [
          'Gladio',
          'Trabucco',
          'Haldorádó',
          'Karp',
          'Drake'
        ],
        u_hossz: [
          '1 m',
          '3 m',
          '35 mm',
          '60 cm',
          '70 mm',
          '90 mm',
          '93 mm',
          '200 cm',
          '390 mm'
        ],
        u_atmero: [
          '2,5 mm',
          '3,0 mm',
          '4 mm',
          '5 mm',
          '6 mm',
          '10 mm'
        ],
        u_anyaga: [
          'PE',
          'Nylon',
          'Gumi',
          'ALU',
          'carbon'
        ]
      }
    },
    52: {
      priceRange: {
        min: 100,
        max: 20000
      },
      properties: {
        manufacturers: [
          'Gladio',
          'Trabucco',
          'Haldorádó',
          'Karp',
          'Drake'
        ],
        u_hossz: [
          '1 m',
          '3 m',
          '35 mm',
          '60 cm',
          '70 mm',
          '90 mm',
          '93 mm',
          '200 cm',
          '390 mm'
        ],
        u_atmero: [
          '2,5 mm',
          '3,0 mm',
          '4 mm',
          '5 mm',
          '6 mm',
          '10 mm'
        ],
        u_anyaga: [
          'PE',
          'Nylon',
          'Gumi',
          'ALU',
          'carbon'
        ]
      }
    },
    53: {
      priceRange: {
        min: 100,
        max: 20000
      },
      properties: {
        manufacturers: [
          'Gladio',
          'Trabucco',
          'Haldorádó',
          'Karp',
          'Drake'
        ],
        u_hossz: [
          '1 m',
          '3 m',
          '35 mm',
          '60 cm',
          '70 mm',
          '90 mm',
          '93 mm',
          '200 cm',
          '390 mm'
        ],
        u_atmero: [
          '2,5 mm',
          '3,0 mm',
          '4 mm',
          '5 mm',
          '6 mm',
          '10 mm'
        ],
        u_anyaga: [
          'PE',
          'Nylon',
          'Gumi',
          'ALU',
          'carbon'
        ]
      }
    },
    54: {
      priceRange: {
        min: 100,
        max: 20000
      },
      properties: {
        manufacturers: [
          'Gladio',
          'Trabucco',
          'Haldorádó',
          'Karp',
          'Drake'
        ],
        u_hossz: [
          '1 m',
          '3 m',
          '35 mm',
          '60 cm',
          '70 mm',
          '90 mm',
          '93 mm',
          '200 cm',
          '390 mm'
        ],
        u_atmero: [
          '2,5 mm',
          '3,0 mm',
          '4 mm',
          '5 mm',
          '6 mm',
          '10 mm'
        ],
        u_anyaga: [
          'PE',
          'Nylon',
          'Gumi',
          'ALU',
          'carbon'
        ]
      }
    },
    55: {
      priceRange: {
        min: 100,
        max: 20000
      },
      properties: {
        manufacturers: [
          'Gladio',
          'Trabucco',
          'Haldorádó',
          'Karp',
          'Drake'
        ],
        u_hossz: [
          '1 m',
          '3 m',
          '35 mm',
          '60 cm',
          '70 mm',
          '90 mm',
          '93 mm',
          '200 cm',
          '390 mm'
        ],
        u_atmero: [
          '2,5 mm',
          '3,0 mm',
          '4 mm',
          '5 mm',
          '6 mm',
          '10 mm'
        ],
        u_anyaga: [
          'PE',
          'Nylon',
          'Gumi',
          'ALU',
          'carbon'
        ]
      }
    },
    56: {
      priceRange: {
        min: 100,
        max: 20000
      },
      properties: {
        manufacturers: [
          'Gladio',
          'Trabucco',
          'Haldorádó',
          'Karp',
          'Drake'
        ],
        u_hossz: [
          '1 m',
          '3 m',
          '35 mm',
          '60 cm',
          '70 mm',
          '90 mm',
          '93 mm',
          '200 cm',
          '390 mm'
        ],
        u_atmero: [
          '2,5 mm',
          '3,0 mm',
          '4 mm',
          '5 mm',
          '6 mm',
          '10 mm'
        ],
        u_anyaga: [
          'PE',
          'Nylon',
          'Gumi',
          'ALU',
          'carbon'
        ]
      }
    },
    57: {
      priceRange: {
        min: 100,
        max: 20000
      },
      properties: {
        manufacturers: [
          'Gladio',
          'Trabucco',
          'Haldorádó',
          'Karp',
          'Drake'
        ],
        u_hossz: [
          '1 m',
          '3 m',
          '35 mm',
          '60 cm',
          '70 mm',
          '90 mm',
          '93 mm',
          '200 cm',
          '390 mm'
        ],
        u_atmero: [
          '2,5 mm',
          '3,0 mm',
          '4 mm',
          '5 mm',
          '6 mm',
          '10 mm'
        ],
        u_anyaga: [
          'PE',
          'Nylon',
          'Gumi',
          'ALU',
          'carbon'
        ]
      }
    },
    58: {
      priceRange: {
        min: 100,
        max: 20000
      },
      properties: {
        manufacturers: [
          'Gladio',
          'Trabucco',
          'Haldorádó',
          'Karp',
          'Drake'
        ],
        u_hossz: [
          '1 m',
          '3 m',
          '35 mm',
          '60 cm',
          '70 mm',
          '90 mm',
          '93 mm',
          '200 cm',
          '390 mm'
        ],
        u_atmero: [
          '2,5 mm',
          '3,0 mm',
          '4 mm',
          '5 mm',
          '6 mm',
          '10 mm'
        ],
        u_anyaga: [
          'PE',
          'Nylon',
          'Gumi',
          'ALU',
          'carbon'
        ]
      }
    },
    59: {
      priceRange: {
        min: 100,
        max: 20000
      },
      properties: {
        manufacturers: [
          'Gladio',
          'Trabucco',
          'Haldorádó',
          'Karp',
          'Drake'
        ],
        u_hossz: [
          '1 m',
          '3 m',
          '35 mm',
          '60 cm',
          '70 mm',
          '90 mm',
          '93 mm',
          '200 cm',
          '390 mm'
        ],
        u_atmero: [
          '2,5 mm',
          '3,0 mm',
          '4 mm',
          '5 mm',
          '6 mm',
          '10 mm'
        ],
        u_anyaga: [
          'PE',
          'Nylon',
          'Gumi',
          'ALU',
          'carbon'
        ]
      }
    },
    661: {
      priceRange: {
        min: 100,
        max: 20000
      },
      properties: {
        manufacturers: [
          'Gladio',
          'Trabucco',
          'Haldorádó',
          'Karp',
          'Drake'
        ],
        u_hossz: [
          '1 m',
          '3 m',
          '35 mm',
          '60 cm',
          '70 mm',
          '90 mm',
          '93 mm',
          '200 cm',
          '390 mm'
        ],
        u_atmero: [
          '2,5 mm',
          '3,0 mm',
          '4 mm',
          '5 mm',
          '6 mm',
          '10 mm'
        ],
        u_anyaga: [
          'PE',
          'Nylon',
          'Gumi',
          'ALU',
          'carbon'
        ]
      }
    },
    62: {
      priceRange: {
        min: 100,
        max: 20000
      },
      properties: {
        manufacturers: [
          'Gladio',
          'Trabucco',
          'Haldorádó',
          'Karp',
          'Drake'
        ],
        u_hossz: [
          '1 m',
          '3 m',
          '35 mm',
          '60 cm',
          '70 mm',
          '90 mm',
          '93 mm',
          '200 cm',
          '390 mm'
        ],
        u_atmero: [
          '2,5 mm',
          '3,0 mm',
          '4 mm',
          '5 mm',
          '6 mm',
          '10 mm'
        ],
        u_anyaga: [
          'PE',
          'Nylon',
          'Gumi',
          'ALU',
          'carbon'
        ]
      }
    },
    63: {
      priceRange: {
        min: 100,
        max: 20000
      },
      properties: {
        manufacturers: [
          'Gladio',
          'Trabucco',
          'Haldorádó',
          'Karp',
          'Drake'
        ],
        u_hossz: [
          '1 m',
          '3 m',
          '35 mm',
          '60 cm',
          '70 mm',
          '90 mm',
          '93 mm',
          '200 cm',
          '390 mm'
        ],
        u_atmero: [
          '2,5 mm',
          '3,0 mm',
          '4 mm',
          '5 mm',
          '6 mm',
          '10 mm'
        ],
        u_anyaga: [
          'PE',
          'Nylon',
          'Gumi',
          'ALU',
          'carbon'
        ]
      }
    },
    64: {
      priceRange: {
        min: 100,
        max: 20000
      },
      properties: {
        manufacturers: [
          'Gladio',
          'Trabucco',
          'Haldorádó',
          'Karp',
          'Drake'
        ],
        u_hossz: [
          '1 m',
          '3 m',
          '35 mm',
          '60 cm',
          '70 mm',
          '90 mm',
          '93 mm',
          '200 cm',
          '390 mm'
        ],
        u_atmero: [
          '2,5 mm',
          '3,0 mm',
          '4 mm',
          '5 mm',
          '6 mm',
          '10 mm'
        ],
        u_anyaga: [
          'PE',
          'Nylon',
          'Gumi',
          'ALU',
          'carbon'
        ]
      }
    },
    65: {
      priceRange: {
        min: 100,
        max: 20000
      },
      properties: {
        manufacturers: [
          'Gladio',
          'Trabucco',
          'Haldorádó',
          'Karp',
          'Drake'
        ],
        u_hossz: [
          '1 m',
          '3 m',
          '35 mm',
          '60 cm',
          '70 mm',
          '90 mm',
          '93 mm',
          '200 cm',
          '390 mm'
        ],
        u_atmero: [
          '2,5 mm',
          '3,0 mm',
          '4 mm',
          '5 mm',
          '6 mm',
          '10 mm'
        ],
        u_anyaga: [
          'PE',
          'Nylon',
          'Gumi',
          'ALU',
          'carbon'
        ]
      }
    },
    66: {
      priceRange: {
        min: 100,
        max: 20000
      },
      properties: {
        manufacturers: [
          'Gladio',
          'Trabucco',
          'Haldorádó',
          'Karp',
          'Drake'
        ],
        u_hossz: [
          '1 m',
          '3 m',
          '35 mm',
          '60 cm',
          '70 mm',
          '90 mm',
          '93 mm',
          '200 cm',
          '390 mm'
        ],
        u_atmero: [
          '2,5 mm',
          '3,0 mm',
          '4 mm',
          '5 mm',
          '6 mm',
          '10 mm'
        ],
        u_anyaga: [
          'PE',
          'Nylon',
          'Gumi',
          'ALU',
          'carbon'
        ]
      }
    },
    67: {
      priceRange: {
        min: 100,
        max: 20000
      },
      properties: {
        manufacturers: [
          'Gladio',
          'Trabucco',
          'Haldorádó',
          'Karp',
          'Drake'
        ],
        u_hossz: [
          '1 m',
          '3 m',
          '35 mm',
          '60 cm',
          '70 mm',
          '90 mm',
          '93 mm',
          '200 cm',
          '390 mm'
        ],
        u_atmero: [
          '2,5 mm',
          '3,0 mm',
          '4 mm',
          '5 mm',
          '6 mm',
          '10 mm'
        ],
        u_anyaga: [
          'PE',
          'Nylon',
          'Gumi',
          'ALU',
          'carbon'
        ]
      }
    },
    68: {
      priceRange: {
        min: 100,
        max: 20000
      },
      properties: {
        manufacturers: [
          'Gladio',
          'Trabucco',
          'Haldorádó',
          'Karp',
          'Drake'
        ],
        u_hossz: [
          '1 m',
          '3 m',
          '35 mm',
          '60 cm',
          '70 mm',
          '90 mm',
          '93 mm',
          '200 cm',
          '390 mm'
        ],
        u_atmero: [
          '2,5 mm',
          '3,0 mm',
          '4 mm',
          '5 mm',
          '6 mm',
          '10 mm'
        ],
        u_anyaga: [
          'PE',
          'Nylon',
          'Gumi',
          'ALU',
          'carbon'
        ]
      }
    },
    69: {
      priceRange: {
        min: 100,
        max: 20000
      },
      properties: {
        manufacturers: [
          'Gladio',
          'Trabucco',
          'Haldorádó',
          'Karp',
          'Drake'
        ],
        u_hossz: [
          '1 m',
          '3 m',
          '35 mm',
          '60 cm',
          '70 mm',
          '90 mm',
          '93 mm',
          '200 cm',
          '390 mm'
        ],
        u_atmero: [
          '2,5 mm',
          '3,0 mm',
          '4 mm',
          '5 mm',
          '6 mm',
          '10 mm'
        ],
        u_anyaga: [
          'PE',
          'Nylon',
          'Gumi',
          'ALU',
          'carbon'
        ]
      }
    },
    771: {
      priceRange: {
        min: 100,
        max: 20000
      },
      properties: {
        manufacturers: [
          'Gladio',
          'Trabucco',
          'Haldorádó',
          'Karp',
          'Drake'
        ],
        u_hossz: [
          '1 m',
          '3 m',
          '35 mm',
          '60 cm',
          '70 mm',
          '90 mm',
          '93 mm',
          '200 cm',
          '390 mm'
        ],
        u_atmero: [
          '2,5 mm',
          '3,0 mm',
          '4 mm',
          '5 mm',
          '6 mm',
          '10 mm'
        ],
        u_anyaga: [
          'PE',
          'Nylon',
          'Gumi',
          'ALU',
          'carbon'
        ]
      }
    },
    72: {
      priceRange: {
        min: 100,
        max: 20000
      },
      properties: {
        manufacturers: [
          'Gladio',
          'Trabucco',
          'Haldorádó',
          'Karp',
          'Drake'
        ],
        u_hossz: [
          '1 m',
          '3 m',
          '35 mm',
          '60 cm',
          '70 mm',
          '90 mm',
          '93 mm',
          '200 cm',
          '390 mm'
        ],
        u_atmero: [
          '2,5 mm',
          '3,0 mm',
          '4 mm',
          '5 mm',
          '6 mm',
          '10 mm'
        ],
        u_anyaga: [
          'PE',
          'Nylon',
          'Gumi',
          'ALU',
          'carbon'
        ]
      }
    },
    73: {
      priceRange: {
        min: 100,
        max: 20000
      },
      properties: {
        manufacturers: [
          'Gladio',
          'Trabucco',
          'Haldorádó',
          'Karp',
          'Drake'
        ],
        u_hossz: [
          '1 m',
          '3 m',
          '35 mm',
          '60 cm',
          '70 mm',
          '90 mm',
          '93 mm',
          '200 cm',
          '390 mm'
        ],
        u_atmero: [
          '2,5 mm',
          '3,0 mm',
          '4 mm',
          '5 mm',
          '6 mm',
          '10 mm'
        ],
        u_anyaga: [
          'PE',
          'Nylon',
          'Gumi',
          'ALU',
          'carbon'
        ]
      }
    },
    74: {
      priceRange: {
        min: 100,
        max: 20000
      },
      properties: {
        manufacturers: [
          'Gladio',
          'Trabucco',
          'Haldorádó',
          'Karp',
          'Drake'
        ],
        u_hossz: [
          '1 m',
          '3 m',
          '35 mm',
          '60 cm',
          '70 mm',
          '90 mm',
          '93 mm',
          '200 cm',
          '390 mm'
        ],
        u_atmero: [
          '2,5 mm',
          '3,0 mm',
          '4 mm',
          '5 mm',
          '6 mm',
          '10 mm'
        ],
        u_anyaga: [
          'PE',
          'Nylon',
          'Gumi',
          'ALU',
          'carbon'
        ]
      }
    },
    75: {
      priceRange: {
        min: 100,
        max: 20000
      },
      properties: {
        manufacturers: [
          'Gladio',
          'Trabucco',
          'Haldorádó',
          'Karp',
          'Drake'
        ],
        u_hossz: [
          '1 m',
          '3 m',
          '35 mm',
          '60 cm',
          '70 mm',
          '90 mm',
          '93 mm',
          '200 cm',
          '390 mm'
        ],
        u_atmero: [
          '2,5 mm',
          '3,0 mm',
          '4 mm',
          '5 mm',
          '6 mm',
          '10 mm'
        ],
        u_anyaga: [
          'PE',
          'Nylon',
          'Gumi',
          'ALU',
          'carbon'
        ]
      }
    },
    76: {
      priceRange: {
        min: 100,
        max: 20000
      },
      properties: {
        manufacturers: [
          'Gladio',
          'Trabucco',
          'Haldorádó',
          'Karp',
          'Drake'
        ],
        u_hossz: [
          '1 m',
          '3 m',
          '35 mm',
          '60 cm',
          '70 mm',
          '90 mm',
          '93 mm',
          '200 cm',
          '390 mm'
        ],
        u_atmero: [
          '2,5 mm',
          '3,0 mm',
          '4 mm',
          '5 mm',
          '6 mm',
          '10 mm'
        ],
        u_anyaga: [
          'PE',
          'Nylon',
          'Gumi',
          'ALU',
          'carbon'
        ]
      }
    },
    77: {
      priceRange: {
        min: 100,
        max: 20000
      },
      properties: {
        manufacturers: [
          'Gladio',
          'Trabucco',
          'Haldorádó',
          'Karp',
          'Drake'
        ],
        u_hossz: [
          '1 m',
          '3 m',
          '35 mm',
          '60 cm',
          '70 mm',
          '90 mm',
          '93 mm',
          '200 cm',
          '390 mm'
        ],
        u_atmero: [
          '2,5 mm',
          '3,0 mm',
          '4 mm',
          '5 mm',
          '6 mm',
          '10 mm'
        ],
        u_anyaga: [
          'PE',
          'Nylon',
          'Gumi',
          'ALU',
          'carbon'
        ]
      }
    },
    78: {
      priceRange: {
        min: 100,
        max: 20000
      },
      properties: {
        manufacturers: [
          'Gladio',
          'Trabucco',
          'Haldorádó',
          'Karp',
          'Drake'
        ],
        u_hossz: [
          '1 m',
          '3 m',
          '35 mm',
          '60 cm',
          '70 mm',
          '90 mm',
          '93 mm',
          '200 cm',
          '390 mm'
        ],
        u_atmero: [
          '2,5 mm',
          '3,0 mm',
          '4 mm',
          '5 mm',
          '6 mm',
          '10 mm'
        ],
        u_anyaga: [
          'PE',
          'Nylon',
          'Gumi',
          'ALU',
          'carbon'
        ]
      }
    },
    79: {
      priceRange: {
        min: 100,
        max: 20000
      },
      properties: {
        manufacturers: [
          'Gladio',
          'Trabucco',
          'Haldorádó',
          'Karp',
          'Drake'
        ],
        u_hossz: [
          '1 m',
          '3 m',
          '35 mm',
          '60 cm',
          '70 mm',
          '90 mm',
          '93 mm',
          '200 cm',
          '390 mm'
        ],
        u_atmero: [
          '2,5 mm',
          '3,0 mm',
          '4 mm',
          '5 mm',
          '6 mm',
          '10 mm'
        ],
        u_anyaga: [
          'PE',
          'Nylon',
          'Gumi',
          'ALU',
          'carbon'
        ]
      }
    },
    881: {
      priceRange: {
        min: 100,
        max: 20000
      },
      properties: {
        manufacturers: [
          'Gladio',
          'Trabucco',
          'Haldorádó',
          'Karp',
          'Drake'
        ],
        u_hossz: [
          '1 m',
          '3 m',
          '35 mm',
          '60 cm',
          '70 mm',
          '90 mm',
          '93 mm',
          '200 cm',
          '390 mm'
        ],
        u_atmero: [
          '2,5 mm',
          '3,0 mm',
          '4 mm',
          '5 mm',
          '6 mm',
          '10 mm'
        ],
        u_anyaga: [
          'PE',
          'Nylon',
          'Gumi',
          'ALU',
          'carbon'
        ]
      }
    },
    82: {
      priceRange: {
        min: 100,
        max: 20000
      },
      properties: {
        manufacturers: [
          'Gladio',
          'Trabucco',
          'Haldorádó',
          'Karp',
          'Drake'
        ],
        u_hossz: [
          '1 m',
          '3 m',
          '35 mm',
          '60 cm',
          '70 mm',
          '90 mm',
          '93 mm',
          '200 cm',
          '390 mm'
        ],
        u_atmero: [
          '2,5 mm',
          '3,0 mm',
          '4 mm',
          '5 mm',
          '6 mm',
          '10 mm'
        ],
        u_anyaga: [
          'PE',
          'Nylon',
          'Gumi',
          'ALU',
          'carbon'
        ]
      }
    },
    83: {
      priceRange: {
        min: 100,
        max: 20000
      },
      properties: {
        manufacturers: [
          'Gladio',
          'Trabucco',
          'Haldorádó',
          'Karp',
          'Drake'
        ],
        u_hossz: [
          '1 m',
          '3 m',
          '35 mm',
          '60 cm',
          '70 mm',
          '90 mm',
          '93 mm',
          '200 cm',
          '390 mm'
        ],
        u_atmero: [
          '2,5 mm',
          '3,0 mm',
          '4 mm',
          '5 mm',
          '6 mm',
          '10 mm'
        ],
        u_anyaga: [
          'PE',
          'Nylon',
          'Gumi',
          'ALU',
          'carbon'
        ]
      }
    },
    84: {
      priceRange: {
        min: 100,
        max: 20000
      },
      properties: {
        manufacturers: [
          'Gladio',
          'Trabucco',
          'Haldorádó',
          'Karp',
          'Drake'
        ],
        u_hossz: [
          '1 m',
          '3 m',
          '35 mm',
          '60 cm',
          '70 mm',
          '90 mm',
          '93 mm',
          '200 cm',
          '390 mm'
        ],
        u_atmero: [
          '2,5 mm',
          '3,0 mm',
          '4 mm',
          '5 mm',
          '6 mm',
          '10 mm'
        ],
        u_anyaga: [
          'PE',
          'Nylon',
          'Gumi',
          'ALU',
          'carbon'
        ]
      }
    },
    85: {
      priceRange: {
        min: 100,
        max: 20000
      },
      properties: {
        manufacturers: [
          'Gladio',
          'Trabucco',
          'Haldorádó',
          'Karp',
          'Drake'
        ],
        u_hossz: [
          '1 m',
          '3 m',
          '35 mm',
          '60 cm',
          '70 mm',
          '90 mm',
          '93 mm',
          '200 cm',
          '390 mm'
        ],
        u_atmero: [
          '2,5 mm',
          '3,0 mm',
          '4 mm',
          '5 mm',
          '6 mm',
          '10 mm'
        ],
        u_anyaga: [
          'PE',
          'Nylon',
          'Gumi',
          'ALU',
          'carbon'
        ]
      }
    },
    86: {
      priceRange: {
        min: 100,
        max: 20000
      },
      properties: {
        manufacturers: [
          'Gladio',
          'Trabucco',
          'Haldorádó',
          'Karp',
          'Drake'
        ],
        u_hossz: [
          '1 m',
          '3 m',
          '35 mm',
          '60 cm',
          '70 mm',
          '90 mm',
          '93 mm',
          '200 cm',
          '390 mm'
        ],
        u_atmero: [
          '2,5 mm',
          '3,0 mm',
          '4 mm',
          '5 mm',
          '6 mm',
          '10 mm'
        ],
        u_anyaga: [
          'PE',
          'Nylon',
          'Gumi',
          'ALU',
          'carbon'
        ]
      }
    },
    87: {
      priceRange: {
        min: 100,
        max: 20000
      },
      properties: {
        manufacturers: [
          'Gladio',
          'Trabucco',
          'Haldorádó',
          'Karp',
          'Drake'
        ],
        u_hossz: [
          '1 m',
          '3 m',
          '35 mm',
          '60 cm',
          '70 mm',
          '90 mm',
          '93 mm',
          '200 cm',
          '390 mm'
        ],
        u_atmero: [
          '2,5 mm',
          '3,0 mm',
          '4 mm',
          '5 mm',
          '6 mm',
          '10 mm'
        ],
        u_anyaga: [
          'PE',
          'Nylon',
          'Gumi',
          'ALU',
          'carbon'
        ]
      }
    },
    88: {
      priceRange: {
        min: 100,
        max: 20000
      },
      properties: {
        manufacturers: [
          'Gladio',
          'Trabucco',
          'Haldorádó',
          'Karp',
          'Drake'
        ],
        u_hossz: [
          '1 m',
          '3 m',
          '35 mm',
          '60 cm',
          '70 mm',
          '90 mm',
          '93 mm',
          '200 cm',
          '390 mm'
        ],
        u_atmero: [
          '2,5 mm',
          '3,0 mm',
          '4 mm',
          '5 mm',
          '6 mm',
          '10 mm'
        ],
        u_anyaga: [
          'PE',
          'Nylon',
          'Gumi',
          'ALU',
          'carbon'
        ]
      }
    },
    89: {
      priceRange: {
        min: 100,
        max: 20000
      },
      properties: {
        manufacturers: [
          'Gladio',
          'Trabucco',
          'Haldorádó',
          'Karp',
          'Drake'
        ],
        u_hossz: [
          '1 m',
          '3 m',
          '35 mm',
          '60 cm',
          '70 mm',
          '90 mm',
          '93 mm',
          '200 cm',
          '390 mm'
        ],
        u_atmero: [
          '2,5 mm',
          '3,0 mm',
          '4 mm',
          '5 mm',
          '6 mm',
          '10 mm'
        ],
        u_anyaga: [
          'PE',
          'Nylon',
          'Gumi',
          'ALU',
          'carbon'
        ]
      }
    },
    991: {
      priceRange: {
        min: 100,
        max: 20000
      },
      properties: {
        manufacturers: [
          'Gladio',
          'Trabucco',
          'Haldorádó',
          'Karp',
          'Drake'
        ],
        u_hossz: [
          '1 m',
          '3 m',
          '35 mm',
          '60 cm',
          '70 mm',
          '90 mm',
          '93 mm',
          '200 cm',
          '390 mm'
        ],
        u_atmero: [
          '2,5 mm',
          '3,0 mm',
          '4 mm',
          '5 mm',
          '6 mm',
          '10 mm'
        ],
        u_anyaga: [
          'PE',
          'Nylon',
          'Gumi',
          'ALU',
          'carbon'
        ]
      }
    },
    92: {
      priceRange: {
        min: 100,
        max: 20000
      },
      properties: {
        manufacturers: [
          'Gladio',
          'Trabucco',
          'Haldorádó',
          'Karp',
          'Drake'
        ],
        u_hossz: [
          '1 m',
          '3 m',
          '35 mm',
          '60 cm',
          '70 mm',
          '90 mm',
          '93 mm',
          '200 cm',
          '390 mm'
        ],
        u_atmero: [
          '2,5 mm',
          '3,0 mm',
          '4 mm',
          '5 mm',
          '6 mm',
          '10 mm'
        ],
        u_anyaga: [
          'PE',
          'Nylon',
          'Gumi',
          'ALU',
          'carbon'
        ]
      }
    },
    93: {
      priceRange: {
        min: 100,
        max: 20000
      },
      properties: {
        manufacturers: [
          'Gladio',
          'Trabucco',
          'Haldorádó',
          'Karp',
          'Drake'
        ],
        u_hossz: [
          '1 m',
          '3 m',
          '35 mm',
          '60 cm',
          '70 mm',
          '90 mm',
          '93 mm',
          '200 cm',
          '390 mm'
        ],
        u_atmero: [
          '2,5 mm',
          '3,0 mm',
          '4 mm',
          '5 mm',
          '6 mm',
          '10 mm'
        ],
        u_anyaga: [
          'PE',
          'Nylon',
          'Gumi',
          'ALU',
          'carbon'
        ]
      }
    },
    94: {
      priceRange: {
        min: 100,
        max: 20000
      },
      properties: {
        manufacturers: [
          'Gladio',
          'Trabucco',
          'Haldorádó',
          'Karp',
          'Drake'
        ],
        u_hossz: [
          '1 m',
          '3 m',
          '35 mm',
          '60 cm',
          '70 mm',
          '90 mm',
          '93 mm',
          '200 cm',
          '390 mm'
        ],
        u_atmero: [
          '2,5 mm',
          '3,0 mm',
          '4 mm',
          '5 mm',
          '6 mm',
          '10 mm'
        ],
        u_anyaga: [
          'PE',
          'Nylon',
          'Gumi',
          'ALU',
          'carbon'
        ]
      }
    },
    95: {
      priceRange: {
        min: 100,
        max: 20000
      },
      properties: {
        manufacturers: [
          'Gladio',
          'Trabucco',
          'Haldorádó',
          'Karp',
          'Drake'
        ],
        u_hossz: [
          '1 m',
          '3 m',
          '35 mm',
          '60 cm',
          '70 mm',
          '90 mm',
          '93 mm',
          '200 cm',
          '390 mm'
        ],
        u_atmero: [
          '2,5 mm',
          '3,0 mm',
          '4 mm',
          '5 mm',
          '6 mm',
          '10 mm'
        ],
        u_anyaga: [
          'PE',
          'Nylon',
          'Gumi',
          'ALU',
          'carbon'
        ]
      }
    },
    96: {
      priceRange: {
        min: 100,
        max: 20000
      },
      properties: {
        manufacturers: [
          'Gladio',
          'Trabucco',
          'Haldorádó',
          'Karp',
          'Drake'
        ],
        u_hossz: [
          '1 m',
          '3 m',
          '35 mm',
          '60 cm',
          '70 mm',
          '90 mm',
          '93 mm',
          '200 cm',
          '390 mm'
        ],
        u_atmero: [
          '2,5 mm',
          '3,0 mm',
          '4 mm',
          '5 mm',
          '6 mm',
          '10 mm'
        ],
        u_anyaga: [
          'PE',
          'Nylon',
          'Gumi',
          'ALU',
          'carbon'
        ]
      }
    },
    97: {
      priceRange: {
        min: 100,
        max: 20000
      },
      properties: {
        manufacturers: [
          'Gladio',
          'Trabucco',
          'Haldorádó',
          'Karp',
          'Drake'
        ],
        u_hossz: [
          '1 m',
          '3 m',
          '35 mm',
          '60 cm',
          '70 mm',
          '90 mm',
          '93 mm',
          '200 cm',
          '390 mm'
        ],
        u_atmero: [
          '2,5 mm',
          '3,0 mm',
          '4 mm',
          '5 mm',
          '6 mm',
          '10 mm'
        ],
        u_anyaga: [
          'PE',
          'Nylon',
          'Gumi',
          'ALU',
          'carbon'
        ]
      }
    },
    98: {
      priceRange: {
        min: 100,
        max: 20000
      },
      properties: {
        manufacturers: [
          'Gladio',
          'Trabucco',
          'Haldorádó',
          'Karp',
          'Drake'
        ],
        u_hossz: [
          '1 m',
          '3 m',
          '35 mm',
          '60 cm',
          '70 mm',
          '90 mm',
          '93 mm',
          '200 cm',
          '390 mm'
        ],
        u_atmero: [
          '2,5 mm',
          '3,0 mm',
          '4 mm',
          '5 mm',
          '6 mm',
          '10 mm'
        ],
        u_anyaga: [
          'PE',
          'Nylon',
          'Gumi',
          'ALU',
          'carbon'
        ]
      }
    },
    99: {
      priceRange: {
        min: 100,
        max: 20000
      },
      properties: {
        manufacturers: [
          'Gladio',
          'Trabucco',
          'Haldorádó',
          'Karp',
          'Drake'
        ],
        u_hossz: [
          '1 m',
          '3 m',
          '35 mm',
          '60 cm',
          '70 mm',
          '90 mm',
          '93 mm',
          '200 cm',
          '390 mm'
        ],
        u_atmero: [
          '2,5 mm',
          '3,0 mm',
          '4 mm',
          '5 mm',
          '6 mm',
          '10 mm'
        ],
        u_anyaga: [
          'PE',
          'Nylon',
          'Gumi',
          'ALU',
          'carbon'
        ]
      }
    },
    10101: {
      priceRange: {
        min: 100,
        max: 20000
      },
      properties: {
        manufacturers: [
          'Gladio',
          'Trabucco',
          'Haldorádó',
          'Karp',
          'Drake'
        ],
        u_hossz: [
          '1 m',
          '3 m',
          '35 mm',
          '60 cm',
          '70 mm',
          '90 mm',
          '93 mm',
          '200 cm',
          '390 mm'
        ],
        u_atmero: [
          '2,5 mm',
          '3,0 mm',
          '4 mm',
          '5 mm',
          '6 mm',
          '10 mm'
        ],
        u_anyaga: [
          'PE',
          'Nylon',
          'Gumi',
          'ALU',
          'carbon'
        ]
      }
    },
    102: {
      priceRange: {
        min: 100,
        max: 20000
      },
      properties: {
        manufacturers: [
          'Gladio',
          'Trabucco',
          'Haldorádó',
          'Karp',
          'Drake'
        ],
        u_hossz: [
          '1 m',
          '3 m',
          '35 mm',
          '60 cm',
          '70 mm',
          '90 mm',
          '93 mm',
          '200 cm',
          '390 mm'
        ],
        u_atmero: [
          '2,5 mm',
          '3,0 mm',
          '4 mm',
          '5 mm',
          '6 mm',
          '10 mm'
        ],
        u_anyaga: [
          'PE',
          'Nylon',
          'Gumi',
          'ALU',
          'carbon'
        ]
      }
    },
    103: {
      priceRange: {
        min: 100,
        max: 20000
      },
      properties: {
        manufacturers: [
          'Gladio',
          'Trabucco',
          'Haldorádó',
          'Karp',
          'Drake'
        ],
        u_hossz: [
          '1 m',
          '3 m',
          '35 mm',
          '60 cm',
          '70 mm',
          '90 mm',
          '93 mm',
          '200 cm',
          '390 mm'
        ],
        u_atmero: [
          '2,5 mm',
          '3,0 mm',
          '4 mm',
          '5 mm',
          '6 mm',
          '10 mm'
        ],
        u_anyaga: [
          'PE',
          'Nylon',
          'Gumi',
          'ALU',
          'carbon'
        ]
      }
    },
    104: {
      priceRange: {
        min: 100,
        max: 20000
      },
      properties: {
        manufacturers: [
          'Gladio',
          'Trabucco',
          'Haldorádó',
          'Karp',
          'Drake'
        ],
        u_hossz: [
          '1 m',
          '3 m',
          '35 mm',
          '60 cm',
          '70 mm',
          '90 mm',
          '93 mm',
          '200 cm',
          '390 mm'
        ],
        u_atmero: [
          '2,5 mm',
          '3,0 mm',
          '4 mm',
          '5 mm',
          '6 mm',
          '10 mm'
        ],
        u_anyaga: [
          'PE',
          'Nylon',
          'Gumi',
          'ALU',
          'carbon'
        ]
      }
    },
    105: {
      priceRange: {
        min: 100,
        max: 20000
      },
      properties: {
        manufacturers: [
          'Gladio',
          'Trabucco',
          'Haldorádó',
          'Karp',
          'Drake'
        ],
        u_hossz: [
          '1 m',
          '3 m',
          '35 mm',
          '60 cm',
          '70 mm',
          '90 mm',
          '93 mm',
          '200 cm',
          '390 mm'
        ],
        u_atmero: [
          '2,5 mm',
          '3,0 mm',
          '4 mm',
          '5 mm',
          '6 mm',
          '10 mm'
        ],
        u_anyaga: [
          'PE',
          'Nylon',
          'Gumi',
          'ALU',
          'carbon'
        ]
      }
    },
    106: {
      priceRange: {
        min: 100,
        max: 20000
      },
      properties: {
        manufacturers: [
          'Gladio',
          'Trabucco',
          'Haldorádó',
          'Karp',
          'Drake'
        ],
        u_hossz: [
          '1 m',
          '3 m',
          '35 mm',
          '60 cm',
          '70 mm',
          '90 mm',
          '93 mm',
          '200 cm',
          '390 mm'
        ],
        u_atmero: [
          '2,5 mm',
          '3,0 mm',
          '4 mm',
          '5 mm',
          '6 mm',
          '10 mm'
        ],
        u_anyaga: [
          'PE',
          'Nylon',
          'Gumi',
          'ALU',
          'carbon'
        ]
      }
    },
    107: {
      priceRange: {
        min: 100,
        max: 20000
      },
      properties: {
        manufacturers: [
          'Gladio',
          'Trabucco',
          'Haldorádó',
          'Karp',
          'Drake'
        ],
        u_hossz: [
          '1 m',
          '3 m',
          '35 mm',
          '60 cm',
          '70 mm',
          '90 mm',
          '93 mm',
          '200 cm',
          '390 mm'
        ],
        u_atmero: [
          '2,5 mm',
          '3,0 mm',
          '4 mm',
          '5 mm',
          '6 mm',
          '10 mm'
        ],
        u_anyaga: [
          'PE',
          'Nylon',
          'Gumi',
          'ALU',
          'carbon'
        ]
      }
    },
    108: {
      priceRange: {
        min: 100,
        max: 20000
      },
      properties: {
        manufacturers: [
          'Gladio',
          'Trabucco',
          'Haldorádó',
          'Karp',
          'Drake'
        ],
        u_hossz: [
          '1 m',
          '3 m',
          '35 mm',
          '60 cm',
          '70 mm',
          '90 mm',
          '93 mm',
          '200 cm',
          '390 mm'
        ],
        u_atmero: [
          '2,5 mm',
          '3,0 mm',
          '4 mm',
          '5 mm',
          '6 mm',
          '10 mm'
        ],
        u_anyaga: [
          'PE',
          'Nylon',
          'Gumi',
          'ALU',
          'carbon'
        ]
      }
    },
    109: {
      priceRange: {
        min: 100,
        max: 20000
      },
      properties: {
        manufacturers: [
          'Gladio',
          'Trabucco',
          'Haldorádó',
          'Karp',
          'Drake'
        ],
        u_hossz: [
          '1 m',
          '3 m',
          '35 mm',
          '60 cm',
          '70 mm',
          '90 mm',
          '93 mm',
          '200 cm',
          '390 mm'
        ],
        u_atmero: [
          '2,5 mm',
          '3,0 mm',
          '4 mm',
          '5 mm',
          '6 mm',
          '10 mm'
        ],
        u_anyaga: [
          'PE',
          'Nylon',
          'Gumi',
          'ALU',
          'carbon'
        ]
      }
    },
    11111: {
      priceRange: {
        min: 100,
        max: 20000
      },
      properties: {
        manufacturers: [
          'Gladio',
          'Trabucco',
          'Haldorádó',
          'Karp',
          'Drake'
        ],
        u_hossz: [
          '1 m',
          '3 m',
          '35 mm',
          '60 cm',
          '70 mm',
          '90 mm',
          '93 mm',
          '200 cm',
          '390 mm'
        ],
        u_atmero: [
          '2,5 mm',
          '3,0 mm',
          '4 mm',
          '5 mm',
          '6 mm',
          '10 mm'
        ],
        u_anyaga: [
          'PE',
          'Nylon',
          'Gumi',
          'ALU',
          'carbon'
        ]
      }
    },
    112: {
      priceRange: {
        min: 100,
        max: 20000
      },
      properties: {
        manufacturers: [
          'Gladio',
          'Trabucco',
          'Haldorádó',
          'Karp',
          'Drake'
        ],
        u_hossz: [
          '1 m',
          '3 m',
          '35 mm',
          '60 cm',
          '70 mm',
          '90 mm',
          '93 mm',
          '200 cm',
          '390 mm'
        ],
        u_atmero: [
          '2,5 mm',
          '3,0 mm',
          '4 mm',
          '5 mm',
          '6 mm',
          '10 mm'
        ],
        u_anyaga: [
          'PE',
          'Nylon',
          'Gumi',
          'ALU',
          'carbon'
        ]
      }
    },
    113: {
      priceRange: {
        min: 100,
        max: 20000
      },
      properties: {
        manufacturers: [
          'Gladio',
          'Trabucco',
          'Haldorádó',
          'Karp',
          'Drake'
        ],
        u_hossz: [
          '1 m',
          '3 m',
          '35 mm',
          '60 cm',
          '70 mm',
          '90 mm',
          '93 mm',
          '200 cm',
          '390 mm'
        ],
        u_atmero: [
          '2,5 mm',
          '3,0 mm',
          '4 mm',
          '5 mm',
          '6 mm',
          '10 mm'
        ],
        u_anyaga: [
          'PE',
          'Nylon',
          'Gumi',
          'ALU',
          'carbon'
        ]
      }
    },
    114: {
      priceRange: {
        min: 100,
        max: 20000
      },
      properties: {
        manufacturers: [
          'Gladio',
          'Trabucco',
          'Haldorádó',
          'Karp',
          'Drake'
        ],
        u_hossz: [
          '1 m',
          '3 m',
          '35 mm',
          '60 cm',
          '70 mm',
          '90 mm',
          '93 mm',
          '200 cm',
          '390 mm'
        ],
        u_atmero: [
          '2,5 mm',
          '3,0 mm',
          '4 mm',
          '5 mm',
          '6 mm',
          '10 mm'
        ],
        u_anyaga: [
          'PE',
          'Nylon',
          'Gumi',
          'ALU',
          'carbon'
        ]
      }
    },
    115: {
      priceRange: {
        min: 100,
        max: 20000
      },
      properties: {
        manufacturers: [
          'Gladio',
          'Trabucco',
          'Haldorádó',
          'Karp',
          'Drake'
        ],
        u_hossz: [
          '1 m',
          '3 m',
          '35 mm',
          '60 cm',
          '70 mm',
          '90 mm',
          '93 mm',
          '200 cm',
          '390 mm'
        ],
        u_atmero: [
          '2,5 mm',
          '3,0 mm',
          '4 mm',
          '5 mm',
          '6 mm',
          '10 mm'
        ],
        u_anyaga: [
          'PE',
          'Nylon',
          'Gumi',
          'ALU',
          'carbon'
        ]
      }
    },
    116: {
      priceRange: {
        min: 100,
        max: 20000
      },
      properties: {
        manufacturers: [
          'Gladio',
          'Trabucco',
          'Haldorádó',
          'Karp',
          'Drake'
        ],
        u_hossz: [
          '1 m',
          '3 m',
          '35 mm',
          '60 cm',
          '70 mm',
          '90 mm',
          '93 mm',
          '200 cm',
          '390 mm'
        ],
        u_atmero: [
          '2,5 mm',
          '3,0 mm',
          '4 mm',
          '5 mm',
          '6 mm',
          '10 mm'
        ],
        u_anyaga: [
          'PE',
          'Nylon',
          'Gumi',
          'ALU',
          'carbon'
        ]
      }
    },
    117: {
      priceRange: {
        min: 100,
        max: 20000
      },
      properties: {
        manufacturers: [
          'Gladio',
          'Trabucco',
          'Haldorádó',
          'Karp',
          'Drake'
        ],
        u_hossz: [
          '1 m',
          '3 m',
          '35 mm',
          '60 cm',
          '70 mm',
          '90 mm',
          '93 mm',
          '200 cm',
          '390 mm'
        ],
        u_atmero: [
          '2,5 mm',
          '3,0 mm',
          '4 mm',
          '5 mm',
          '6 mm',
          '10 mm'
        ],
        u_anyaga: [
          'PE',
          'Nylon',
          'Gumi',
          'ALU',
          'carbon'
        ]
      }
    },
    118: {
      priceRange: {
        min: 100,
        max: 20000
      },
      properties: {
        manufacturers: [
          'Gladio',
          'Trabucco',
          'Haldorádó',
          'Karp',
          'Drake'
        ],
        u_hossz: [
          '1 m',
          '3 m',
          '35 mm',
          '60 cm',
          '70 mm',
          '90 mm',
          '93 mm',
          '200 cm',
          '390 mm'
        ],
        u_atmero: [
          '2,5 mm',
          '3,0 mm',
          '4 mm',
          '5 mm',
          '6 mm',
          '10 mm'
        ],
        u_anyaga: [
          'PE',
          'Nylon',
          'Gumi',
          'ALU',
          'carbon'
        ]
      }
    },
    119: {
      priceRange: {
        min: 100,
        max: 20000
      },
      properties: {
        manufacturers: [
          'Gladio',
          'Trabucco',
          'Haldorádó',
          'Karp',
          'Drake'
        ],
        u_hossz: [
          '1 m',
          '3 m',
          '35 mm',
          '60 cm',
          '70 mm',
          '90 mm',
          '93 mm',
          '200 cm',
          '390 mm'
        ],
        u_atmero: [
          '2,5 mm',
          '3,0 mm',
          '4 mm',
          '5 mm',
          '6 mm',
          '10 mm'
        ],
        u_anyaga: [
          'PE',
          'Nylon',
          'Gumi',
          'ALU',
          'carbon'
        ]
      }
    },
    12121: {
      priceRange: {
        min: 100,
        max: 20000
      },
      properties: {
        manufacturers: [
          'Gladio',
          'Trabucco',
          'Haldorádó',
          'Karp',
          'Drake'
        ],
        u_hossz: [
          '1 m',
          '3 m',
          '35 mm',
          '60 cm',
          '70 mm',
          '90 mm',
          '93 mm',
          '200 cm',
          '390 mm'
        ],
        u_atmero: [
          '2,5 mm',
          '3,0 mm',
          '4 mm',
          '5 mm',
          '6 mm',
          '10 mm'
        ],
        u_anyaga: [
          'PE',
          'Nylon',
          'Gumi',
          'ALU',
          'carbon'
        ]
      }
    },
    122: {
      priceRange: {
        min: 100,
        max: 20000
      },
      properties: {
        manufacturers: [
          'Gladio',
          'Trabucco',
          'Haldorádó',
          'Karp',
          'Drake'
        ],
        u_hossz: [
          '1 m',
          '3 m',
          '35 mm',
          '60 cm',
          '70 mm',
          '90 mm',
          '93 mm',
          '200 cm',
          '390 mm'
        ],
        u_atmero: [
          '2,5 mm',
          '3,0 mm',
          '4 mm',
          '5 mm',
          '6 mm',
          '10 mm'
        ],
        u_anyaga: [
          'PE',
          'Nylon',
          'Gumi',
          'ALU',
          'carbon'
        ]
      }
    },
    123: {
      priceRange: {
        min: 100,
        max: 20000
      },
      properties: {
        manufacturers: [
          'Gladio',
          'Trabucco',
          'Haldorádó',
          'Karp',
          'Drake'
        ],
        u_hossz: [
          '1 m',
          '3 m',
          '35 mm',
          '60 cm',
          '70 mm',
          '90 mm',
          '93 mm',
          '200 cm',
          '390 mm'
        ],
        u_atmero: [
          '2,5 mm',
          '3,0 mm',
          '4 mm',
          '5 mm',
          '6 mm',
          '10 mm'
        ],
        u_anyaga: [
          'PE',
          'Nylon',
          'Gumi',
          'ALU',
          'carbon'
        ]
      }
    },
    124: {
      priceRange: {
        min: 100,
        max: 20000
      },
      properties: {
        manufacturers: [
          'Gladio',
          'Trabucco',
          'Haldorádó',
          'Karp',
          'Drake'
        ],
        u_hossz: [
          '1 m',
          '3 m',
          '35 mm',
          '60 cm',
          '70 mm',
          '90 mm',
          '93 mm',
          '200 cm',
          '390 mm'
        ],
        u_atmero: [
          '2,5 mm',
          '3,0 mm',
          '4 mm',
          '5 mm',
          '6 mm',
          '10 mm'
        ],
        u_anyaga: [
          'PE',
          'Nylon',
          'Gumi',
          'ALU',
          'carbon'
        ]
      }
    },
    125: {
      priceRange: {
        min: 100,
        max: 20000
      },
      properties: {
        manufacturers: [
          'Gladio',
          'Trabucco',
          'Haldorádó',
          'Karp',
          'Drake'
        ],
        u_hossz: [
          '1 m',
          '3 m',
          '35 mm',
          '60 cm',
          '70 mm',
          '90 mm',
          '93 mm',
          '200 cm',
          '390 mm'
        ],
        u_atmero: [
          '2,5 mm',
          '3,0 mm',
          '4 mm',
          '5 mm',
          '6 mm',
          '10 mm'
        ],
        u_anyaga: [
          'PE',
          'Nylon',
          'Gumi',
          'ALU',
          'carbon'
        ]
      }
    },
    126: {
      priceRange: {
        min: 100,
        max: 20000
      },
      properties: {
        manufacturers: [
          'Gladio',
          'Trabucco',
          'Haldorádó',
          'Karp',
          'Drake'
        ],
        u_hossz: [
          '1 m',
          '3 m',
          '35 mm',
          '60 cm',
          '70 mm',
          '90 mm',
          '93 mm',
          '200 cm',
          '390 mm'
        ],
        u_atmero: [
          '2,5 mm',
          '3,0 mm',
          '4 mm',
          '5 mm',
          '6 mm',
          '10 mm'
        ],
        u_anyaga: [
          'PE',
          'Nylon',
          'Gumi',
          'ALU',
          'carbon'
        ]
      }
    },
    127: {
      priceRange: {
        min: 100,
        max: 20000
      },
      properties: {
        manufacturers: [
          'Gladio',
          'Trabucco',
          'Haldorádó',
          'Karp',
          'Drake'
        ],
        u_hossz: [
          '1 m',
          '3 m',
          '35 mm',
          '60 cm',
          '70 mm',
          '90 mm',
          '93 mm',
          '200 cm',
          '390 mm'
        ],
        u_atmero: [
          '2,5 mm',
          '3,0 mm',
          '4 mm',
          '5 mm',
          '6 mm',
          '10 mm'
        ],
        u_anyaga: [
          'PE',
          'Nylon',
          'Gumi',
          'ALU',
          'carbon'
        ]
      }
    },
    128: {
      priceRange: {
        min: 100,
        max: 20000
      },
      properties: {
        manufacturers: [
          'Gladio',
          'Trabucco',
          'Haldorádó',
          'Karp',
          'Drake'
        ],
        u_hossz: [
          '1 m',
          '3 m',
          '35 mm',
          '60 cm',
          '70 mm',
          '90 mm',
          '93 mm',
          '200 cm',
          '390 mm'
        ],
        u_atmero: [
          '2,5 mm',
          '3,0 mm',
          '4 mm',
          '5 mm',
          '6 mm',
          '10 mm'
        ],
        u_anyaga: [
          'PE',
          'Nylon',
          'Gumi',
          'ALU',
          'carbon'
        ]
      }
    },
    129: {
      priceRange: {
        min: 100,
        max: 20000
      },
      properties: {
        manufacturers: [
          'Gladio',
          'Trabucco',
          'Haldorádó',
          'Karp',
          'Drake'
        ],
        u_hossz: [
          '1 m',
          '3 m',
          '35 mm',
          '60 cm',
          '70 mm',
          '90 mm',
          '93 mm',
          '200 cm',
          '390 mm'
        ],
        u_atmero: [
          '2,5 mm',
          '3,0 mm',
          '4 mm',
          '5 mm',
          '6 mm',
          '10 mm'
        ],
        u_anyaga: [
          'PE',
          'Nylon',
          'Gumi',
          'ALU',
          'carbon'
        ]
      }
    },
    13131: {
      priceRange: {
        min: 100,
        max: 20000
      },
      properties: {
        manufacturers: [
          'Gladio',
          'Trabucco',
          'Haldorádó',
          'Karp',
          'Drake'
        ],
        u_hossz: [
          '1 m',
          '3 m',
          '35 mm',
          '60 cm',
          '70 mm',
          '90 mm',
          '93 mm',
          '200 cm',
          '390 mm'
        ],
        u_atmero: [
          '2,5 mm',
          '3,0 mm',
          '4 mm',
          '5 mm',
          '6 mm',
          '10 mm'
        ],
        u_anyaga: [
          'PE',
          'Nylon',
          'Gumi',
          'ALU',
          'carbon'
        ]
      }
    },
    132: {
      priceRange: {
        min: 100,
        max: 20000
      },
      properties: {
        manufacturers: [
          'Gladio',
          'Trabucco',
          'Haldorádó',
          'Karp',
          'Drake'
        ],
        u_hossz: [
          '1 m',
          '3 m',
          '35 mm',
          '60 cm',
          '70 mm',
          '90 mm',
          '93 mm',
          '200 cm',
          '390 mm'
        ],
        u_atmero: [
          '2,5 mm',
          '3,0 mm',
          '4 mm',
          '5 mm',
          '6 mm',
          '10 mm'
        ],
        u_anyaga: [
          'PE',
          'Nylon',
          'Gumi',
          'ALU',
          'carbon'
        ]
      }
    },
    133: {
      priceRange: {
        min: 100,
        max: 20000
      },
      properties: {
        manufacturers: [
          'Gladio',
          'Trabucco',
          'Haldorádó',
          'Karp',
          'Drake'
        ],
        u_hossz: [
          '1 m',
          '3 m',
          '35 mm',
          '60 cm',
          '70 mm',
          '90 mm',
          '93 mm',
          '200 cm',
          '390 mm'
        ],
        u_atmero: [
          '2,5 mm',
          '3,0 mm',
          '4 mm',
          '5 mm',
          '6 mm',
          '10 mm'
        ],
        u_anyaga: [
          'PE',
          'Nylon',
          'Gumi',
          'ALU',
          'carbon'
        ]
      }
    },
    134: {
      priceRange: {
        min: 100,
        max: 20000
      },
      properties: {
        manufacturers: [
          'Gladio',
          'Trabucco',
          'Haldorádó',
          'Karp',
          'Drake'
        ],
        u_hossz: [
          '1 m',
          '3 m',
          '35 mm',
          '60 cm',
          '70 mm',
          '90 mm',
          '93 mm',
          '200 cm',
          '390 mm'
        ],
        u_atmero: [
          '2,5 mm',
          '3,0 mm',
          '4 mm',
          '5 mm',
          '6 mm',
          '10 mm'
        ],
        u_anyaga: [
          'PE',
          'Nylon',
          'Gumi',
          'ALU',
          'carbon'
        ]
      }
    },
    135: {
      priceRange: {
        min: 100,
        max: 20000
      },
      properties: {
        manufacturers: [
          'Gladio',
          'Trabucco',
          'Haldorádó',
          'Karp',
          'Drake'
        ],
        u_hossz: [
          '1 m',
          '3 m',
          '35 mm',
          '60 cm',
          '70 mm',
          '90 mm',
          '93 mm',
          '200 cm',
          '390 mm'
        ],
        u_atmero: [
          '2,5 mm',
          '3,0 mm',
          '4 mm',
          '5 mm',
          '6 mm',
          '10 mm'
        ],
        u_anyaga: [
          'PE',
          'Nylon',
          'Gumi',
          'ALU',
          'carbon'
        ]
      }
    },
    136: {
      priceRange: {
        min: 100,
        max: 20000
      },
      properties: {
        manufacturers: [
          'Gladio',
          'Trabucco',
          'Haldorádó',
          'Karp',
          'Drake'
        ],
        u_hossz: [
          '1 m',
          '3 m',
          '35 mm',
          '60 cm',
          '70 mm',
          '90 mm',
          '93 mm',
          '200 cm',
          '390 mm'
        ],
        u_atmero: [
          '2,5 mm',
          '3,0 mm',
          '4 mm',
          '5 mm',
          '6 mm',
          '10 mm'
        ],
        u_anyaga: [
          'PE',
          'Nylon',
          'Gumi',
          'ALU',
          'carbon'
        ]
      }
    },
    137: {
      priceRange: {
        min: 100,
        max: 20000
      },
      properties: {
        manufacturers: [
          'Gladio',
          'Trabucco',
          'Haldorádó',
          'Karp',
          'Drake'
        ],
        u_hossz: [
          '1 m',
          '3 m',
          '35 mm',
          '60 cm',
          '70 mm',
          '90 mm',
          '93 mm',
          '200 cm',
          '390 mm'
        ],
        u_atmero: [
          '2,5 mm',
          '3,0 mm',
          '4 mm',
          '5 mm',
          '6 mm',
          '10 mm'
        ],
        u_anyaga: [
          'PE',
          'Nylon',
          'Gumi',
          'ALU',
          'carbon'
        ]
      }
    },
    138: {
      priceRange: {
        min: 100,
        max: 20000
      },
      properties: {
        manufacturers: [
          'Gladio',
          'Trabucco',
          'Haldorádó',
          'Karp',
          'Drake'
        ],
        u_hossz: [
          '1 m',
          '3 m',
          '35 mm',
          '60 cm',
          '70 mm',
          '90 mm',
          '93 mm',
          '200 cm',
          '390 mm'
        ],
        u_atmero: [
          '2,5 mm',
          '3,0 mm',
          '4 mm',
          '5 mm',
          '6 mm',
          '10 mm'
        ],
        u_anyaga: [
          'PE',
          'Nylon',
          'Gumi',
          'ALU',
          'carbon'
        ]
      }
    },
    139: {
      priceRange: {
        min: 100,
        max: 20000
      },
      properties: {
        manufacturers: [
          'Gladio',
          'Trabucco',
          'Haldorádó',
          'Karp',
          'Drake'
        ],
        u_hossz: [
          '1 m',
          '3 m',
          '35 mm',
          '60 cm',
          '70 mm',
          '90 mm',
          '93 mm',
          '200 cm',
          '390 mm'
        ],
        u_atmero: [
          '2,5 mm',
          '3,0 mm',
          '4 mm',
          '5 mm',
          '6 mm',
          '10 mm'
        ],
        u_anyaga: [
          'PE',
          'Nylon',
          'Gumi',
          'ALU',
          'carbon'
        ]
      }
    },
    14141: {
      priceRange: {
        min: 100,
        max: 20000
      },
      properties: {
        manufacturers: [
          'Gladio',
          'Trabucco',
          'Haldorádó',
          'Karp',
          'Drake'
        ],
        u_hossz: [
          '1 m',
          '3 m',
          '35 mm',
          '60 cm',
          '70 mm',
          '90 mm',
          '93 mm',
          '200 cm',
          '390 mm'
        ],
        u_atmero: [
          '2,5 mm',
          '3,0 mm',
          '4 mm',
          '5 mm',
          '6 mm',
          '10 mm'
        ],
        u_anyaga: [
          'PE',
          'Nylon',
          'Gumi',
          'ALU',
          'carbon'
        ]
      }
    },
    142: {
      priceRange: {
        min: 100,
        max: 20000
      },
      properties: {
        manufacturers: [
          'Gladio',
          'Trabucco',
          'Haldorádó',
          'Karp',
          'Drake'
        ],
        u_hossz: [
          '1 m',
          '3 m',
          '35 mm',
          '60 cm',
          '70 mm',
          '90 mm',
          '93 mm',
          '200 cm',
          '390 mm'
        ],
        u_atmero: [
          '2,5 mm',
          '3,0 mm',
          '4 mm',
          '5 mm',
          '6 mm',
          '10 mm'
        ],
        u_anyaga: [
          'PE',
          'Nylon',
          'Gumi',
          'ALU',
          'carbon'
        ]
      }
    },
    143: {
      priceRange: {
        min: 100,
        max: 20000
      },
      properties: {
        manufacturers: [
          'Gladio',
          'Trabucco',
          'Haldorádó',
          'Karp',
          'Drake'
        ],
        u_hossz: [
          '1 m',
          '3 m',
          '35 mm',
          '60 cm',
          '70 mm',
          '90 mm',
          '93 mm',
          '200 cm',
          '390 mm'
        ],
        u_atmero: [
          '2,5 mm',
          '3,0 mm',
          '4 mm',
          '5 mm',
          '6 mm',
          '10 mm'
        ],
        u_anyaga: [
          'PE',
          'Nylon',
          'Gumi',
          'ALU',
          'carbon'
        ]
      }
    },
    144: {
      priceRange: {
        min: 100,
        max: 20000
      },
      properties: {
        manufacturers: [
          'Gladio',
          'Trabucco',
          'Haldorádó',
          'Karp',
          'Drake'
        ],
        u_hossz: [
          '1 m',
          '3 m',
          '35 mm',
          '60 cm',
          '70 mm',
          '90 mm',
          '93 mm',
          '200 cm',
          '390 mm'
        ],
        u_atmero: [
          '2,5 mm',
          '3,0 mm',
          '4 mm',
          '5 mm',
          '6 mm',
          '10 mm'
        ],
        u_anyaga: [
          'PE',
          'Nylon',
          'Gumi',
          'ALU',
          'carbon'
        ]
      }
    },
    145: {
      priceRange: {
        min: 100,
        max: 20000
      },
      properties: {
        manufacturers: [
          'Gladio',
          'Trabucco',
          'Haldorádó',
          'Karp',
          'Drake'
        ],
        u_hossz: [
          '1 m',
          '3 m',
          '35 mm',
          '60 cm',
          '70 mm',
          '90 mm',
          '93 mm',
          '200 cm',
          '390 mm'
        ],
        u_atmero: [
          '2,5 mm',
          '3,0 mm',
          '4 mm',
          '5 mm',
          '6 mm',
          '10 mm'
        ],
        u_anyaga: [
          'PE',
          'Nylon',
          'Gumi',
          'ALU',
          'carbon'
        ]
      }
    },
    146: {
      priceRange: {
        min: 100,
        max: 20000
      },
      properties: {
        manufacturers: [
          'Gladio',
          'Trabucco',
          'Haldorádó',
          'Karp',
          'Drake'
        ],
        u_hossz: [
          '1 m',
          '3 m',
          '35 mm',
          '60 cm',
          '70 mm',
          '90 mm',
          '93 mm',
          '200 cm',
          '390 mm'
        ],
        u_atmero: [
          '2,5 mm',
          '3,0 mm',
          '4 mm',
          '5 mm',
          '6 mm',
          '10 mm'
        ],
        u_anyaga: [
          'PE',
          'Nylon',
          'Gumi',
          'ALU',
          'carbon'
        ]
      }
    },
    147: {
      priceRange: {
        min: 100,
        max: 20000
      },
      properties: {
        manufacturers: [
          'Gladio',
          'Trabucco',
          'Haldorádó',
          'Karp',
          'Drake'
        ],
        u_hossz: [
          '1 m',
          '3 m',
          '35 mm',
          '60 cm',
          '70 mm',
          '90 mm',
          '93 mm',
          '200 cm',
          '390 mm'
        ],
        u_atmero: [
          '2,5 mm',
          '3,0 mm',
          '4 mm',
          '5 mm',
          '6 mm',
          '10 mm'
        ],
        u_anyaga: [
          'PE',
          'Nylon',
          'Gumi',
          'ALU',
          'carbon'
        ]
      }
    },
    148: {
      priceRange: {
        min: 100,
        max: 20000
      },
      properties: {
        manufacturers: [
          'Gladio',
          'Trabucco',
          'Haldorádó',
          'Karp',
          'Drake'
        ],
        u_hossz: [
          '1 m',
          '3 m',
          '35 mm',
          '60 cm',
          '70 mm',
          '90 mm',
          '93 mm',
          '200 cm',
          '390 mm'
        ],
        u_atmero: [
          '2,5 mm',
          '3,0 mm',
          '4 mm',
          '5 mm',
          '6 mm',
          '10 mm'
        ],
        u_anyaga: [
          'PE',
          'Nylon',
          'Gumi',
          'ALU',
          'carbon'
        ]
      }
    },
    149: {
      priceRange: {
        min: 100,
        max: 20000
      },
      properties: {
        manufacturers: [
          'Gladio',
          'Trabucco',
          'Haldorádó',
          'Karp',
          'Drake'
        ],
        u_hossz: [
          '1 m',
          '3 m',
          '35 mm',
          '60 cm',
          '70 mm',
          '90 mm',
          '93 mm',
          '200 cm',
          '390 mm'
        ],
        u_atmero: [
          '2,5 mm',
          '3,0 mm',
          '4 mm',
          '5 mm',
          '6 mm',
          '10 mm'
        ],
        u_anyaga: [
          'PE',
          'Nylon',
          'Gumi',
          'ALU',
          'carbon'
        ]
      }
    },
    15151: {
      priceRange: {
        min: 100,
        max: 20000
      },
      properties: {
        manufacturers: [
          'Gladio',
          'Trabucco',
          'Haldorádó',
          'Karp',
          'Drake'
        ],
        u_hossz: [
          '1 m',
          '3 m',
          '35 mm',
          '60 cm',
          '70 mm',
          '90 mm',
          '93 mm',
          '200 cm',
          '390 mm'
        ],
        u_atmero: [
          '2,5 mm',
          '3,0 mm',
          '4 mm',
          '5 mm',
          '6 mm',
          '10 mm'
        ],
        u_anyaga: [
          'PE',
          'Nylon',
          'Gumi',
          'ALU',
          'carbon'
        ]
      }
    },
    152: {
      priceRange: {
        min: 100,
        max: 20000
      },
      properties: {
        manufacturers: [
          'Gladio',
          'Trabucco',
          'Haldorádó',
          'Karp',
          'Drake'
        ],
        u_hossz: [
          '1 m',
          '3 m',
          '35 mm',
          '60 cm',
          '70 mm',
          '90 mm',
          '93 mm',
          '200 cm',
          '390 mm'
        ],
        u_atmero: [
          '2,5 mm',
          '3,0 mm',
          '4 mm',
          '5 mm',
          '6 mm',
          '10 mm'
        ],
        u_anyaga: [
          'PE',
          'Nylon',
          'Gumi',
          'ALU',
          'carbon'
        ]
      }
    },
    153: {
      priceRange: {
        min: 100,
        max: 20000
      },
      properties: {
        manufacturers: [
          'Gladio',
          'Trabucco',
          'Haldorádó',
          'Karp',
          'Drake'
        ],
        u_hossz: [
          '1 m',
          '3 m',
          '35 mm',
          '60 cm',
          '70 mm',
          '90 mm',
          '93 mm',
          '200 cm',
          '390 mm'
        ],
        u_atmero: [
          '2,5 mm',
          '3,0 mm',
          '4 mm',
          '5 mm',
          '6 mm',
          '10 mm'
        ],
        u_anyaga: [
          'PE',
          'Nylon',
          'Gumi',
          'ALU',
          'carbon'
        ]
      }
    },
    154: {
      priceRange: {
        min: 100,
        max: 20000
      },
      properties: {
        manufacturers: [
          'Gladio',
          'Trabucco',
          'Haldorádó',
          'Karp',
          'Drake'
        ],
        u_hossz: [
          '1 m',
          '3 m',
          '35 mm',
          '60 cm',
          '70 mm',
          '90 mm',
          '93 mm',
          '200 cm',
          '390 mm'
        ],
        u_atmero: [
          '2,5 mm',
          '3,0 mm',
          '4 mm',
          '5 mm',
          '6 mm',
          '10 mm'
        ],
        u_anyaga: [
          'PE',
          'Nylon',
          'Gumi',
          'ALU',
          'carbon'
        ]
      }
    },
    155: {
      priceRange: {
        min: 100,
        max: 20000
      },
      properties: {
        manufacturers: [
          'Gladio',
          'Trabucco',
          'Haldorádó',
          'Karp',
          'Drake'
        ],
        u_hossz: [
          '1 m',
          '3 m',
          '35 mm',
          '60 cm',
          '70 mm',
          '90 mm',
          '93 mm',
          '200 cm',
          '390 mm'
        ],
        u_atmero: [
          '2,5 mm',
          '3,0 mm',
          '4 mm',
          '5 mm',
          '6 mm',
          '10 mm'
        ],
        u_anyaga: [
          'PE',
          'Nylon',
          'Gumi',
          'ALU',
          'carbon'
        ]
      }
    },
    156: {
      priceRange: {
        min: 100,
        max: 20000
      },
      properties: {
        manufacturers: [
          'Gladio',
          'Trabucco',
          'Haldorádó',
          'Karp',
          'Drake'
        ],
        u_hossz: [
          '1 m',
          '3 m',
          '35 mm',
          '60 cm',
          '70 mm',
          '90 mm',
          '93 mm',
          '200 cm',
          '390 mm'
        ],
        u_atmero: [
          '2,5 mm',
          '3,0 mm',
          '4 mm',
          '5 mm',
          '6 mm',
          '10 mm'
        ],
        u_anyaga: [
          'PE',
          'Nylon',
          'Gumi',
          'ALU',
          'carbon'
        ]
      }
    },
    157: {
      priceRange: {
        min: 100,
        max: 20000
      },
      properties: {
        manufacturers: [
          'Gladio',
          'Trabucco',
          'Haldorádó',
          'Karp',
          'Drake'
        ],
        u_hossz: [
          '1 m',
          '3 m',
          '35 mm',
          '60 cm',
          '70 mm',
          '90 mm',
          '93 mm',
          '200 cm',
          '390 mm'
        ],
        u_atmero: [
          '2,5 mm',
          '3,0 mm',
          '4 mm',
          '5 mm',
          '6 mm',
          '10 mm'
        ],
        u_anyaga: [
          'PE',
          'Nylon',
          'Gumi',
          'ALU',
          'carbon'
        ]
      }
    },
    158: {
      priceRange: {
        min: 100,
        max: 20000
      },
      properties: {
        manufacturers: [
          'Gladio',
          'Trabucco',
          'Haldorádó',
          'Karp',
          'Drake'
        ],
        u_hossz: [
          '1 m',
          '3 m',
          '35 mm',
          '60 cm',
          '70 mm',
          '90 mm',
          '93 mm',
          '200 cm',
          '390 mm'
        ],
        u_atmero: [
          '2,5 mm',
          '3,0 mm',
          '4 mm',
          '5 mm',
          '6 mm',
          '10 mm'
        ],
        u_anyaga: [
          'PE',
          'Nylon',
          'Gumi',
          'ALU',
          'carbon'
        ]
      }
    },
    159: {
      priceRange: {
        min: 100,
        max: 20000
      },
      properties: {
        manufacturers: [
          'Gladio',
          'Trabucco',
          'Haldorádó',
          'Karp',
          'Drake'
        ],
        u_hossz: [
          '1 m',
          '3 m',
          '35 mm',
          '60 cm',
          '70 mm',
          '90 mm',
          '93 mm',
          '200 cm',
          '390 mm'
        ],
        u_atmero: [
          '2,5 mm',
          '3,0 mm',
          '4 mm',
          '5 mm',
          '6 mm',
          '10 mm'
        ],
        u_anyaga: [
          'PE',
          'Nylon',
          'Gumi',
          'ALU',
          'carbon'
        ]
      }
    },
    16161: {
      priceRange: {
        min: 100,
        max: 20000
      },
      properties: {
        manufacturers: [
          'Gladio',
          'Trabucco',
          'Haldorádó',
          'Karp',
          'Drake'
        ],
        u_hossz: [
          '1 m',
          '3 m',
          '35 mm',
          '60 cm',
          '70 mm',
          '90 mm',
          '93 mm',
          '200 cm',
          '390 mm'
        ],
        u_atmero: [
          '2,5 mm',
          '3,0 mm',
          '4 mm',
          '5 mm',
          '6 mm',
          '10 mm'
        ],
        u_anyaga: [
          'PE',
          'Nylon',
          'Gumi',
          'ALU',
          'carbon'
        ]
      }
    },
    162: {
      priceRange: {
        min: 100,
        max: 20000
      },
      properties: {
        manufacturers: [
          'Gladio',
          'Trabucco',
          'Haldorádó',
          'Karp',
          'Drake'
        ],
        u_hossz: [
          '1 m',
          '3 m',
          '35 mm',
          '60 cm',
          '70 mm',
          '90 mm',
          '93 mm',
          '200 cm',
          '390 mm'
        ],
        u_atmero: [
          '2,5 mm',
          '3,0 mm',
          '4 mm',
          '5 mm',
          '6 mm',
          '10 mm'
        ],
        u_anyaga: [
          'PE',
          'Nylon',
          'Gumi',
          'ALU',
          'carbon'
        ]
      }
    },
    163: {
      priceRange: {
        min: 100,
        max: 20000
      },
      properties: {
        manufacturers: [
          'Gladio',
          'Trabucco',
          'Haldorádó',
          'Karp',
          'Drake'
        ],
        u_hossz: [
          '1 m',
          '3 m',
          '35 mm',
          '60 cm',
          '70 mm',
          '90 mm',
          '93 mm',
          '200 cm',
          '390 mm'
        ],
        u_atmero: [
          '2,5 mm',
          '3,0 mm',
          '4 mm',
          '5 mm',
          '6 mm',
          '10 mm'
        ],
        u_anyaga: [
          'PE',
          'Nylon',
          'Gumi',
          'ALU',
          'carbon'
        ]
      }
    },
    164: {
      priceRange: {
        min: 100,
        max: 20000
      },
      properties: {
        manufacturers: [
          'Gladio',
          'Trabucco',
          'Haldorádó',
          'Karp',
          'Drake'
        ],
        u_hossz: [
          '1 m',
          '3 m',
          '35 mm',
          '60 cm',
          '70 mm',
          '90 mm',
          '93 mm',
          '200 cm',
          '390 mm'
        ],
        u_atmero: [
          '2,5 mm',
          '3,0 mm',
          '4 mm',
          '5 mm',
          '6 mm',
          '10 mm'
        ],
        u_anyaga: [
          'PE',
          'Nylon',
          'Gumi',
          'ALU',
          'carbon'
        ]
      }
    },
    165: {
      priceRange: {
        min: 100,
        max: 20000
      },
      properties: {
        manufacturers: [
          'Gladio',
          'Trabucco',
          'Haldorádó',
          'Karp',
          'Drake'
        ],
        u_hossz: [
          '1 m',
          '3 m',
          '35 mm',
          '60 cm',
          '70 mm',
          '90 mm',
          '93 mm',
          '200 cm',
          '390 mm'
        ],
        u_atmero: [
          '2,5 mm',
          '3,0 mm',
          '4 mm',
          '5 mm',
          '6 mm',
          '10 mm'
        ],
        u_anyaga: [
          'PE',
          'Nylon',
          'Gumi',
          'ALU',
          'carbon'
        ]
      }
    },
    166: {
      priceRange: {
        min: 100,
        max: 20000
      },
      properties: {
        manufacturers: [
          'Gladio',
          'Trabucco',
          'Haldorádó',
          'Karp',
          'Drake'
        ],
        u_hossz: [
          '1 m',
          '3 m',
          '35 mm',
          '60 cm',
          '70 mm',
          '90 mm',
          '93 mm',
          '200 cm',
          '390 mm'
        ],
        u_atmero: [
          '2,5 mm',
          '3,0 mm',
          '4 mm',
          '5 mm',
          '6 mm',
          '10 mm'
        ],
        u_anyaga: [
          'PE',
          'Nylon',
          'Gumi',
          'ALU',
          'carbon'
        ]
      }
    },
    167: {
      priceRange: {
        min: 100,
        max: 20000
      },
      properties: {
        manufacturers: [
          'Gladio',
          'Trabucco',
          'Haldorádó',
          'Karp',
          'Drake'
        ],
        u_hossz: [
          '1 m',
          '3 m',
          '35 mm',
          '60 cm',
          '70 mm',
          '90 mm',
          '93 mm',
          '200 cm',
          '390 mm'
        ],
        u_atmero: [
          '2,5 mm',
          '3,0 mm',
          '4 mm',
          '5 mm',
          '6 mm',
          '10 mm'
        ],
        u_anyaga: [
          'PE',
          'Nylon',
          'Gumi',
          'ALU',
          'carbon'
        ]
      }
    },
    168: {
      priceRange: {
        min: 100,
        max: 20000
      },
      properties: {
        manufacturers: [
          'Gladio',
          'Trabucco',
          'Haldorádó',
          'Karp',
          'Drake'
        ],
        u_hossz: [
          '1 m',
          '3 m',
          '35 mm',
          '60 cm',
          '70 mm',
          '90 mm',
          '93 mm',
          '200 cm',
          '390 mm'
        ],
        u_atmero: [
          '2,5 mm',
          '3,0 mm',
          '4 mm',
          '5 mm',
          '6 mm',
          '10 mm'
        ],
        u_anyaga: [
          'PE',
          'Nylon',
          'Gumi',
          'ALU',
          'carbon'
        ]
      }
    },
    169: {
      priceRange: {
        min: 100,
        max: 20000
      },
      properties: {
        manufacturers: [
          'Gladio',
          'Trabucco',
          'Haldorádó',
          'Karp',
          'Drake'
        ],
        u_hossz: [
          '1 m',
          '3 m',
          '35 mm',
          '60 cm',
          '70 mm',
          '90 mm',
          '93 mm',
          '200 cm',
          '390 mm'
        ],
        u_atmero: [
          '2,5 mm',
          '3,0 mm',
          '4 mm',
          '5 mm',
          '6 mm',
          '10 mm'
        ],
        u_anyaga: [
          'PE',
          'Nylon',
          'Gumi',
          'ALU',
          'carbon'
        ]
      }
    }
  },
  dedicatedFilters: {
    1: [
      "2 részes",
      "3 részes"
    ],
    25: [
      "Szakállas",
      "Szakáll nélküli"
    ],
    26: [
      "Egyágú",
      "Kétágú",
      "Háromágú",
    ],
    35: [
      "Klasszikus",
      "Bojli",
      "Feeder",
      "Magvak",
      "Pellet"
    ],
    39: [
      "Élőanyaghoz",
      "Etetőanyaghoz",
      "Bojli adalék"
    ],
    46: [
      "Támolygó/",
      "Kanál",
      "Körforgó"
    ],
    47: [
      "Egyrészes",
      "Többrészes"
    ],
    54: [
      "Ólmok",
      "Ragasztó"
    ],
    65: [
      "Testes",
      "Test nélküli"
    ],
    72: [
      "Táska csalikhoz",
      "Táska kellékekhez"
    ],
    110: [
      "Zsinórsüllyesztő",
      "Csúszóólom",
      "Végólom"
    ],
    114: [
      "Klasszikus",
      "Feeder",
      "Method"
    ]
  }
}
