<div class="bg-secondary d-flex">
  <div class="col-8 d-flex flex-column mb-4">
    <div>
      <h2>Kategóriák</h2>
      <a [routerLink]="c.link" *ngFor="let c of categories">{{c.name + ' ' }}</a>
    </div>
    <div class="d-flex flex-column">
      <h3>Gyártók</h3>
      <a [routerLink]="'kereses'" [queryParams]="{manufacturer: m}" *ngFor="let m of manufacturers">{{m}}</a>
    </div>
  </div>
  <div class="col-14 d-flex flex-wrap">
    <h2>Termékek</h2>
    <div *ngFor="let p of products" class="col-12 d-flex flex-column">
<!--      TODO routerLink = "${category}/${subcategory}/${productId}-->"
      <a [routerLink]="['termek', p.articleNumber]">{{p.name}}</a>
    </div>
  </div>
</div>
