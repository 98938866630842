<div class="container-fluid masterhead">

  <div class="row w-100">
    <div class="col-md-12 col-lg-4 logo"><img (click)="navigate(HOME_LINK)" src="assets/images/logo.png"
                                              class="img-fluid mb-5 mb-lg-0" style="cursor: pointer;" alt="horgász webshop"></div>
    <div class="col-md-12 col-lg-3 d-flex align-items-center phone-container">
      <a href="tel:+36202194634">
        <i class="fa fa-mobile-phone"></i>
        <span>20 219 46 34</span>
      </a>
    </div>
    <div class="col-lg-10 d-flex align-content-between flex-wrap">

      <div class="search-bar" (click)="showSearchResult = true" (clickOutside)="showSearchResult = false">
        <input class="search-input" autocomplete="off" type="text" name="search" maxlength="80"
               placeholder="Mit keres? Pl. pontyozó bot, pergető orsó" [(ngModel)]="query"
               (ngModelChange)="search($event)">
        <app-search-result class="position-absolute"
                           style="z-index: 9999;left: 0;top: 62px;"
                           *ngIf="searchResult && showSearchResult"
                           [categories]="searchedCategories"
                           [products]="searchResult.products"
                           [manufacturers]="searchResult.manufacturers"></app-search-result>
        <span class="search-icon"><i class="fa fa-search"></i></span>
      </div>

      <!--NAVBAR--------------------------------------------------------->
      <nav class="navbar navbar-expand-lg w-100">

        <div class="collapse navbar-collapse" id="mainNav">

          <div class="navbar-nav mx-auto w-100 d-flex justify-content-between">

            <div class="btn-group btn-group-sm">
              <button type="button"
                      class="btn btn-sm btn-link nav-link"
                      (click)="clickCategory()"
                      matRipple [matRippleUnbounded]="false"
                      [matRippleCentered]="true"
                      [matRippleRadius]="200"><a href="#"><i class="fa fa-bars"></i> Kategóriák</a></button>
            </div>

            <div class="btn-group btn-group-sm">
              <button type="button"
                      class="btn btn-sm btn-link nav-link"
                      matRipple [matRippleUnbounded]="false"
                      [matRippleCentered]="true"
                      [matRippleRadius]="200"><a href="#"><i class="fa fa-exclamation-circle"></i> Akciók</a></button>
            </div>

            <!-- HALAK ---------------------------------------------------- -->
            <div class="btn-group btn-group-sm" dropdown>
              <button id="button-fish"
                      dropdownToggle
                      matRipple [matRippleUnbounded]="false"
                      [matRippleCentered]="true"
                      [matRippleRadius]="200"
                      type="button"
                      class="btn btn-sm btn-link dropdown-toggle"
                      aria-controls="dropdown-fish">Halak <i class="fa fa-angle-down"></i>
              </button>
              <ul id="dropdown-fish" *dropdownMenu class="dropdown-menu" role="menu" aria-labelledby="button-fish">
                <li role="menuitem"><a class="dropdown-item" href="#">Halszótár</a></li>
                <li role="menuitem"><a class="dropdown-item" [routerLink]="'halak/horgasz-szabalyok'">Horgász szabályok</a>
                </li>
                <div class="dropdown-divider"></div>
                <li role="menuitem"><a class="dropdown-item" [routerLink]="'halak/ponty'">Ponty</a></li>
                <li role="menuitem"><a class="dropdown-item" [routerLink]="'halak/csuka'">Csuka</a></li>
                <li role="menuitem"><a class="dropdown-item" [routerLink]="'halak/sullo'">Süllő</a></li>
                <li role="menuitem"><a class="dropdown-item" [routerLink]="'halak/harcsa'">Harcsa</a></li>
                <li role="menuitem"><a class="dropdown-item" [routerLink]="'halak/keszegfelek'">Keszegfélék</a></li>
                <div class="dropdown-divider"></div>
                <li role="menuitem"><a class="dropdown-item" [routerLink]="'halak/hazai-halfajok'">Hazai halfajok</a></li>
              </ul>
            </div>

            <!-- FELSZERELESEK ---------------------------------------------------- -->
            <div class="btn-group btn-group-sm" dropdown>
              <button id="button-gear"
                      dropdownToggle
                      matRipple [matRippleUnbounded]="false"
                      [matRippleCentered]="true"
                      [matRippleRadius]="200"
                      type="button"
                      class="btn btn-sm btn-link dropdown-toggle"
                      aria-controls="dropdown-gear">Felszerelések <i class="fa fa-angle-down"></i>
              </button>
              <ul id="dropdown-gear" *dropdownMenu class="dropdown-menu" role="menu" aria-labelledby="button-gear">
                <li role="menuitem"><a class="dropdown-item" [routerLink]="'felszerelesek/horgaszbotok'">Horgászbotok</a></li>
                <li role="menuitem"><a class="dropdown-item" [routerLink]="'felszerelesek/orsok'">Orsók</a></li>
                <li role="menuitem"><a class="dropdown-item" [routerLink]="'felszerelesek/zsinorok'">Zsinórok</a></li>
                <li role="menuitem"><a class="dropdown-item" [routerLink]="'felszerelesek/horgok'">Horgok</a></li>
                <li role="menuitem"><a class="dropdown-item" [routerLink]="'felszerelesek/kiegeszitok'">Kiegészítők</a></li>
                <li role="menuitem"><a class="dropdown-item" [routerLink]="'felszerelesek/csalik'">Csalik</a></li>
                <li role="menuitem"><a class="dropdown-item" [routerLink]="'felszerelesek/mucsalik'">Műcsalik</a></li>
              </ul>
            </div>


            <!-- MODSZEREK ---------------------------------------------------- -->
            <div class="btn-group btn-group-sm" dropdown>
              <button id="button-methods"
                      dropdownToggle
                      matRipple [matRippleUnbounded]="false"
                      [matRippleCentered]="true"
                      [matRippleRadius]="200"
                      type="button"
                      class="btn btn-sm btn-link dropdown-toggle"
                      aria-controls="dropdown-methods">Módszerek <i class="fa fa-angle-down"></i>
              </button>
              <ul id="dropdown-methods" *dropdownMenu class="dropdown-menu" role="menu"
                  aria-labelledby="button-methods">
                <li role="menuitem"><a class="dropdown-item" [routerLink]="'modszerek/csalik'">Hagyományos és speciális horgászmódszerek</a></li>
                <li role="menuitem"><a class="dropdown-item" [routerLink]="'modszerek/uszozas'">Úszózás</a></li>
                <li role="menuitem"><a class="dropdown-item" [routerLink]="'modszerek/fenekezes'">Fenekezés</a></li>
                <li role="menuitem"><a class="dropdown-item" [routerLink]="'modszerek/pergetes'">Pergetés</a></li>
                <li role="menuitem"><a class="dropdown-item" [routerLink]="'modszerek/mulegyezes'">Műlegyezés</a></li>
                <li role="menuitem"><a class="dropdown-item" [routerLink]="'modszerek/kuttyogatas-es-egyeb'">Kuttyogatás és egyéb</a></li>
              </ul>
            </div>

            <!-- VIZEK ---------------------------------------------------- -->
            <div class="btn-group btn-group-sm" dropdown>
              <button id="button-waters"
                      dropdownToggle
                      matRipple [matRippleUnbounded]="false"
                      [matRippleCentered]="true"
                      [matRippleRadius]="200"
                      type="button"
                      class="btn btn-sm btn-link dropdown-toggle"
                      aria-controls="dropdown-waters">Vizek <i class="fa fa-angle-down"></i>
              </button>
              <ul id="dropdown-waters" *dropdownMenu class="dropdown-menu" role="menu" aria-labelledby="button-waters">
                <li role="menuitem"><a class="dropdown-item" [routerLink]="'vizek/horgaszvizek-magyarorszagon'">Horgászvizek Magyarországon</a></li>
                <li role="menuitem"><a class="dropdown-item" [routerLink]="'vizek/vizallasok'">Vízállások</a></li>
                <div class="dropdown-divider"></div>
                <li role="menuitem"><a class="dropdown-item" [routerLink]="'vizek/folyok'">Folyók</a></li>
                <li role="menuitem"><a class="dropdown-item" [routerLink]="'vizek/patakok'">Patakok</a></li>
                <li role="menuitem"><a class="dropdown-item" [routerLink]="'vizek/csatornak'">Csatornák</a></li>
                <li role="menuitem"><a class="dropdown-item" [routerLink]="'vizek/taval'">Tavak</a></li>
                <li role="menuitem"><a class="dropdown-item" [routerLink]="'vizek/tarozok'">Tározók</a></li>
                <li role="menuitem"><a class="dropdown-item" [routerLink]="'vizek/holtagak'">Holtágak</a></li>
              </ul>
            </div>


          </div>

        </div> <!-- navbar-collapse.// -->
      </nav>

    </div>
    <div class="col-lg-5 d-flex align-content-between align-items-center justify-content-center login">

      <div class="d-flex align-content-center" *ngIf="!user">
        <i class="fa fa-user-o" (click)="openLogin('LOGIN');"></i>
        <div>
          <p (click)="openLogin('LOGIN');"><span>Belépés</span></p>
          <p (click)="openLogin('REG');"><span>Regisztráció</span></p>
        </div>
      </div>


      <div class="d-flex" *ngIf="user">
        <i class="fa fa-user-o" [routerLink]="'/profil'" tooltip="Profilom" [delay]="500"></i>
        <div (click)="logout()"><span>Kijelentkezés</span></div>
        <div dropdown class="user-dropdown" triggers="hover" #dp="bs-dropdown">
          <div id="button-user"
               dropdownToggle
               (mouseenter)="dp.isOpen=true;"
               matRipple [matRippleUnbounded]="false"
               [matRippleCentered]="true"
               [matRippleRadius]="200"
               class="dropdown-toggle"
               aria-controls="dropdown-user">BELÉPVE: {{user}} <i class="fa fa-angle-down align-bottom"></i>
          </div>
          <ul id="dropdown-user" *dropdownMenu class="dropdown-menu" role="menu"
              aria-labelledby="button-user">
            <li role="menuitem"><a class="dropdown-item" [routerLink]="'profil/sajat-fiok'">Személyes adataim</a></li>
            <li role="menuitem"><a class="dropdown-item" [routerLink]="'profil/rendeleseim'">Rendeléseim</a></li>
            <li role="menuitem"><a class="dropdown-item" [routerLink]="'profil/ertekelesek'">Értékeléseim</a></li>
          </ul>
        </div>
      </div>

      <!--      <div class="btn-group" dropdown triggers="hover"-->
      <!--           #dp="bs-dropdown">-->
      <!--        <ul *dropdownMenu class="dropdown-menu" role="menu"-->
      <!--            (mouseleave)="dp.hide()">-->
      <!--      <a class="mr-5 mb-3" [routerLink]="'/profil'" *ngIf="user">{{user}}</a>-->
      <!--      <a><i class="fa fa-user-o" *ngIf="user"></i></a>-->

    </div>
    <div class="col-lg-2 d-flex align-items-center justify-content-end cart">
      <i tooltip="A kosár üres" [delay]="500" class="fa fa-shopping-cart" aria-hidden="true"
         *ngIf="contOfItem == 0"></i>
      <i (click)="cartClicked.emit()" class="fa fa-shopping-cart" aria-hidden="true" *ngIf="contOfItem > 0"></i>
      <sup class="badge badge-danger badge-pill">{{contOfItem}}</sup>
    </div>


  </div><!-- // row end-->

</div>
