import { Component, Input, OnInit } from '@angular/core';
import { Address } from '../../../model/Address';
import { FormGroup } from '@angular/forms';
import { FormService } from '../../../service/form.service';
import { ProfileService } from '../../../service/profile.service';
import { UserState } from '../../../store/user/UserReducer';
import { Store } from '@ngrx/store';
import { DeleteAddress, ModifyAddress } from '../../../store/user/UserActions';

@Component({
  selector: 'app-address',
  templateUrl: './address.component.html',
  styleUrls: ['./address.component.scss']
})
export class AddressComponent implements OnInit {

  @Input()
  address: Address;

  modifyState: boolean;

  addressForm: FormGroup;

  constructor(private readonly formService: FormService,
              private readonly profileService: ProfileService,
              private readonly userStore: Store<UserState>) {
  }

  ngOnInit(): void {
    this.addressForm = this.formService.getAddressForm(this.address);
  }

  deleteAddress() {
    this.profileService.deleteAddress(this.address.id).subscribe(() => {
      this.userStore.dispatch(new DeleteAddress(this.address.id));
    })
  }

  saveAddress() {
    this.profileService.modifyAddress(this.addressForm.value).subscribe(() => {

    })
    if (this.addressForm.invalid) {
      return;
    }
    this.modifyState = false;
    this.userStore.dispatch(new ModifyAddress(this.addressForm.getRawValue()));
  }
}
