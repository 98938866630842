import { Action } from '@ngrx/store';
import { Properties } from '../../model/Properties';

export enum PropertyActionTypes {
  InitProperties = '[INIT_PROPERTIES] Init properties'
}

export class InitProperties implements Action {
  readonly type = PropertyActionTypes.InitProperties;


  constructor(public properties: Properties) {
  }
}

export type PropertyActions =
  InitProperties;
