import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { TEST_PRODUCTS } from '../test';
import { Product } from '../model/Product';
import { Category } from '../model/Category';
import { CategoryService } from './category.service';
import { SearchResult } from '../model/SearchResult';

@Injectable({
  providedIn: 'root'
})
export class SearchService {

  constructor(private httpClient: HttpClient,
              private categoryService: CategoryService) {
  }

  search(query: string): Observable<SearchResult> {
    return this.httpClient.get<SearchResult>('/api/search?query=' + query);
  }

  searchTest(query: string): Observable<SearchResult> {
    return of(
      new SearchResult([1, 2, 3], ['ASD', 'QWE'], TEST_PRODUCTS.map(tp => Product.buildProduct(tp)).slice(0, 6))
    )
  }

  searchCategories(query: string): Array<Category> {
    const categories: Array<Category> = this.categoryService.getCategories();
    return categories.slice(0, 6);
  }

  // async getStateAsync(): Promise<Array<Category>> {
  //   let state = await this.categoryStore.select(initCategoriesSelector)
  //     .toPromise<Array<Category>>();
  //   return state;
  // }



}
