import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { Profile } from '../model/Profile';
import { Address } from '../model/Address';

@Injectable({
  providedIn: 'root'
})
export class UserService {

  constructor(private httpClient: HttpClient) {
  }

  getProfile(): Observable<Profile> {
    return this.httpClient.get<Profile>('/api/user/profile');
  }

  addAddress(address: Address): Observable<void> {
    return this.httpClient.post<void>('/api/user/address/add', {address});
  }

  removeAddress(addressId: string): Observable<void> {
    return this.httpClient.post<void>('/api/user/address/remove', {addressId});
  }

  updateAddress(address: Address): Observable<void> {
    return this.httpClient.post<void>('/api/user/address/update', {address});
  }
}
