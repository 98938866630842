import { Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges } from '@angular/core';
import { Store } from '@ngrx/store';
import { CartState } from '../../../store/cart/CartReducer';
import { Subscription } from 'rxjs';
import { CategoryState } from '../../../store/category/CategoryReducer';
import { ChangeAmountOfProduct, RemoveProductFromCart } from '../../../store/cart/CartActions';
import { ProductKey } from '../../../model/ProductKey';
import { Product } from '../../../model/Product';
import { ProductState } from '../../../store/product/ProductReducer';
import { initProductsSelector } from '../../../store/product/ProductSelector';
import { Cart } from '../../../model/Cart';
import { CartService } from '../../../service/cart.service';
import { ProductService } from '../../../service/product.service';
import { Router } from '@angular/router';
import { MatSidenav } from '@angular/material/sidenav';
import { ProductInCart } from '../../../model/ProductInCart';
import { DataExchangeService } from '../../../service/data-exchange.service';

@Component({
  selector: 'app-shopping-cart',
  templateUrl: './shopping-cart.component.html'
})
export class ShoppingCartComponent implements OnInit, OnDestroy, OnChanges {

  @Input()
  sidenav: MatSidenav;
  @Input()
  cartSidenav: MatSidenav;
  @Input()
  cart: Cart;

  productsInCart: Array<ProductInCart>;

  @Output()
  closeCartEvent: EventEmitter<void> = new EventEmitter<void>();

  reason = '';

  close(reason: string) {
    this.reason = reason;
    this.sidenav.close();
    this.cartSidenav.close();
  }

  products: ProductKey;

  productsById: { [k: number]: Product} = {};

  numberOfProducts: number;

  private subscriptions: Array<Subscription> = new Array<Subscription>();

  constructor(private readonly cartStore: Store<CartState>,
              private readonly categoryStore: Store<CategoryState>,
              private readonly productStore: Store<ProductState>,
              private readonly cartService: CartService,
              private readonly productService: ProductService,
              private readonly router: Router,
              private readonly dataexchangeService: DataExchangeService) {
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.cart && this.products) {
      for (const k of Object.keys(changes.cart.currentValue)) {
        if (!this.productsById[this.products[k].id]) {
          this.productsById[this.products[k].id] = this.products[k];
        }
      }
      this.productsInCart = Object.values(changes.cart.currentValue);
    }
  }

  ngOnInit(): void {
    const missingProducts: Array<number> = new Array<number>();
    this.productsInCart = Object.values(this.cart);
    this.subscriptions.push(
      this.productStore.select(initProductsSelector).subscribe((products) => {
        this.products = products;
      })
    );

    this.subscriptions.push(
      this.dataexchangeService.numberOfProductsInCart.subscribe((value) => {
        this.numberOfProducts = value;
      })
    )

    Object.keys(this.cart).forEach((k: string) => {
      if (!this.products[k]) {
        missingProducts.push(this.cart[k].productId);
      } else {
        this.productsById[this.products[k].id] = this.products[k];
      }
    });

    if (missingProducts.length > 0) {
      this.subscriptions.push(
        this.productService.getProductsByIds(missingProducts).subscribe((products: Array<Product>) => {
          products.forEach(p => {
            this.productsById[p.id] = p;
          });
        })
      );
    }
  }

  navigateToCheckout() {
    this.router.navigateByUrl('penztar');
    this.cartSidenav.close();
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach(s => s.unsubscribe());
  }

  removeProductFromCart(articleNumber: string) {
    this.cartStore.dispatch(new RemoveProductFromCart(articleNumber));
  }

  increaseAmount(productId: string) {
    this.changeAmount(productId, this.cart[productId].amount + 1);
  }

  decreaseAmount(productId: string) {
    this.changeAmount(productId, this.cart[productId].amount - 1);
  }

  changeAmount(productId: string, amount: number) {
    this.cartStore.dispatch(new ChangeAmountOfProduct(productId, amount));
  }
}
