<div class="btn-group btn-group-sm" dropdown>
  <button id="button-order" dropdownToggle type="button" class="btn btn-sm btn-info dropdown-toggle"
          aria-controls="dropdown-order">Rendezés <i class="fa fa-angle-down"></i>
  </button>
  <ul id="dropdown-order" *dropdownMenu class="dropdown-menu" role="menu" aria-labelledby="button-order">
    <li role="menuitem"><span class="dropdown-item">Népszerűek elöl ✓</span></li>
    <li role="menuitem"><a class="dropdown-item" href="#">Legolcsóbb elöl</a></li>
    <li role="menuitem"><a class="dropdown-item" href="#">Legdrágább elöl</a></li>
    <li role="menuitem"><a class="dropdown-item" href="#">Legjobb értékelés</a></li>
    <li role="menuitem"><a class="dropdown-item" href="#">A termék neve</a></li>
  </ul>
</div>
