export class Review {
  productId: number;
  rating: number;
  review: string;
  userId: string;
  name: string;
  creationDate: Date;

  constructor(productId: number, rating: number, review: string, userId: string, name: string, creationDate: Date) {
    this.productId = productId;
    this.rating = rating;
    this.review = review;
    this.userId = userId;
    this.name = name;
    this.creationDate = creationDate;
  }
}
