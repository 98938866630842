<div class="mt-5 d-flex justify-content-between">
  <div *ngIf="!modifyState" class="row d-flex flex-column">
    <div>{{address.postalCode + ' ' + address.city}}</div>
    <div>{{address.streetAddress}}</div>
  </div>
  <div *ngIf="modifyState" class="row d-flex flex-column">
    <div>
      <form [formGroup]="addressForm" (submit)="saveAddress()">
        <div class="row justify-content-between">
          <div class="col-12">
            <input type="text" class="col-24 form-control addressInput" [class.invalid]="addressForm.get('country').invalid &&
        (addressForm.get('country').dirty || addressForm.get('country').touched)" name="country" formControlName="country" [placeholder]="'address.country' | translate">
          </div>
          <div class="col-12 d-flex pl-0 pr-0">
            <div class="col-10">
              <input type="text" class="form-control addressInput" [class.invalid]="addressForm.get('postalCode').invalid &&
        (addressForm.get('postalCode').dirty || addressForm.get('postalCode').touched)" name="postalCode" formControlName="postalCode" [placeholder]="'address.postalCode' | translate">
            </div>
            <div class="col-14 pl-1">
              <input type="text" class="form-control addressInput" [class.invalid]="addressForm.get('city').invalid &&
        (addressForm.get('city').dirty || addressForm.get('city').touched)" name="city" formControlName="city" [placeholder]="'address.city' | translate">
            </div>
          </div>
        </div>
        <div class="row mt-5">
          <div class="col-24">
            <input type="text" class="col-24 form-control addressInput" [class.invalid]="addressForm.get('streetAddress').invalid &&
        (addressForm.get('streetAddress').dirty || addressForm.get('streetAddress').touched)" name="address" formControlName="streetAddress" [placeholder]="'address.address' | translate">
          </div>
        </div>
        <div class="row ml-4 mt-4">
          <button class="btn btn-primary" type="submit">Mentés</button>
        </div>
      </form>
    </div>
  </div>
  <div class="col-6">
    <button class="btn btn-primary addressBtn mr-3" *ngIf="!modifyState" (click)="modifyState = true;">Szerkesztés</button>
    <button class="btn btn-outline-danger addressBtn" *ngIf="modifyState" (click)="modifyState = false;">X</button>
    <button class="btn btn-danger addressBtn" *ngIf="!modifyState" (click)="deleteAddress()">Törlés</button>
  </div>
</div>

<mat-divider></mat-divider>
