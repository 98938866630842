import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Address } from '../model/Address';
import { Observable, of } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ProfileService {

  constructor(private http: HttpClient) {
  }

  modifyAddress(address: Address): Observable<any> {
    // return this.http.post<void>('/api/address/modify', {address});
    return of(true);
  }

  deleteAddress(addressId: number) {
    // return this.http.post('/api/address/remove', {addressId})
    return of(true);
  }
}
