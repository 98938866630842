import { Component, OnInit } from '@angular/core';
import Auth from '@aws-amplify/auth';
import { FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { TranslateService } from '@ngx-translate/core';
import { FormService } from '../../../service/form.service';
import { FacebookLoginProvider, SocialAuthService, SocialUser } from 'angularx-social-login';
import { Router } from '@angular/router';
import { DataExchangeService } from '../../../service/data-exchange.service';
import { UserState } from '../../../store/user/UserReducer';
import { Store } from '@ngrx/store';
import { LoginUser } from '../../../store/user/UserActions';
import { User } from '../../../model/User';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { RegisterService } from '../../../service/register.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {

  loginForm: FormGroup;
  registerForm: FormGroup;
  forgetForm: FormGroup;

  modalType: string;

  emailFocus: boolean = false;

  hidePsw = true;

  emailFormControl = new FormControl('', [
    Validators.required,
    Validators.email,
  ]);

  nameFormControl = new FormControl('', [
    Validators.required,
  ]);


  // Pattern to match at least 1 upper case character, 1 digit and any special characters and the length between 8 to 63.
 // https://stackoverflow.com/questions/19605150/regex-for-password-must-contain-at-least-eight-characters-at-least-one-number-a

  passwordFormControl = new FormControl('', [
    Validators.required,
    Validators.pattern('^(?=.*[a-z])(?=.*[A-Z])(?=.*\\d)[a-zA-Z\\d\\W]{8,63}$'),
  ]);

  passwordAgainFormControl = new FormControl('', [
    Validators.required,
  ]);


  constructor(private readonly fb: FormBuilder,
              private readonly toastrService: ToastrService,
              private readonly translateSvice: TranslateService,
              private readonly formService: FormService,
              private readonly authService: SocialAuthService,
              private readonly router: Router,
              private readonly dataexchangeService: DataExchangeService,
              private readonly userStore: Store<UserState>,
              public readonly bsModalRef: BsModalRef,
              private readonly registerService: RegisterService) {
  }

  // validatePassword(fc: FormControl) {
  //   const value = fc.value as string;
  //   const isInvalid = value.length < 8;
  //   return isInvalid ? { passwordError: 'Password is not a strong password'} : null;
  // }

  // signInWithGoogle(): void {
  //   this.authService.signIn(GoogleLoginProvider.PROVIDER_ID);
  // }

  signInWithFB(): void {
    this.authService.signIn(FacebookLoginProvider.PROVIDER_ID)
      .then((resp: SocialUser) => {
        const user = new User(resp.id, resp.name, true, resp.photoUrl);
        localStorage.setItem('token', resp.authToken);
        localStorage.setItem('user', JSON.stringify(user));
        this.userStore.dispatch(new LoginUser(user))
        this.router.navigateByUrl('');
        this.dataexchangeService.setUser(user);
      }).catch(err => {
        console.log(err);
    })
  }

  ngOnInit(): void {
    this.loginForm = this.formService.getLoginForm();
    this.registerForm = this.formService.getRegistrationForm();
  }

  login() {
    const username = this.loginForm.value.username;
    const password = this.loginForm.value.password;

    Auth.signIn({
      username: username,
      password: password
    }).then((user: any) => {
      const authenticatedUser = new User(user.signInUserSession.accessToken.payload.sub, user.signInUserSession.accessToken.payload.username);
      localStorage.setItem('token', user.signInUserSession.accessToken.jwtToken);
      localStorage.setItem('user', JSON.stringify(authenticatedUser));
      this.userStore.dispatch(new LoginUser(new User('user', user.signInUserSession.accessToken.payload.username)))
      this.dataexchangeService.setUser(authenticatedUser);
      this.bsModalRef.hide();
    }).catch(err => {
      // this.toastrService.error(err.message);
      this.toastrService.error(this.translateSvice.instant('login.error.invalidUsernameOrPassword'));
      // this.toastrService.error(this.translateSvice.instant(err.code));
    })
  }

  forgotPassword(username: string) {
    Auth.forgotPassword(username)
      .then((resp) => {
        console.log(resp);
      })
      .catch(err => {
        console.log(err);
      });
  }

  setModalType(string) {
    this.modalType = string;
  }

  register() {
    this.registerService.register(this.registerForm.value).subscribe((resp) => {
      console.log(resp);
    }, err => {
      console.log(err);
    })
  }
}
