import { TEST_PRODUCT } from '../test';
import { Properties } from './Properties';
import { Review } from './Review';

export class Product {
  id: number;
  articleNumber: string;
  name: string;
  description: string;
  unitOfMeasure: string;
  manufacturerPartNumber: string;
  barCode: string;
  primaryPicture: string;
  packagingQuantity: number;
  vat: number;
  grossPrice: number;
  salePrice: number;
  manufacturer: string;
  link: string;
  availabilityStatus: boolean; //true/false
  parentId: string | null;

  properties: Properties;
  item: Array<Product>;
  reviews: Array<Review>;

  // netPrice: number;
  // grossPriceCarriage: number;
  // deliveryPrice: number;
  // prickPackPoint: boolean;
  // warranty: string;
  // stock: boolean;
  // itemId: string;
  // urlSite: string;
  // urlPicture: string;
  // pictures: string;

  constructor(id: number,
              articleNumber: string,
              name: string,
              description: string,
              unitOfMeasure: string,
              manufacturerPartNumber: string,
              barCode: string,
              primaryPicture: string,
              packagingQuantity: number,
              vat: number,
              grossPrice: number,
              salePrice: number,
              manufacturer: string,
              link: string,
              availabilityStatus: boolean,
              parentId: string | null,
              properties: Properties,
              item: Array<Product>,
              reviews?: Array<Review>) {
    this.id = id;
    this.articleNumber = articleNumber;
    this.name = name;
    this.description = description;
    this.unitOfMeasure = unitOfMeasure;
    this.manufacturerPartNumber = manufacturerPartNumber;
    this.barCode = barCode;
    this.primaryPicture = primaryPicture;
    this.packagingQuantity = packagingQuantity;
    this.vat = vat;
    this.grossPrice = grossPrice;
    this.salePrice = salePrice;
    this.manufacturer = manufacturer;
    this.link = link;
    this.availabilityStatus = availabilityStatus;
    this.parentId = parentId;
    this.properties = properties;
    this.item = item;
    this.reviews = reviews;
  }

  public static buildProduct(product: typeof TEST_PRODUCT | any): Product {
    const mainProductId = Math.random();
    return new Product(
      mainProductId,
      product['Cikkszám'],
      product['Terméknév (hu)'],
      product['Rövid leírás (hu)'],
      product['Mértékegység neve (hu)'],
      product['Gyártói cikkszám'],
      product['Vonalkód/GTIN Azonosító'],
      product['Elsődleges termékkép'],
      Number(product['Kiszerelés mennyiség']),
      Number(product['ÁFA']),
      Number(product['Bruttó ár']),
      Number(product['Akciós bruttó ár (Minden vevő)']),
      product['Gyártó'],
      product['Termék URL'],
      product['Rendelhető termék (Igen / Nem)'],
      product['Szülőtermék cikkszáma'],
      new Properties({
        u_hossz_m: '20',
        u_meret_50: '50'
      }),
      [
        new Product(
          Math.random(),
          product['Cikkszám'] + '1',
          product['Terméknév (hu)'],
          product['Rövid leírás (hu)'],
          product['Mértékegység neve (hu)'],
          product['Gyártói cikkszám'],
          product['Vonalkód/GTIN Azonosító'],
          product['Elsődleges termékkép'],
          Number(product['Kiszerelés mennyiség']),
          Number(product['ÁFA']),
          Number(product['Bruttó ár']),
          Number(product['Akciós bruttó ár (Minden vevő)']),
          product['Gyártó'],
          product['Termék URL'],
          product['Rendelhető termék (Igen / Nem)'],
          product['Szülőtermék cikkszáma'],
          new Properties({
            u_hossz_m: '20',
            u_meret_50: '50'
          }),
          []
        ),
        new Product(
          Math.random(),
          product['Cikkszám'] + '2',
          product['Terméknév (hu)'],
          product['Rövid leírás (hu)'],
          product['Mértékegység neve (hu)'],
          product['Gyártói cikkszám'],
          product['Vonalkód/GTIN Azonosító'],
          product['Elsődleges termékkép'],
          Number(product['Kiszerelés mennyiség']),
          Number(product['ÁFA']),
          Number(product['Bruttó ár']),
          Number(product['Akciós bruttó ár (Minden vevő)']),
          product['Gyártó'],
          product['Termék URL'],
          product['Rendelhető termék (Igen / Nem)'],
          product['Szülőtermék cikkszáma'],
          new Properties({
            u_hossz_m: '20',
            u_meret_50: '50'
          }),
          []
        )
      ],
      [
        new Review(mainProductId, 3, 'Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry\'s standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum.', '','Tamás', new Date()),
        new Review(mainProductId, 3, 'Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry\'s standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum.','', 'Tamás', new Date()),
        new Review(mainProductId, 5, 'Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry\'s standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum.','', 'Tamás', new Date()),
        new Review(mainProductId, 1, 'Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry\'s standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum.','', 'Tamás', new Date()),
        new Review(mainProductId, 1, 'Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry\'s standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum.','', 'Tamás', new Date())
      ]
    )
  }

}
