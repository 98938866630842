import { Component, OnInit, ViewChild } from '@angular/core';
import { MatSidenav } from '@angular/material/sidenav';
import { Category } from '../../../model/Category';
import { Store } from '@ngrx/store';
import { CategoryState } from '../../../store/category/CategoryReducer';
import { initCategoriesSelector } from '../../../store/category/CategorySelector';
import { CartState } from '../../../store/cart/CartReducer';
import { initCartSelector } from '../../../store/cart/CartSelector';
import { Router } from '@angular/router';
import { DataExchangeService } from '../../../service/data-exchange.service';
import { User } from '../../../model/User';
import { Cart } from '../../../model/Cart';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html'
})
export class HomeComponent implements OnInit {
  @ViewChild('sidenav') sidenav: MatSidenav;
  @ViewChild('cartSidenav') cartSidenav: MatSidenav;

  reason = '';

  categories: Array<Category>;

  cart: any;

  user: User;

  close(reason: string) {
    this.reason = reason;
    this.sidenav.close();
    this.cartSidenav.close();
  }

  constructor(private readonly categoryStore: Store<CategoryState>,
              private readonly cartStore: Store<CartState>,
              private readonly router: Router,
              private dataexchangeService: DataExchangeService) {
  }

  ngOnInit(): void {
    try {
      const userFromLocalStorage = localStorage.getItem('user');
      this.user = JSON.parse(userFromLocalStorage);
    } catch(err) {
      this.user = null;
      localStorage.removeItem('user');
    }

    if (this.user) {
      this.dataexchangeService.setUser(this.user);
    }
    this.dataexchangeService.user.subscribe((user) => {
      this.user = user;
    })
    this.categoryStore.select(initCategoriesSelector).subscribe((categories: Array<Category>) => {
      this.categories = categories;
    });
    this.cartStore.select(initCartSelector).subscribe((cart: Cart) => {
      this.cart = cart;
      if (this.cartSidenav && Object.keys(cart).length === 0) {
        this.cartSidenav.close();
      }
    });
    this.dataexchangeService.addProduct.subscribe((product) => {
      if (product) {
        this.cartSidenav.open();
      }
    })
  }

  // prepareRoute(outlet: RouterOutlet) {
  //   return outlet && outlet.activatedRouteData && outlet.activatedRouteData.animation;
  // }
}
