<div class="container d-flex">
  <div class="col-2">
    <div class="row mt-3">
      <mat-list>
        <mat-list-item><a [routerLink]="'sajat-fiok'">Saját fiók</a></mat-list-item>
        <mat-divider></mat-divider>
        <mat-list-item><a [routerLink]="'rendeleseim'">Rendelések</a></mat-list-item>
        <mat-divider></mat-divider>
        <mat-list-item><a [routerLink]="'ertekelesek'">Értékelések</a></mat-list-item>
        <mat-divider></mat-divider>
        <mat-list-item><a [routerLink]="'kedvencek'">Kedvencek</a></mat-list-item>
      </mat-list>
    </div>
  </div>
  <div class="col-22 mt-5">
    <router-outlet></router-outlet>
  </div>
</div>
