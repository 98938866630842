


  <h3><span>Béla</span> <ngx-input-star-rating [disabled]="true" [value]="review.rating"></ngx-input-star-rating> </h3>

  {{review.review}}

  <hr>


