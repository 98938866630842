import { Injectable } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { Address } from '../model/Address';
import { PersonalData } from '../model/PersonalData';
import { Company } from '../model/Company';

@Injectable({
  providedIn: 'root'
})
export class FormService {

  constructor(private fb: FormBuilder) {
  }

  getLoginForm(): FormGroup {
    return this.fb.group({
      username: new FormControl('', Validators.required),
      password: new FormControl('', [Validators.required])
    });
  }

  getRegistrationForm(): FormGroup {
    return this.fb.group({
      personalData: this.getRegistrationPersonalData(),
      password: this.getPasswordForm()
    });
  }

  getRegistrationPersonalData(): FormGroup {
    return this.fb.group({
      name: new FormControl('', [Validators.minLength(2), Validators.maxLength(30)]),
      email: new FormControl('')
    });
  }

  getCheckoutForm(): FormGroup {
    return this.fb.group({
      personalData: this.getPersonalDataForm(),
      company: this.getCompanyForm(),
      address: this.getAddressForm(),
      additionalAddress: this.getAddressForm()
    });
  }

  getPasswordForm(): FormGroup {
    return this.fb.group({
      password: new FormControl('', [Validators.required]),
      passwordAgain: new FormControl('', [Validators.required])
    });
  }

  getAddressForm(address?: Address): FormGroup {
    return this.fb.group({
      id: new FormControl({value: address ? address.id : '', disabled: true}),
      country: new FormControl(address ? address.country : '', [Validators.required, Validators.minLength(2), Validators.maxLength(30)]),
      postalCode: new FormControl(address ? address.postalCode : '', [Validators.required, Validators.minLength(2), Validators.maxLength(30)]),
      city: new FormControl(address ? address.city : '', [Validators.required, Validators.minLength(2), Validators.maxLength(30)]),
      streetAddress: new FormControl(address ? address.streetAddress : '', [Validators.required, Validators.minLength(2), Validators.maxLength(30)])
    });
  }

  getPersonalDataForm(personalData?: PersonalData): FormGroup {
    return this.fb.group({
      name: new FormControl(personalData ? personalData.name : '', [Validators.required, Validators.minLength(2), Validators.maxLength(30)]),
      email: new FormControl(personalData ? personalData.email : '', [Validators.required]),
      phoneNumber: new FormControl(personalData ? personalData.phoneNumber : '', [Validators.required])
    });
  }

  getCompanyForm(company?: Company): FormGroup {
    return this.fb.group({
      companyName: new FormControl(company ? company.name : '', [Validators.minLength(2), Validators.maxLength(30)]),
      taxNumber: new FormControl(company ? company.taxNumber : '', [Validators.minLength(2), Validators.maxLength(30)]),
      address: this.getAddressForm(company ? company.address : null)
    });
  }

  getReviewForm(productId: number) {
    return this.fb.group({
      productId: new FormControl(productId),
      rating: new FormControl(null, Validators.required),
      review: new FormControl('', Validators.maxLength(500))
    });
  }
}
