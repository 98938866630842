import { Action } from '@ngrx/store';
import { ProductKey } from '../../model/ProductKey';

export enum ProductActionTypes {
  LoadProducts = 'Load products',
  AddProducts = 'Add products'
}

export class LoadProducts implements Action {
  readonly type = ProductActionTypes.LoadProducts;

  constructor(public payload: ProductKey) {}
}

export class AddProduct implements Action {
  readonly type = ProductActionTypes.AddProducts;

  constructor(public payload: ProductKey) {}
}

export type ProductActions =
  LoadProducts |
  AddProduct;
