import { Component, Input, OnInit } from '@angular/core';
import { Order } from '../../../model/Order';

@Component({
  selector: 'app-my-order-item',
  templateUrl: './my-order-item.component.html',
  styleUrls: ['./my-order-item.component.scss']
})
export class MyOrderItemComponent implements OnInit {

  @Input()
  order: Order;

  constructor() { }

  ngOnInit(): void {
  }
}
