import { Component, forwardRef, Input, OnInit } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { LabelType, Options } from '@angular-slider/ngx-slider';

@Component({
  selector: 'app-price-filter',
  templateUrl: './price-filter.component.html',
  styleUrls: ['./price-filter.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => PriceFilterComponent),  // replace name as appropriate
      multi: true
    }
  ]
})
export class PriceFilterComponent implements OnInit, ControlValueAccessor {

  @Input()
  minPrice: number;
  @Input()
  maxPrice: number;

  value: DoubleRange;

  isDisabled: boolean;

  sliderOptions: Options;

  propagateChange: any = () => {};
  propagateTouch: any = () => {};

  constructor() { }

  ngOnInit(): void {
    this.sliderOptions = {
      floor: this.minPrice,
      ceil: this.maxPrice,
      ariaLabelHigh: 'Ft',
      ariaLabelledBy: 'Ft',
      ariaLabelledByHigh: 'Ft',
      translate:  this.translateFunction,
      step: 1,
      animate: true,
      getSelectionBarColor: (min, max) => max > 10000 ? '#CB7677' : '#79B1CC'
    }
  }

  translateFunction(value: number, label: LabelType) {
    return value + ' Ft';
  }

  registerOnChange(fn: any): void {
    this.propagateChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.propagateTouch = fn;
  }

  setDisabledState(isDisabled: boolean): void {
    this.isDisabled = isDisabled;
  }

  writeValue(obj: any): void {
    if (obj) {
      this.value = obj;
    }
  }

  valueChanged(event) {
    this.value.min = event;
    this.propagateChange(this.value);
  }

  highValueChanged(event) {
    // console.log(event)
    this.value.max = event;
    this.propagateChange(this.value);
  }

}
