import { FilterActions, FilterActionTypes } from './FilterActions';
import { Filter } from '../../model/Filter';

export interface FilterState {
  filters: { [k: number]: Filter };
  dedicatedFilters: {[k: number]: Array<string>}
}

export const initialState: FilterState = {
  filters: {},
  dedicatedFilters: {}
};

export function filterReducer(state: FilterState = initialState, action: FilterActions): FilterState {
  switch (action.type) {
    case FilterActionTypes.InitFilters:  {
      return {
        ...state,
        filters: action.filters,
        dedicatedFilters: action.dedicatedFilters
      }
    }
  }
  return state;
}

export const getFilters = (state: FilterState) => state.filters;
export const getDedicatedFilters = (state: FilterState) => state.dedicatedFilters;

