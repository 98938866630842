<!--<div class="footer-wawe-container">-->
<!--  <svg-->
<!--    preserveAspectRatio="none"-->
<!--    viewBox="0 0 1200 120"-->
<!--    xmlns="http://www.w3.org/2000/svg"-->
<!--    class="footer-wave">-->
<!--    <defs>-->
<!--      <linearGradient id="footerGradient" gradientTransform="rotate(90)">-->
<!--        <stop offset="5%"  stop-color="#79B1CC" />-->
<!--        <stop offset="95%" stop-color="#9CDEFC" />-->
<!--      </linearGradient>-->
<!--    </defs>-->

<!--    <path fill="url('#footerGradient')" d="M321.39 56.44c58-10.79 114.16-30.13 172-41.86 82.39-16.72 168.19-17.73 250.45-.39C823.78 31 906.67 72 985.66 92.83c70.05 18.48 146.53 26.09 214.34 3V0H0v27.35a600.21 600.21 0 00321.39 29.09z" />-->
<!--  </svg>-->


<!--</div>-->

<!--1920 changed to 2600 !!!!!-->

<div class="footer-wawe-container">
<div class="waves">
  <svg class="footer-wave" fill="none"
       xmlns="http://www.w3.org/2000/svg">
    <linearGradient id="grad1" x1="0%" y1="0%" x2="100%" y2="0%" gradientTransform="rotate(90)">
      <stop offset="5%"  stop-color="#4894B9" />
      <stop offset="95%" stop-color="#3E81A1" />
<!--      <stop offset="0%" stop-color="#00B4DB" />-->
<!--      <stop offset="50%" stop-color="#224488" />-->
<!--      <stop offset="100%" stop-color="#0083B0" />-->
    </linearGradient>
    <path
      fill="url(#grad1)"
      d="
          M0 67
          C 273,183
            822,-40
            2600.00,106

          V 359
          H 0
          V 67
          Z">
      <animate
        repeatCount="indefinite"
        attributeName="d"
        dur="15s"
        attributeType="XML"
        values="
            M0 77
            C 473,283
              822,-40
              2600,116

            V 359
            H 0
            V 67
            Z;

            M0 77
            C 473,-40
              1222,283
              2600,136

            V 359
            H 0
            V 67
            Z;

            M0 77
            C 973,260
              1722,-53
              2600,120

            V 359
            H 0
            V 67
            Z;

            M0 77
            C 473,283
              822,-40
              2600,116

            V 359
            H 0
            V 67
            Z
            ">
      </animate>
    </path>
  </svg>
</div>
</div>


<!--<div class="container-fluid footer">-->

<!--</div>-->
