import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html'
})
export class AppComponent implements OnInit {
  title = 'minta';

  constructor(private readonly translateService: TranslateService) {
  }

  ngOnInit(): void {
    this.translateService.use('hu_HU').subscribe((res) => {
    })
  }


}
