<div class="container-fluid product-detail">

    <div class="row">
      <div class="col-xl-19">

        <div class="row">
          <div class="col-xxl-7 d-flex align-items-center justify-content-lg-start justify-content-center p-0">
            <app-site-navigation *ngIf="siteNavigation"></app-site-navigation>
          </div>

          <div class="col-xxl-12 d-flex align-items-end justify-content-center">
            <h1>{{product.name}}</h1>
          </div>

          <div class="col-xxl-5 d-flex justify-content-xxl-start justify-content-center align-items-center rating">
            <span class="mr-2">Értékelés:</span>
            <ngx-input-star-rating [value]="averageRating" [disabled]="true"></ngx-input-star-rating>
          </div>

        </div>
        <hr>

      <div class="row mt-5 prod-card m-2 m-xl-0">

        <div class="col-xl-6">
          <div class="prod-detail-img-container">
          <img src="/assets/images/product/k-karp_019-25-950_parent_gladio_tx_micro_bite_indicator_set.jpg" alt="terméknév" class="product-img">
          </div>

          <div class="d-flex justify-content-center">
          <div class="starburst">
            <div *ngIf="product.salePrice" class="text" #priceContainer>
              <span class="salesprice"><s>{{product.grossPrice}} Ft</s></span>
              <span class="terms">helyett</span>
              <span class="offerprice" [fittext]="true" [modelToWatch]="product" [activateOnResize]="true" [container]="priceContainer">15 400 Ft</span>
            </div>
            <div *ngIf="!product.salePrice" class="text d-flex align-items-center" #priceContainer> <span class="normalprice" [fittext]="true" [modelToWatch]="product" [activateOnResize]="true" [container]="priceContainer">{{product.grossPrice | number}} Ft</span></div>
          </div>
          </div>
        </div>

        <div class="col-xl-18">
          <div class="prod-detail-content-container">
            <mat-tab-group>
              <mat-tab label="Leírás">

                <div class="mat-content-container">
                <!--            {{product | json}}-->

                  <p [innerHTML]="product.description"></p>

                <!-- PROD TABLE -->

                <div class="row prod-table d-none d-lg-flex">
                  <div class="col-md-4 table-header">Cikkszám</div>
                  <div class="col-md-8 table-header"><span>Tulajdonságok</span></div>
                  <div class="col-md-3 table-header">Ára</div>
                  <div class="col-md-2 table-header d-flex justify-content-center">Készlet</div>
                  <div class="col-md-3 table-header d-flex justify-content-center">Mennyiség</div>
                </div>

                  <div class="row prod-table">
                  <div class="col-24 divider"></div>
                  </div>


                <!-- PRODUCT START-->
                <div class="row prod-table" *ngFor="let item of product.item">

                  <div class="col-md-4 table-col d-flex justify-content-between justify-content-lg-left">
                  <div class="text-nowrap pt-4 pt-md-0"><span class="d-md-none font-weight-bold">Cikkszám:</span> {{item.articleNumber}}</div>
                  <span class="d-md-none mr-5">Raktáron <i class="fa fa-lg fa-check"></i></span>
                  </div>
                  <div class="col-md-8 table-col">
                    <span class="d-md-none table-header">Tulajdonságok:</span>
                    <table>
                      <tr *ngFor="let p of (item.properties | keyvalue)">
                        <td>{{p.key | translate}}</td>
                        <td>{{p.value}}</td>
                      </tr>
                    </table>

                  </div>
                  <div class="col-24 col-md-3 table-col"><span class="d-md-none table-header">Ára:</span> <span class="lead font-weight-bold"> {{(item.salePrice ? item.salePrice : item.grossPrice) | number}} Ft</span></div>

                  <div class="col-2 d-none d-md-flex justify-content-center"><i class="fa fa-lg fa-check mt-4"></i></div>

                  <div class="col-12 col-md-3 text-center p-0">
                    <div class="btn-group btn-quantity" role="group" aria-label="Mennyiseg">
                      <button [disabled]="amountByProduct[item.id] < 2" (click)="decreaseAmountForProduct(item.id)" type="button" class="btn btn-sub" matRipple [matRippleUnbounded]="false" [matRippleCentered]="true" tooltip="Elvesz" [delay]="1000"><i class="fa fa-minus"></i></button>
                      <input class="form-control form-control-sm price-input" [(ngModel)]="amountByProduct[item.id]" type="number" (click)="$event.target.select()" />
                      <button (click)="increaseAmountForProduct(item.id)" type="button" class="btn btn-add" matRipple [matRippleUnbounded]="false" [matRippleCentered]="true" tooltip="Hozzáad" [delay]="1000"><i class="fa fa-plus"></i></button>
                    </div>
                  </div>

                  <div class="col-12 text-right col-md-4">
                    <button class="btn btn-rounded btn-danger text-nowrap" (click)="addToCart(item)"><i class="fa fa-lg fa-cart-plus"></i> <span class="add-card-txt d-xl-none d-xxxl-inline">Kosárba</span></button>
                  </div>


                  <div class="col-24 divider"></div>
                </div>
                <!-- PRODUCT END-->

                </div><!-- mat-content-container end-->
              </mat-tab>
              <mat-tab label="Szállítás és fizetés"> Ide egységesen ugyanaz az info </mat-tab>
              <mat-tab label="Vélemények">
                <div class="container-review">

                  <h2>Értékeld a terméket!</h2>

                <form [formGroup]="reviewForm" (ngSubmit)="submitReview()">

                  <mat-form-field [hideRequiredMarker]="true" class="w-100">
                    <input matInput
                           placeholder="Neved"
                           name="name"
                           autofocus
                           autocapitalize="none"
                           autocomplete="off"
                           [formControl]="nameFormControl"
                           required
                           mat-no-asterisk>
                    <mat-error *ngIf="nameFormControl.hasError('required')">
                      Nevet kötelező írni!
                    </mat-error>
                  </mat-form-field>

                  <ngx-input-star-rating formControlName="rating" class="mb-2"></ngx-input-star-rating>


                  <mat-form-field [hideRequiredMarker]="true" class="w-100">
                    <mat-label *ngIf="!revFormControl.value">Kattints az egyik csillagra és értékeld a terméket!</mat-label>
                    <textarea matInput
                              [formControl]="revFormControl"
                              required
                              placeholder="Értékelés">
                    </textarea>
                    <mat-error *ngIf="nameFormControl.hasError('required')">
                      Értékelést szükséges írni!
                    </mat-error>
                  </mat-form-field>


                  <button type="submit" class="btn btn-rounded btn-primary w-100 mt-5" [disabled]="reviewForm.invalid">Elküld</button>
                </form>

                <div class="mt-5" *ngIf="product.reviews && product.reviews.length > 0">
                  <h2 class="pt-5">Értékelések és vélemények:</h2>
                  <hr>
                  <app-review class="mb-3" *ngFor="let r of product.reviews" [review]="r"></app-review>
                </div>
                <div *ngIf="!product.reviews || product.reviews.length === 0">
                  Legyél az első aki értékeli ezt a terméket!
                </div>

                </div>
              </mat-tab>
            </mat-tab-group>


          </div>
        </div>
      </div>
      </div>

        <div class="col-xl-5 cart-total-container">

          <div class="cart-total">
          <h5><span class="fa fa-shopping-cart"></span> Kosár összesen</h5>

          <div class="row">
            <div class="col-8">
              <b>Részösszeg</b>
            </div>
            <div class="col-16 text-right">
              2 650 Ft
            </div>
          </div>
            <div class="col-24 divider"></div>

            <div class="row">
              <div class="col-8">
                <b>Szállítás</b>
              </div>
              <div class="col-16 text-right">
                1500 Ft
              </div>
            </div>
            <div class="col-24 divider"></div>

            <div class="row lead">
              <div class="col-8">
                Összeg
              </div>
              <div class="col-16 text-right text-danger">
                4 999 Ft
              </div>
            </div>
            <div class="col-24 divider"></div>

            <p class="text-center">
            <button class="btn btn-info btn-rounded text-white mx-auto"><i class="fa fa-chevron-right"></i> Tovább a pénztárhoz </button>
            </p>

        </div>



        </div><!-- col END-->

  </div><!--  cart total END-->



</div>
<!--  container-fluid END-->


<div>
</div>
