<div class="container-fluid sub-categories">


  <div class="row">
    <div class="col-xxl-6 d-flex align-items-center justify-content-lg-start justify-content-center p-0">
      <app-site-navigation></app-site-navigation>
    </div>


    <div class="col-xxl-12 d-flex align-items-end justify-content-center">
      <h1>Horgászbotok széles választéka</h1>
    </div>

    <div class="col-xxl-6 d-flex justify-content-end align-items-end">

    </div>

  </div>


  <div class="row mt-4">
    <!--    <div class="col-12 col-lg-8 col-xxl-6" style="border: 1px solid green">-->
    <!--    <div class="d-flex justify-content-center flex-wrap">-->

    <app-sub-category-card (navigateEvent)="navigateToSubCategory($event)" class="col-12 col-md-8 col-lg-6 col-xxl-4" *ngFor="let c of categories" [category]="c">

    </app-sub-category-card>
  </div>

  <div class="container-fluid product-list">
    <div class="row">
      <div class="col-lg-19">

        <div class="row">
          <div class="col-md-6 justify-content-end align-items-end"></div>
          <div class="col-md-12 d-flex align-items-end justify-content-center">
            <h1>{{title}}</h1>
          </div>

          <div class="col-md-6 d-flex justify-content-end align-items-center btn-nav">
            <app-order></app-order>
          </div>

        </div>
        <hr>


        <div class="row mt-5">
          <app-product class="col-12 col-md-8 col-lg-6 mb-5 d-flex" *ngFor="let p of products | paginate: pagination"
                       [product]="p"
                       [link]="'/' + category.link"
          ></app-product>

          <div class="col-24">
            <pagination-controls class="pt-5 pb-5 text-center" (pageChange)="pageChanged($event)"></pagination-controls>
          </div>

        </div>
      </div>

      <div class="col-lg-5 aside">

        <!--SUBCATEGORIES-------------------------------------->
        <div class="list-group">
                  <app-filter [(ngModel)]="activeFilter" [filter]="filter" (ngModelChange)="updateParams($event)"></app-filter>
          <!--        <app-sub-categories-list [category]="category" [activeSubcategory]="subCategoryParam"></app-sub-categories-list>-->
        </div>

        <!--BRANDS-------------------------------------->
        <app-brand-list></app-brand-list>


      </div><!--  row END-->

    </div><!--  aside END-->
  </div>
</div>
