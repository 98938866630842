<div class="container">
  <div class="row">
    <ngx-avatar [size]="170" *ngIf="!user.isFacebookUser" class="col-6" src="assets/images/avatar.png"></ngx-avatar>
<!--    <ngx-avatar [size]="170" [facebookId]="user.userId" class="col-6"></ngx-avatar>-->
    <app-personal-data class="col-18" [personalData]="profile.personalData"></app-personal-data>
  </div>

  <div class="row mt-5 d-flex flex-column">
    <h1>Szállítási címek</h1>
    <div class="row">
      <button class="btn btn-primary" *ngIf="!addNewAddressState" (click)="changeAddNewAddressState()">Új cím hozzáadása</button>
    </div>
    <form *ngIf="addNewAddressState" class="row" [formGroup]="addressForm" (submit)="addNewAddress()">
      <div class="col-20 flex-column mt-5">
        <div class="row justify-content-between">
          <div class="col-12">
            <input type="text" class="col-24 form-control addressInput"  [class.invalid]="addressForm.get('country').invalid &&
        (addressForm.get('country').dirty || addressForm.get('country').touched)"  name="country" formControlName="country" [placeholder]="'address.country' | translate">
          </div>
          <div class="col-12 d-flex pl-0 pr-0">
            <div class="col-10">
              <input type="text" class="form-control addressInput" [class.invalid]="addressForm.get('postalCode').invalid &&
        (addressForm.get('postalCode').dirty || addressForm.get('postalCode').touched)" name="postalCode" formControlName="postalCode" [placeholder]="'address.postalCode' | translate">
            </div>
            <div class="col-14 pl-1">
              <input type="text" class="form-control addressInput" [class.invalid]="addressForm.get('city').invalid &&
        (addressForm.get('city').dirty || addressForm.get('city').touched)" name="city" formControlName="city" [placeholder]="'address.city' | translate">
            </div>
          </div>
        </div>
        <div class="row mt-5">
          <div class="col-24">
            <input type="text" class="col-24 form-control addressInput" [class.invalid]="addressForm.get('streetAddress').invalid &&
        (addressForm.get('streetAddress').dirty || addressForm.get('streetAddress').touched)" name="address" formControlName="streetAddress" [placeholder]="'address.address' | translate">
          </div>
        </div>
        <div class="row ml-4 mt-4">
          <button class="btn btn-primary" type="submit" [disabled]="!isSubmitEnabled">Hozzáadás</button>
        </div>
      </div>
      <div class="mt-2">
        <button class="btn btn-danger" (click)="closeAddNewAddress()">X</button>
      </div>
    </form>
    <app-address *ngFor="let a of profile.addresses" [address]="a"></app-address>
  </div>

  <div class="row mt-5 d-flex flex-column">
    <h1>Cég adatok</h1>
    <app-company [company]="profile.company"></app-company>
  </div>

</div>
