<mat-horizontal-stepper class="container mt-3 stepper" style="max-width: 1200px;" linear #stepper>
  <mat-step>
    <app-checkout-shopping-cart [cart]="cart"></app-checkout-shopping-cart>
  </mat-step>
  <mat-step [stepControl]="fullForm" [editable]="true">
    <form [formGroup]="fullForm">
      <div class="container">
        <div class="row justify-content-around mb-5">
          <div class="col-8">
            <button class="btn btn-outline-primary col-24" (click)="isCompany = false;" [ngClass]="!isCompany ? 'active' : ''">{{ 'checkout.privatePerson' | translate}}</button>
          </div>
          <div class="col-8">
            <button class="btn btn-outline-primary col-24" (click)="isCompany = true;" [ngClass]="isCompany ? 'active' : ''">{{ 'checkout.companySelector' | translate}}</button>
          </div>
        </div>

        <h2 class="mt-5">Személyes adatok</h2>
        <div class="row" [formGroupName]="'personalData'">
          <div class="col-8">
            <input class="col-24 form-control" type="text" formControlName="name" [placeholder]="'contact.name' | translate">
          </div>

          <div class="col-8">
            <input type="text" class="col-24 form-control" formControlName="email" [placeholder]="'address.email' | translate">
          </div>

          <div class="col-8">
            <ngx-mat-intl-tel-input
              [preferredCountries]="['hu']"
              [enablePlaceholder]="false"
              [(ngModel)]="selectedCountry"
              [ngModelOptions]="{standalone: true}"
              [enableSearch]="true"></ngx-mat-intl-tel-input>
            <input class="form-control" type="text" formControlName="phoneNumber">
          </div>
        </div>

        <h2 class="mt-5 mb-0">Számlázási cím</h2>
        <div class="row">
          <ng-container *ngTemplateOutlet="addressTemplate; context: {form: fullForm.get('address')}"></ng-container>
        </div>



        <h2 class="mt-5" *ngIf="isCompany">Cég adatok</h2>
        <div class="row mb-5 mt-5" *ngIf="isCompany" [formGroupName]="'company'">
          <div class="col-12">
            <input class="col-24 form-control" type="text" formControlName="companyName" [placeholder]="'company.companyName' | translate">
          </div>
          <div class="col-12">
            <input class="col-24 form-control" type="text" formControlName="taxNumber" [placeholder]="'company.taxNumber' | translate">
          </div>

          <ng-container *ngTemplateOutlet="addressTemplate; context: {form: fullForm.get('company').get('address')}"></ng-container>
        </div>

        <div class="row ml-5 mt-5 mb-5">
          <div>
            <label>{{'checkout.isDeliveryAddressTheSame' | translate}}</label>
            <input type="checkbox" (ngModelChange)="changeValue($event)" [ngModel]="isDeliveryAddressTheSame" [ngModelOptions]="{standalone: true}" [placeholder]="'checkout.isDeliveryAddressTheSame' | translate">
          </div>

        </div>

        <div class="mt-3" *ngIf="!isDeliveryAddressTheSame">
          <h2>Szállítási cím</h2>
          <div class="row">
            <ng-container *ngTemplateOutlet="addressTemplate; context: {form: fullForm.get('additionalAddress')}"></ng-container>
          </div>
        </div>

        <div class="row justify-content-center mb-5 mt-5">
          <button (click)="continue()" class="btn btn-primary">{{'continue' | translate}}</button>
        </div>
      </div>
    </form>
  </mat-step>
  <mat-step>
    <ng-template matStepLabel>Done</ng-template>
    <p>You are now done.</p>
    <div>
      <button mat-button matStepperPrevious>{{'checkout.back' | translate}}</button>
      <button mat-button (click)="stepper.reset()">Reset</button>
    </div>
  </mat-step>
</mat-horizontal-stepper>



<ng-template #addressTemplate let-form="form">
  <div class="col-24 flex-column mt-5" [formGroup]="form">
    <div class="row justify-content-between">
      <div class="col-12">
        <input type="text" class="col-24 form-control" name="country" formControlName="country" [placeholder]="'address.country' | translate">
      </div>
      <div class="col-12 d-flex pl-0 pr-0">
        <div class="col-10">
          <input type="text" class="form-control" name="postalCode" formControlName="postalCode" [placeholder]="'address.postalCode' | translate">
        </div>
        <div class="col-14 pl-1">
          <input type="text" class="form-control" name="city" formControlName="city" [placeholder]="'address.city' | translate">
        </div>
      </div>
    </div>
    <div class="row mt-5">
      <div class="col-24">
        <input type="text" class="col-24 form-control" name="address" formControlName="streetAddress" [placeholder]="'address.address' | translate">
      </div>
    </div>
  </div>
</ng-template>


