import { Component, Input, OnInit } from '@angular/core';
import { Product } from '../../../model/Product';

@Component({
  selector: 'app-product-common-listing',
  templateUrl: './product-common-listing.component.html',
  styleUrls: ['./product-common-listing.component.scss']
})
export class ProductCommonListingComponent implements OnInit {

  @Input()
  products: Array<Product>;

  constructor() { }

  ngOnInit(): void {
  }

  calculateAverageRating(product) {
    return Number((product.reviews.reduce((a, b) => a + b.rating, 0) / product.reviews.length).toFixed(2));
  }

}
