<mat-sidenav-container
  class="left-sidenav-container" (backdropClick)="close('backdrop')">
  <mat-sidenav #sidenav (keydown.escape)="close('escape')" disableClose>
    <p><button mat-button (click)="close('toggle button')">Toggle</button></p>
  </mat-sidenav>

  <mat-sidenav-content>
    <p><button mat-button (click)="sidenav.open()">Open</button></p>
  </mat-sidenav-content>
</mat-sidenav-container>
