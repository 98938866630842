import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve, RouterStateSnapshot } from '@angular/router';
import { Observable, of } from 'rxjs';
import { RatingService } from './rating.service';
import { UserReview } from '../model/UserReview';

@Injectable({
  providedIn: 'root'
})
export class RatingResolverService implements Resolve<any> {

  constructor(private ratingService: RatingService) {
  }

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<any> | Promise<any> | any {
    // return this.ratingService.getRatings();
    return of(RatingResolverService.getTestRatings());
  }

  private static getTestRatings(): Array<UserReview> {
    return [
      {
        review: 'Good product',
        rating: 5,
        product: {
          name: 'Trabucco Marker Floats úszó 40g',
          articleNumber: '015-80-040',
          reviews: [{
            rating: 2,
            creationDate: new Date(),
            userId: '',
            review: '',
            name: '',
            productId: 2
          },{
            rating: 3,
            creationDate: new Date(),
            userId: '',
            review: '',
            name: '',
            productId: 2
          },{
            rating: 4,
            creationDate: new Date(),
            userId: '',
            review: '',
            name: '',
            productId: 2
          }]
        },
        creationDate: new Date()
      },
      {
        review: 'Good product',
        rating: 5,
        product: {
          name: 'Trabucco Marker Floats úszó 10g',
          articleNumber: '015-80-040',
          reviews: [{
            rating: 5,
            creationDate: new Date(),
            userId: '',
            review: '',
            name: '',
            productId: 2
          },{
            rating: 5,
            creationDate: new Date(),
            userId: '',
            review: '',
            name: '',
            productId: 2
          },{
            rating: 4,
            creationDate: new Date(),
            userId: '',
            review: '',
            name: '',
            productId: 2
          }]
        },
        creationDate: new Date()
      }
    ]
  }
}
