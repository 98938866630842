import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { Product } from '../../../model/Product';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { ProductService } from '../../../service/product.service';
import { PaginationControlsDirective, PaginationInstance } from 'ngx-pagination';
import { Store } from '@ngrx/store';
import { PaginationState } from '../../../store/pagination/PaginationReducer';
import { CategoryState } from '../../../store/category/CategoryReducer';
import { initCategoriesSelector } from '../../../store/category/CategorySelector';
import { Category } from '../../../model/Category';
import { Filter } from '../../../model/Filter';
import { ActiveFilter } from '../../../model/ActiveFilter';
import { initDedicatedFiltersSelector, initFiltersSelector } from '../../../store/filter/FilterSelector';
import { FilterState } from '../../../store/filter/FilterReducer';
import { FilterService } from '../../../service/filter.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-product-list',
  templateUrl: './product-list.component.html'
})
export class ProductListComponent implements OnInit, OnDestroy {

  @ViewChild('paginationControl')
  paginationControl: PaginationControlsDirective;

  activeFilter: ActiveFilter;

  DEFAULT_PAGE_SIZE: number = 12;

  products: Array<Product>;
  pagination: PaginationInstance;

  title: string;
  mainCategory: Category;

  categoryParam: string;
  subCategoryParam: string;

  categories: Array<Category>;

  radioModel = 'all';

  allFilters: { [k: number]: Filter };

  filter: Filter;

  isFirstLoading: boolean;

  dedicatedFilter: any[];
  dedicatedFilters: any;

  private subscriptions: Array<Subscription> = new Array<Subscription>();

  constructor(private activatedRoute: ActivatedRoute,
              private productService: ProductService,
              private readonly paginationStore: Store<PaginationState>,
              private readonly categoryStore: Store<CategoryState>,
              private readonly filterStore: Store<FilterState>,
              private readonly filterService: FilterService,
              private router: Router) {
  }

  initCategoriesAndFilters(params: Params) {
    this.categoryParam = params['category'];
    this.subCategoryParam = params['subCategory'];

    this.mainCategory = this.categories.find(c => c.link === this.categoryParam);

    if (this.isFirstLoading) {
      this.isFirstLoading = false;
      if (this.categoryParam && this.subCategoryParam) {
        const subCategory = this.mainCategory.subCategories.find(sc => sc.link === this.subCategoryParam);
        this.filter = this.allFilters[subCategory.id];
        this.activeFilter = this.filterService.initActiveFilter(this.filter);
        this.title = subCategory.longName;
        this.dedicatedFilter = this.dedicatedFilters[subCategory.id];
      } else {
        this.title = 'Keresés';
      }
      return;
    }

    // TODO if products already loaded (because of reuse strategy), dont load again, (maybe need a cache)
    if (this.categoryParam && this.subCategoryParam) {
      const subCategory = this.mainCategory.subCategories.find(sc => sc.link === this.subCategoryParam);
      this.filter = this.allFilters[subCategory.id];
      this.activeFilter = this.filterService.initActiveFilter(this.filter);
      // TODO cancel request if the filters changed in a while
      this.productService.getProductsByCategory(subCategory.id).subscribe((products) => {
        this.products = products;
      });
      this.title = subCategory.longName;

      this.dedicatedFilter = this.dedicatedFilters[subCategory.id];

      this.reInitPagination(this.products.length, this.pagination.itemsPerPage, this.pagination.currentPage);
      this.updatePagination(params);

      return;
    }

    const manufacturer = params['manufacturer'];

    if (manufacturer) {
      this.productService.searchProducts('manufacturer=' + manufacturer).subscribe((products) => {
        this.products = products;
      });
      this.title = 'results';
      return;
    }
  }

  ngOnInit(): void {
    this.isFirstLoading = true;

    this.subscriptions.push(
      this.filterStore.select(initFiltersSelector).subscribe((allFilters) => {
        this.allFilters = allFilters;
      })
    );

    this.subscriptions.push(
      this.filterStore.select(initDedicatedFiltersSelector).subscribe((filters) => {
        this.dedicatedFilters = filters;
      })
    );

    this.subscriptions.push(
      this.categoryStore.select(initCategoriesSelector).subscribe((categories) => {
        this.categories = categories;
      })
    );

    this.products = this.activatedRoute.snapshot.data['preload'];
    this.reInitPagination(this.products.length);

    this.initCategoriesAndFilters(this.activatedRoute.snapshot.params);

    this.subscriptions.push(
      this.activatedRoute.params.subscribe(params => {
        this.initCategoriesAndFilters(params);
      })
    );

    this.subscriptions.push(
      this.activatedRoute.queryParams.subscribe((params) => {
        this.updatePagination(params);
      })
    );
  }

  updatePagination(params: Params) {
    if (params.oldal_meret) {
      this.pagination.itemsPerPage = Number(params.oldal_meret);
    } else {
      this.pagination.itemsPerPage = this.DEFAULT_PAGE_SIZE;
    }
    if (params.oldal) {
      // TODO
      // if (this.pagination.itemsPerPage * this.pagination.currentPage > this.pagination.totalItems && (this.pagination.currentPage - 1) * this.pagination.itemsPerPage > this.pagination.totalItems) {
      //   this.pagination.currentPage = 1;
      // } else {
      //   this.pagination.currentPage = Number(params.oldal);
      // }
      this.pagination.currentPage = Number(params.oldal);
    } else {
      this.pagination.currentPage = 1;
    }
  }

  reInitPagination(totalItems: number, itemsPerPage: number = this.DEFAULT_PAGE_SIZE, currentPage: number = 1) {
    this.pagination = {
      totalItems: totalItems,
      itemsPerPage: itemsPerPage,
      currentPage: currentPage
    }
  }

  pageChanged(event) {
    this.updateParams(this.activeFilter, event);
  }

  filterChanged() {

  }

  updatePageSize(value: number) {
    let currentPage = this.pagination.currentPage;
    while(currentPage * value > this.pagination.totalItems && (currentPage - 1) * value > this.pagination.totalItems && currentPage !== 1) {
      currentPage--;
    }
    this.updateParams(this.activeFilter, currentPage, value);
  }

  updateParams(filter: ActiveFilter, currentPage: number = this.pagination.currentPage, itemsPerPage: number = this.pagination.itemsPerPage) {
    const properties = {}
    Object.keys(filter.properties).forEach(k => {
      const props = [];
      filter.properties[k].forEach(f => {
        if (f.isChecked) {
          props.push(f.label);
        }
      });
      if (props.length > 0) {
        properties[k] = props;
      }
    });
    const propKeys = Object.keys(properties);
    const isPriceQueried = this.filter.priceRange.min !== filter.priceRange.min || this.filter.priceRange.max !== filter.priceRange.max;
    const isPageDefault = itemsPerPage === this.DEFAULT_PAGE_SIZE && currentPage === 1;
    // let queryParam = isPriceQueried ? `?ar=${filter.priceRange.min}-${filter.priceRange.max}` : '';
    let queryParam;

    if (isPriceQueried && isPageDefault) {
      queryParam = `?ar=${filter.priceRange.min}-${filter.priceRange.max}`;
    } else if (isPriceQueried && !isPageDefault) {
      queryParam = `${currentPage === 1 ? '' : `?oldal=${currentPage}`}${itemsPerPage !== this.DEFAULT_PAGE_SIZE ? (currentPage === 1 ? `?oldal_meret=${itemsPerPage}` : `&oldal_meret=${itemsPerPage}`) : '' } &ar=${filter.priceRange.min}-${filter.priceRange.max}`;
    } else if (!isPriceQueried && isPageDefault) {
      queryParam = '';
    } else if (!isPriceQueried && !isPageDefault) {
      queryParam = `${currentPage === 1 ? '' : `?oldal=${currentPage}`}${itemsPerPage !== this.DEFAULT_PAGE_SIZE ? (currentPage === 1 ? `?oldal_meret=${itemsPerPage}` : `&oldal_meret=${itemsPerPage}`) : '' }`;
    }


    if (propKeys.length > 0) {
      propKeys.forEach((pk, index) => {
        if ((!isPriceQueried && isPageDefault) && index === 0) {
          queryParam += '?';
        } else {
          queryParam += '&';
        }
        queryParam += pk + '=' + properties[pk].toString().split(',').join('%2c');
        // console.log(properties[pk]);
        // console.log(properties[pk].toString().split(',').join('%2c'));
      })

      this.router.navigateByUrl(`${this.categoryParam }/${this.subCategoryParam}${queryParam}`)
      // this.router.navigateByUrl(`${this.category }/${this.subCategory}?ar=${filter.priceRange.min}-${filter.priceRange.max}`)
    } else {
      // const isPriceQueried = this.filter.priceRange.min !== filter.priceRange.min || this.filter.priceRange.max !== filter.priceRange.max;
      // let queryParam = isPriceQueried ? `?ar=${filter.priceRange.min}-${filter.priceRange.max}` : '';
      this.router.navigateByUrl(`${this.categoryParam }/${this.subCategoryParam}${queryParam}`)
    }

    // const queryParam = this.getQueryParam(filter);
    // this.router.navigateByUrl(`${this.categoryParam }/${this.subCategoryParam}${queryParam}`)
  }

  // TODO
  getQueryParam(filter: ActiveFilter) {
    const properties = {};
    Object.keys(filter.properties).forEach(k => {
      const props = [];
      filter.properties[k].forEach(f => {
        if (f.isChecked) {
          props.push(f.label);
        }
      });
      if (props.length > 0) {
        properties[k] = props;
      }
    });
    const propKeys = Object.keys(properties);
    const isPriceQueried = this.filter.priceRange.min !== filter.priceRange.min || this.filter.priceRange.max !== filter.priceRange.max;
    let queryParam = isPriceQueried ? `?ar=${filter.priceRange.min}-${filter.priceRange.max}` : '';
    if (propKeys.length > 0) {
      propKeys.forEach((pk, index) => {
        if (!isPriceQueried && index === 0) {
          queryParam += '?';
        } else {
          queryParam += '&';
        }
        queryParam += pk + '=' + properties[pk].toString().split(',').join('%2c');
        // console.log(properties[pk]);
        // console.log(properties[pk].toString().split(',').join('%2c'));
      })
    } else {
      const isPriceQueried = this.filter.priceRange.min !== filter.priceRange.min || this.filter.priceRange.max !== filter.priceRange.max;
      let queryParam = isPriceQueried ? `?ar=${filter.priceRange.min}-${filter.priceRange.max}` : '';
    }
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach(s => s.unsubscribe());
  }
}
