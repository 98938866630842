import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Category } from '../../../model/Category';
import { initCategoriesSelector } from '../../../store/category/CategorySelector';
import { Store } from '@ngrx/store';
import { CategoryState } from '../../../store/category/CategoryReducer';
import { Product } from '../../../model/Product';
import { ProductService } from '../../../service/product.service';
import { SiteNavigationModel } from '../../../model/SiteNavigationModel';
import { Filter } from '../../../model/Filter';
import { ActiveFilter } from '../../../model/ActiveFilter';
import { PaginationInstance } from 'ngx-pagination';
import { ProductListState } from '../../../model/ProductListState';
import { FilterState } from '../../../store/filter/FilterReducer';
import { initFiltersSelector } from '../../../store/filter/FilterSelector';
import { FilterService } from '../../../service/filter.service';

@Component({
  selector: 'app-sub-categories',
  templateUrl: './sub-categories.component.html'
})
export class SubCategoriesComponent implements OnInit {

  category: Category;

  pagination: PaginationInstance;

  allCategories: Category[] = [];
  categories: Category[] = [];
  products: Array<Product>;
  navigations: Array<SiteNavigationModel> = new Array<SiteNavigationModel>();

  allFilters: { [k: number]: Filter };

  filter: Filter;

  activeFilter: ActiveFilter;

  title: string;

  constructor(private readonly activatedRoute: ActivatedRoute,
              private readonly router: Router,
              private readonly categoryStore: Store<CategoryState>,
              private readonly filterStore: Store<FilterState>,
              private readonly productService: ProductService,
              private readonly filterService: FilterService) {
  }

  ngOnInit(): void {
    this.pagination = {
      totalItems: 29,
      currentPage: 1,
      itemsPerPage: 12
    }

    // this.activatedRoute.params.subscribe(params => {
    //   // TODO call server: load subcategories
    //   const categoryParam = params['category'];
    //
    //   this.category = this.categories.find(c => c.link === categoryParam);
    //
    //   if (!this.products) {
    //     const preload: ProductListState = this.activatedRoute.snapshot.data['preload'];
    //     this.products = preload.products;
    //     this.filter = preload.filter;
    //     return;
    //   }
    //   // if (category && !subCategory) {
    //   //   this.productService.getProductsByCategory(category).subscribe((products) => {
    //   //     this.products = products;
    //   //   });
    //   // }
    //
    //   // TODO if products already loaded (because of reuse strategy), dont load again, (maybe need a cache)
    //   if (this.category.link) {
    //     this.productService.getProductsAndFiltersByCategory(this.category.id, true).subscribe((state) => {
    //       this.products = state.products;
    //       this.filter = state.filter;
    //     });
    //     this.title = this.category.longName;
    //
    //     return;
    //   }
    //
    //   // moved to sub categories component
    //   // if(this.categoryParam) {
    //   //   this.productService.searchProducts('category=' + this.categoryParam).subscribe((products) => {
    //   //     this.products = products;
    //   //   });
    //   //   return;
    //   // }
    //
    //   const manufacturer = params['manufacturer'];
    //
    //   if (manufacturer) {
    //     this.productService.searchProducts('manufacturer=' + manufacturer).subscribe((products) => {
    //       this.products = products;
    //     });
    //     this.title = 'results';
    //     return;
    //   }
    // });

    this.filterStore.select(initFiltersSelector).subscribe((allFilters) => {
      this.allFilters = allFilters;
    });

    this.categoryStore.select(initCategoriesSelector).subscribe((categories: Array<Category>) => {
      this.allCategories = categories;
      if (this.activatedRoute.routeConfig.path === '') {
        this.categories = this.allCategories;
      }
    });
    this.activatedRoute.params.subscribe(params => {
      const category = params['category'];

      if (!this.products) {
        const preload: ProductListState = this.activatedRoute.snapshot.data['preload'];
        this.products = preload.products;
        // return;
      }

      if (category) {
        const actualCategory = this.allCategories.find(c => c.link === category);
        if (!actualCategory) {
          this.router.navigateByUrl('not-found');
          return;
        }
        this.filter = this.allFilters[actualCategory.id];
        this.activeFilter = this.filterService.initActiveFilter(this.filter);
        this.category = actualCategory;
        this.navigations.push(new SiteNavigationModel(actualCategory.name, actualCategory.link));

        this.productService.getProductsByCategory(category)
          .subscribe(resp => {
            this.products = resp;
          })
        this.categories = actualCategory.subCategories;
      }
    });
  }

  navigateToSubCategory(link: string) {
    this.router.navigateByUrl(link);
  }

  pageChanged(event) {
    this.router.navigate(
      [`${this.category.link}/`],
      {
        queryParams: {
          oldal: event
        }
      });
  }

  updateParams(filter: ActiveFilter) {
    const properties = {};
    Object.keys(filter.properties).forEach(k => {
      const props = [];
      filter.properties[k].forEach(f => {
        if (f.isChecked) {
          props.push(f.label);
        }
      });
      if (props.length > 0) {
        properties[k] = props;
      }
    });
    const propKeys = Object.keys(properties);
    const isPriceQueried = this.filter.priceRange.min !== filter.priceRange.min || this.filter.priceRange.max !== filter.priceRange.max;
    let queryParam = isPriceQueried ? `?ar=${filter.priceRange.min}-${filter.priceRange.max}` : '';
    if (propKeys.length > 0) {
      propKeys.forEach((pk, index) => {
        if (!isPriceQueried && index === 0) {
          queryParam += '?';
        } else {
          queryParam += '&';
        }
        queryParam += pk + '=' + properties[pk].toString().split(',').join('%2c');
        // console.log(properties[pk]);
        // console.log(properties[pk].toString().split(',').join('%2c'));
      })

      this.router.navigateByUrl(`${this.category.link }${queryParam}`)
      // this.router.navigateByUrl(`${this.category }/${this.subCategory}?ar=${filter.priceRange.min}-${filter.priceRange.max}`)
    } else {
      const isPriceQueried = this.filter.priceRange.min !== filter.priceRange.min || this.filter.priceRange.max !== filter.priceRange.max;
      let queryParam = isPriceQueried ? `?ar=${filter.priceRange.min}-${filter.priceRange.max}` : '';
      this.router.navigateByUrl(`${this.category.link }${queryParam}`)
    }

    // const queryParam = this.getQueryParam(filter);
    // this.router.navigateByUrl(`${this.categoryParam }/${this.subCategoryParam}${queryParam}`)
  }

}
