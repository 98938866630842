<!--<div class="container" style="margin-top: 120px;">-->

<!--  <div class="d-flex justify-content-center h-100">-->
<!--    <div class="card">-->
<!--      <div class="card-header d-flex justify-content-between">-->
<!--        <h3>Bejelentkezés</h3>-->
<!--          <div (click)="signInWithFB()"><i class="fa fa-2x fa-facebook"></i></div>-->
<!--&lt;!&ndash;          <span><i class="fab fa-google-plus-square"></i></span>&ndash;&gt;-->
<!--      </div>-->
<!--      <div class="card-body">-->
<!--        <form [formGroup]="loginForm" (submit)="login()">-->
<!--          <div class="input-group form-group">-->
<!--            <div class="input-group-prepend">-->
<!--              <span class="input-group-text"><i class="fas fa-user"></i></span>-->
<!--            </div>-->
<!--            <input type="text" class="form-control" placeholder="username" formControlName="username">-->

<!--          </div>-->
<!--          <div class="input-group form-group">-->
<!--            <div class="input-group-prepend">-->
<!--              <span class="input-group-text"><i class="fas fa-key"></i></span>-->
<!--            </div>-->
<!--            <input type="password" class="form-control" placeholder="password" formControlName="password">-->
<!--          </div>-->
<!--          <div class="form-group">-->
<!--            <input type="submit" value="Login" class="btn float-right login_btn">-->
<!--          </div>-->
<!--        </form>-->
<!--      </div>-->
<!--      <div class="card-footer">-->
<!--        <div class="d-flex justify-content-center links">-->
<!--          Még nem regisztráltál?<a [routerLink]="'/regisztracio'">Regisztálj</a>-->
<!--        </div>-->
<!--        <div class="d-flex justify-content-center">-->
<!--          <a [routerLink]="'/regisztracio'">Elfelejtetted a jelszavad?</a>-->
<!--        </div>-->
<!--      </div>-->
<!--    </div>-->
<!--  </div>-->

<!--</div>-->


<div *ngIf="modalType === 'LOGIN'">
  <div class="modal-header">
    <h4 class="modal-title pull-left">Belépés</h4>
    <button type="button" class="close pull-right" aria-label="Bezár" tooltip="Bezár" [delay]="500" (click)="bsModalRef.hide()">
      <span aria-hidden="true"><i class="fa fa-times"></i></span>
    </button>
  </div>
  <div class="modal-body">
    <form class="form-box" [formGroup]="loginForm" (submit)="login()" name="form" autocomplete="off">
      <div class="login-input-container">
        <mat-form-field [hideRequiredMarker]="true">
          <input matInput
                 placeholder="E-mail"
                 formControlName="username"
                 name="username"
                 autofocus
                 autocapitalize="none"
                 autocorrect="off"
                 autocomplete="off"
                 required
                 mat-no-asterisk
                 (focus)="emailFocus = true"
                 [readonly]="!emailFocus">
          <!--          <mat-error *ngIf="lf?.submitted && lf?.username?.errors">{{'labelErrorFill' | translate}}</mat-error>-->
        </mat-form-field>

        <mat-form-field [hideRequiredMarker]="true">
          <input type="password"
                 matInput
                 placeholder="Jelszó"
                 formControlName="password"
                 name="password"
                 autocapitalize="none"
                 autocorrect="off"
                 autocomplete="off"
                 required>
          <!--          <mat-error *ngIf="lf?.submitted && lf?.password?.errors">{{'labelErrorFill' | translate}}</mat-error>-->
        </mat-form-field>

      </div>

      <div class="form-group text-center">
        <button mat-raised-button color="primary" type="submit" [disabled]="loginForm.invalid"><i class="fa fa-chevron-right"></i> Tovább</button>
      </div>
    </form>

    <p>
      Még nem regisztráltál? <span (click)="setModalType('REG')">Regisztálj!</span>
    </p>
    <p>
      <span (click)="setModalType('FORGET')">Elfelejtetted a jelszavad?</span>
    </p>
  </div>

  <div class="modal-footer d-flex justify-content-center">
    <p>Beléphetsz a Facebook felhasználó neveddel is.<br>Kattints az ikonra!
      <i class="fa fa-3x fa-facebook" (click)="signInWithFB()"></i></p>
  </div>
</div>

<div *ngIf="modalType === 'REG'">
  <div class="modal-header">
    <h4 class="modal-title pull-left">Regisztráció új felhasználóknak</h4>
    <button type="button" class="close pull-right" aria-label="Bezár" tooltip="Bezár" [delay]="500" (click)="bsModalRef.hide()">
      <span aria-hidden="true"><i class="fa fa-times"></i></span>
    </button>
  </div>
  <div class="modal-body">
    <p class="text-left">Az űrlapon levő adatok kitöltése a regisztrációhoz kötelező.<br>
      <strong>A jelszavad legalább 8 karakteres legyen, tartalmazzon legalább egy számot és egy nagy betűt!</strong></p>

    <form class="form-box" [formGroup]="registerForm" (ngSubmit)="register()" (submit)="register()" autocomplete="off">

      <mat-form-field [hideRequiredMarker]="true" class="w-100">
        <!--              <mat-label>{{'contact.name' | translate}}</mat-label>-->
        <input matInput
               placeholder="{{'contact.name' | translate}}"
               name="name"
               autofocus
               autocapitalize="none"
               autocomplete="off"
               [formControl]="nameFormControl"
               required
               mat-no-asterisk>
        <mat-error *ngIf="nameFormControl.hasError('required')">
          Nevet kötelező írni!
        </mat-error>
      </mat-form-field>

      <mat-form-field [hideRequiredMarker]="true" class="w-100">

        <input matInput
               placeholder="{{'address.email' | translate}}"
               name="email"
               autocapitalize="none"
               autocomplete="off"
               required
               [formControl]="emailFormControl"
               mat-no-asterisk>
        <mat-error *ngIf="emailFormControl.hasError('email') && !emailFormControl.hasError('required')">
          Egy érvényes e-mail címet írj be!
        </mat-error>
        <mat-error *ngIf="emailFormControl.hasError('required')">
          E-mail cím kötelező!
        </mat-error>
      </mat-form-field>

      <mat-form-field [hideRequiredMarker]="true" class="w-100">
        <mat-label>{{'register.password' | translate}}</mat-label>
        <input matInput
               [type]="hidePsw ? 'password' : 'text'"
               name="password"
               autocapitalize="none"
               autocomplete="off"
               required
               [formControl]="passwordFormControl"
               mat-no-asterisk>
        <i matSuffix (click)="hidePsw = !hidePsw" class="fa {{hidePsw ? 'fa-angle-down' : 'fa-angle-up'}}"></i>

        <mat-error *ngIf="passwordFormControl.hasError('pattern')">
          8 karakter minimum, tartalmazzon legalább 1 számot és 1 nagy betűt!
        </mat-error>

        <mat-error *ngIf="passwordFormControl.hasError('required')">
          Jelszó megadása kötelező!
        </mat-error>

      </mat-form-field>

      <mat-form-field [hideRequiredMarker]="true" class="w-100">
        <mat-label>{{'register.passwordAgain' | translate}}</mat-label>
        <input matInput
               type="password"
               name="passwordAgain"
               autocapitalize="none"
               autocomplete="off"
               required
               [formControl]="passwordAgainFormControl"
               mat-no-asterisk>

<!--        <mat-error class="password-mismatch" *ngIf="registerForm?.get('password')?.value.passwordAgain?.hasError('mismatch')">-->
<!--          A jelszó nem egyezik.-->
<!--        </mat-error>-->

        <mat-error *ngIf="passwordAgainFormControl.hasError('required')">
          Jelszó újra megadása kötelező!
        </mat-error>
      </mat-form-field>


      <div class="row text-center d-flex justify-content-center align-items-center mt-5 mb-4">

        <button mat-raised-button color="primary" type="submit"><i class="fa fa-chevron-right"></i> {{'register.submit' | translate}}</button>
<!--        <button type="submit" [disabled]="registerForm.invalid" class="btn btn-lg btn-rounded btn-info"><i class="fa fa-chevron-right"></i> {{'register.submit' | translate}}</button>-->
      </div>

    </form>
  </div>

  <div class="modal-footer d-flex justify-content-center">
    <p>Már regisztráltál? <span (click)="setModalType('LOGIN')">Lépj be itt!</span></p>
  </div>

</div>

<div *ngIf="modalType === 'FORGET'">
  <div class="modal-header">
    <h4 class="modal-title pull-left">Elfelejtett jelszó</h4>
    <button type="button" class="close pull-right" aria-label="Bezár" tooltip="Bezár" [delay]="500" (click)="bsModalRef.hide()">
      <span aria-hidden="true"><i class="fa fa-times"></i></span>
    </button>
  </div>
  <div class="modal-body">

    Írd be az e-mail címedet és kattints a gombra!<br>A jelszavadat e-mailen fogjuk elküldeni a megadott címre.

    <form class="form-box" [formGroup]="forgetForm" (submit)="login()" name="form" autocomplete="off">

      <mat-form-field [hideRequiredMarker]="true" class="w-100">
        <input matInput
               placeholder="{{'address.email' | translate}}"
               name="email"
               autocapitalize="none"
               autocomplete="off"
               required
               [formControl]="emailFormControl"
               mat-no-asterisk>
        <mat-error *ngIf="emailFormControl.hasError('email') && !emailFormControl.hasError('required')">
          Egy érvényes e-mail címet írj be!
        </mat-error>
        <mat-error *ngIf="emailFormControl.hasError('required')">
          E-mail címet meg kell adni!
        </mat-error>
      </mat-form-field>

      <div class="form-group text-center">
       <button mat-raised-button color="primary" type="submit" [disabled]="forgetForm.invalid"><i class="fa fa-chevron-right"></i> Tovább</button>
      </div>
    </form>
  </div>
  <div class="modal-footer d-flex justify-content-center">
    <p><span (click)="setModalType('REG')">Regisztráció</span> / <span (click)="setModalType('LOGIN')">Belépés</span></p>
  </div>
</div>
