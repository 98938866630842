import { createFeatureSelector, createSelector } from '@ngrx/store';
import { initOrders, initProfile, initRatings, initUser, isLoggedIn, UserState } from './UserReducer';

export const selectUserState = createFeatureSelector<UserState>('user');

export const initUserSelector = createSelector(
  selectUserState,
  initUser
);

export const initProfileSelector = createSelector(
  selectUserState,
  initProfile
);

export const initOrderSelector = createSelector(
  selectUserState,
  initOrders
);

export const initRatingsSelector = createSelector(
  selectUserState,
  initRatings
);

export const initIsLoggedInSelector = createSelector(
  selectUserState,
  isLoggedIn
);
