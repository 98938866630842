import { Component, Input, OnInit } from '@angular/core';
import { NavigationElement } from '../../../model/NavigationElement';

@Component({
  selector: 'app-content-navigation',
  templateUrl: './content-navigation.component.html',
  styleUrls: ['./content-navigation.component.scss']
})
export class ContentNavigationComponent implements OnInit {

  @Input()
  title: string;

  @Input()
  navigations: Array<NavigationElement>;

  @Input()
  menu: string;

  @Input()
  subMenu: string;

  constructor() { }

  ngOnInit(): void {
  }
}
