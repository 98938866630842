import { Action } from '@ngrx/store';
import { Profile } from '../../model/Profile';
import { Order } from '../../model/Order';
import { UserReview } from '../../model/UserReview';
import { User } from '../../model/User';
import { Address } from '../../model/Address';
import { PersonalData } from '../../model/PersonalData';

export enum UserActionTypes {
  Login = 'Log in ',
  Logout = 'Log out ',
  InitProfile = 'Init profile',
  UpdatePersonalData = 'Update personal data',
  InitOrders = 'Init orders',
  InitRatings = 'Init ratings',
  AddAddress = 'Add address',
  RemoveAddress = 'Remove address',
  ModifyAddress = 'Modify address'
}

export class LoginUser implements Action {
  readonly type = UserActionTypes.Login;

  constructor(public user: User) {
  }
}

export class LogoutUser implements Action {
  readonly type = UserActionTypes.Logout;

  constructor() {
  }
}

export class InitProfile implements Action {
  readonly type = UserActionTypes.InitProfile;

  constructor(public profile: Profile) {
  }
}

export class UpdatePersonalData implements Action {
  readonly type = UserActionTypes.UpdatePersonalData;

  constructor(public personalData: PersonalData) {
  }
}

export class AddAddress implements Action {
  readonly type = UserActionTypes.AddAddress;

  constructor(public address: Address) {
  }
}

export class DeleteAddress implements Action {
  readonly type = UserActionTypes.RemoveAddress;

  constructor(public addressId: number) {
  }
}

export class ModifyAddress implements Action {
  readonly type = UserActionTypes.ModifyAddress;

  constructor(public address: Address) {
  }
}

export class InitOrders implements Action {
  readonly type = UserActionTypes.InitOrders;

  constructor(public orders: Array<Order>) {
  }
}

export class InitRatings implements Action {
  readonly type = UserActionTypes.InitRatings;

  constructor(public ratings: Array<UserReview>) {
  }
}


export type UserActions =
  LoginUser |
  LogoutUser |
  InitProfile |
  UpdatePersonalData |
  InitOrders |
  InitRatings |
  AddAddress |
  DeleteAddress |
  ModifyAddress;
