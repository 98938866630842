import { Injectable } from '@angular/core';
import { Router } from '@angular/router';

@Injectable()
export class ContentService {

  constructor(private readonly router: Router) {
  }

  initNewestArticleIfNotLoaded(navLink: string, articlelink: string) {
    if (this.router.url.split('/').length === 3) {
      this.router.navigateByUrl(navLink + '/' + articlelink);
    }
  }
}
