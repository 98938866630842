import { Product } from './Product';

export class SearchResult {

  categories: Array<number>;
  manufacturers: string[];
  products: Array<Product>;

  constructor(categories: Array<number>, manufacturers: string[], products: Array<Product>) {
    this.categories = categories;
    this.manufacturers = manufacturers;
    this.products = products;
  }
}
