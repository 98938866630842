import { Injectable, OnInit } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve, RouterStateSnapshot } from '@angular/router';
import { Observable, of } from 'rxjs';
import { Order } from '../model/Order';
import { UserState } from '../store/user/UserReducer';
import { Store } from '@ngrx/store';
import { initOrderSelector } from '../store/user/UserSelector';

@Injectable({
  providedIn: 'root'
})
export class MyOrderDetailsResolver implements Resolve<any>, OnInit {

  orders: Array<Order>;

  constructor(private readonly userStore: Store<UserState>) {
  }

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<any> | Promise<any> | any {
    this.userStore.select(initOrderSelector).subscribe((orders) => {
      this.orders = orders;
    })
    const order = this.orders.find(o => o.orderId === route.url[1].path);
    if (order) {
      return order;
    }
    return of(MyOrderDetailsResolver.getOrder());
  }

  private static getOrder(): Order {
    return {
      orderId: 'asd',
      status: 'DONE',
      address: {
        id: 56,
        country: 'HU',
        streetAddress: 'Address',
        city: 'Miskolc',
        postalCode: 3525,
        email: 'email'
      },
      billingAddress: {
        id: 28,
        country: 'HU',
        streetAddress: 'Address',
        city: 'Miskolc',
        postalCode: 3525,
        email: 'email'
      },
      creationDate: new Date(),
      products: [
        {
          id: 23,
          articleNumber: '015-80-040',
          name: 'Trabucco Marker Floats úszó 40g',
          primaryPicture: 'assets/images/product/k-karp_019-25-904_parent_drake_xtr_bite_alarm.jpg',
          grossPrice: 2323,
          salePrice: 2323,
          link: 'link',
          amount: 4,
          unitOfMeasure: 'db'
        },
        {
          id: 24,
          articleNumber: '015-80-040',
          name: 'Trabucco Marker Floats úszó 40g',
          primaryPicture: 'assets/images/product/k-karp_019-25-904_parent_drake_xtr_bite_alarm.jpg',
          grossPrice: 2323,
          salePrice: 2323,
          link: 'link',
          amount: 2,
          unitOfMeasure: 'db'
        }
      ],
      total: 34550
    };
  }

  ngOnInit(): void {

  }
}
