export const PROPERTIES_BY_CODE = {
  u_anyaga: { hu_HU: 'Anyaga', en_EN: 'Material' },
  u_atmero: { hu_HU: 'Átmérő', en_EN: 'Diameter' },
  u_atm_mm: { hu_HU: 'Átmérő mm', en_EN: 'Diameter mm' },
  u_attetel_cm: { hu_HU: 'Áttétel cm', en_EN: 'Gearbox cm' },
  u_belso_atmero: { hu_HU: 'Belső átm.', en_EN: 'Internal dia.' },
  u_bevonat: { hu_HU: 'Bevonat', en_EN: 'Coating' },
  u_botszam: {hu_HU: 'Tárolható botok száma', en_EN: 'Number of sticks that can be stored'},
  u_bot_akcio: { hu_HU: 'Bot akció', en_EN: 'Bot action' },
  u_bot_atm: { hu_HU: 'Bot átm.', en_EN: 'Bot dia.' },
  u_csapagyszam_hosszu: { hu_HU: 'Csapágy', en_EN: 'Bearing' },
  u_db_cs: { hu_HU: 'db/csg', en_EN: 'db / csg' },
  u_dobkapacitas: { hu_HU: 'Dobkap.', en_EN: 'Dobkap.' },
  u_dobosuly: { hu_HU: 'Dobósúly', en_EN: 'Throwing weight' },
  u_elem: { hu_HU: 'Elem', en_EN: 'Element' },
  u_elokezsinor: { hu_HU: 'Előkezsinór hossz', en_EN: 'Front cord length' },
  u_eloke_atm: { hu_HU: 'Előkezsinór átm.', en_EN: 'Front cord dia.' },
  u_erzekenyseg: { hu_HU: 'Érzékenység', en_EN: 'Sensitivity' },
  u_evszak: { hu_HU: 'Évszak', en_EN: 'Season' },
  u_fektelj: { hu_HU: 'Fékerő kg', en_EN: 'Braking force kg' },
  u_fenyforras: { hu_HU: 'Fényforrás', en_EN: 'Light source' },
  u_feszultseg: { hu_HU: 'Feszültség', en_EN: 'Tension' },
  u_forgo: { hu_HU: 'Forgó', en_EN: 'Rotating' },
  u_gyuru: { hu_HU: 'Gyűrű tip./db', en_EN: 'Ring type / pc' },
  u_horog: { hu_HU: 'Horog', en_EN: 'Hook' },
  u_hossz: { hu_HU: 'Hossz', en_EN: 'Length' },
  u_hossz_m: { hu_HU: 'Hossz m', en_EN: 'Length m' },
  u_izesites: { hu_HU: 'Ízesítés', en_EN: 'Flavoring' },
  u_javasolt_zsinor: { hu_HU: 'Javasolt zsinór', en_EN: 'Recommended cord' },
  u_kanal_hossz_mm: { hu_HU: 'Kanál hossz mm', en_EN: 'Spoon length mm' },
  u_kanal_szelesseg_mm: { hu_HU: 'Kanál szélesség mm', en_EN: 'Spoon width mm' },
  u_keverogyuru: { hu_HU: 'Keverőgyűrű átm.', en_EN: 'Mixing ring dia.' },
  u_kialakitas: { hu_HU: 'Kialakítás', en_EN: 'Design' },
  u_kiszereles: { hu_HU: 'Kisz.', en_EN: 'Kisz.' },
  u_kupossag: { hu_HU: 'Kúposság mm', en_EN: 'Conicity mm' },
  u_lyukmeret: { hu_HU: 'Lyukméret', en_EN: 'Hole size' },
  u_meret_50: { hu_HU: 'Mérete', en_EN: 'Size' },
  u_potdob: { hu_HU: 'Pótdob', en_EN: 'Replacement drum' },
  u_profil: { hu_HU: 'Profil', en_EN: 'Profile' },
  u_spicc: { hu_HU: 'Spicckészlet', en_EN: 'Tip set' },
  u_szakall: { hu_HU: 'Szakáll', en_EN: 'Beard' },
  u_szakitoszilardsag: { hu_HU: 'Szak lbs', en_EN: 'Major lbs' },
  u_szakszil_kg: { hu_HU: 'Szak.', en_EN: 'Major.' },
  u_szalak_szama: { hu_HU: 'Szálak száma', en_EN: 'Number of threads' },
  u_szallhossz: { hu_HU: 'Száll. hossz', en_EN: 'Accommodation. length' },
  u_szin: { hu_HU: 'Szín', en_EN: 'Color' },
  u_tagok_szama: { hu_HU: 'Tagok száma', en_EN: 'Number of members' },
  u_telj: { hu_HU: 'Telj.', en_EN: 'Telj.' },
  u_terhelhetoseg: { hu_HU: 'Terhelhetőség', en_EN: 'Load capacity' },
  u_tipus: { hu_HU: 'Tipus', en_EN: 'Type' },
  u_tomeg_g: { hu_HU: 'Tömeg', en_EN: 'Crowd' },
  u_tulajdonsag: { hu_HU: 'Tulajdonság', en_EN: 'Property' },
  u_uszokepesseg: { hu_HU: 'Úszóképesség', en_EN: 'Buoyancy' },
  u_uzemora: { hu_HU: 'Üzemóra', en_EN: 'Operating hours' },
  u_vontatasi_melyseg: { hu_HU: 'Vontatási mélység', en_EN: 'Towing depth' },
  u_csatl_atm_mm: { hu_HU: 'Csatl. átm. mm', en_EN: 'Csatl. dia. mm' },
  u_gravitacios_e: { hu_HU: 'Gravitációs együttható', en_EN: 'Coefficient of gravity' },
  u_spicchossz: { hu_HU: 'Spicc hossz', en_EN: 'Tip length' },
  u_lab_atmero_mm: { hu_HU: 'Láb átmérő', en_EN: 'Foot diameter' }
}
