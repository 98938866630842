<div class="card">

  <div class="card-header d-flex justify-content-center" (click)="navigateToProduct()" matRipple [matRippleUnbounded]="false" [matRippleCentered]="true" [matRippleRadius]="135">
    <img src="/assets/images/{{product.primaryPicture}}" class="card-img-prod" alt="{{product.name}}">
  </div>

  <div class="card-body">
    <h3 class="card-title" (click)="navigateToProduct()">{{product.name}}</h3>
  </div>
  <div class="card-footer text-center">


    <p *ngIf="product.salePrice" class="card-text"><s class="salesprice">{{product.grossPrice}} Ft</s> helyett <span class="offerprice">{{product.salePrice}} Ft</span></p>
    <p *ngIf="!product.salePrice" class="card-text"><span class="offerprice">{{product.grossPrice}} Ft</span></p>

    <button class="btn btn-secondary btn-rounded" (click)="navigateToProduct()"><i class="fa fa-chevron-right"></i> Részletek</button>

<!--          <div class="row">-->
<!--          <div class="col-3 btn-sub"><span matRipple [matRippleUnbounded]="false" [matRippleCentered]="true" tooltip="Elvesz" [delay]="1000">-</span></div>-->
<!--          <div class="col-3 btn-piece"><span>1</span></div>-->
<!--          <div class="col-3 btn-add"><span matRipple [matRippleUnbounded]="false" [matRippleCentered]="true" tooltip="Hozzáad" [delay]="1000">+</span></div>-->

<!--            <div class="col-7 col-lg-11 text-right">-->
<!--            <span class="fa-stack" tooltip="Kosárba tesz" [delay]="1000" (click)="addToCart()">-->
<!--            <i class="fa btn-cart fa-circle fa-stack-2x"></i>-->
<!--            <i class="fa fa-cart-plus fa-stack-1x"></i>-->
<!--          </span>-->
<!--            </div>-->

<!--            <div class="col-5 col-lg-4 text-right">-->
<!--              <span (click)="navigateToProduct()">-->
<!--            <span class="fa-stack" tooltip="Tovább a termék részletekhez" [delay]="1000">-->
<!--             <i class="fa btn-detail fa-circle fa-stack-2x"></i>-->
<!--              <i class="fa fa-chevron-right fa-stack-1x"></i>-->
<!--            </span>-->
<!--              </span>-->
<!--            </div>-->


<!--          </div>-->

<!--          <div class="input-group input-group-sm">-->
<!--            <input type="number" class="form-control form-control-sm" value="1" min="1" aria-label="mennyiség">-->
<!--            <div class="input-group-append">-->
<!--              <span class="input-group-text">db</span>-->
<!--            </div>-->
<!--          </div>-->





  </div>
</div>




