import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { CategoryState } from '../store/category/CategoryReducer';
import { Category } from '../model/Category';

@Injectable({
  providedIn: 'root'
})
export class CategoryService {

  constructor(private readonly store: Store<CategoryState>) { }

  public getCategories(): Array<Category> {

    // const sub = [
    //   new Category(uuidv4(), categoryPath + 'horgaszbotok-bojli.jpg', 'Bojlis', 'bojlis'),
    //   new Category(uuidv4(), categoryPath + 'horgaszbotok-bojli.jpg', 'Feeder', 'feeder', []),
    //   new Category(uuidv4(), categoryPath + 'horgaszbotok-bojli.jpg', 'Harcsázó', 'harcsazo', []),
    //   new Category(uuidv4(), categoryPath + 'horgaszbotok-bojli.jpg', 'Legyező', 'harcsazo', []),
    //   new Category(uuidv4(), categoryPath + 'horgaszbotok-bojli.jpg', 'Match', 'harcsazo', []),
    //   new Category(uuidv4(), categoryPath + 'horgaszbotok-bojli.jpg', 'Pergető', 'harcsazo', []),
    //   new Category(uuidv4(), categoryPath + 'horgaszbotok-bojli.jpg', 'Rakós, Bolognai', 'harcsazo', []),
    //   new Category(uuidv4(), categoryPath + 'horgaszbotok-bojli.jpg', 'Spicc', 'harcsazo', []),
    //   new Category(uuidv4(), categoryPath + 'horgaszbotok-bojli.jpg', 'Teleszkópos', 'harcsazo', []),
    //   new Category(uuidv4(), categoryPath + 'horgaszbotok-bojli.jpg', 'Alkatrészek', 'harcsazo', []),
    // ];
    //
    // const bot = new Category(uuidv4(), categoryPath + 'horgaszbotok-bojli.jpg', 'Bojlis', 'bojlis', sub)
    // const subCategories = {
    //   bot: [
    //     new Category(uuidv4(), categoryPath + 'horgaszbotok-bojli.jpg', 'Bojlis', 'bojlis'),
    //     new Category(uuidv4(), categoryPath + 'horgaszbotok-bojli.jpg', 'Feeder', 'feeder'),
    //     new Category(uuidv4(), categoryPath + 'horgaszbotok-bojli.jpg', 'Harcsázó', 'harcsazo'),
    //     new Category(uuidv4(), categoryPath + 'horgaszbotok-bojli.jpg', 'Legyező', 'harcsazo'),
    //     new Category(uuidv4(), categoryPath + 'horgaszbotok-bojli.jpg', 'Match', 'harcsazo'),
    //     new Category(uuidv4(), categoryPath + 'horgaszbotok-bojli.jpg', 'Pergető', 'harcsazo'),
    //     new Category(uuidv4(), categoryPath + 'horgaszbotok-bojli.jpg', 'Rakós, Bolognai', 'harcsazo'),
    //     new Category(uuidv4(), categoryPath + 'horgaszbotok-bojli.jpg', 'Spicc', 'harcsazo'),
    //     new Category(uuidv4(), categoryPath + 'horgaszbotok-bojli.jpg', 'Teleszkópos', 'harcsazo'),
    //     new Category(uuidv4(), categoryPath + 'horgaszbotok-bojli.jpg', 'Alkatrészek', 'harcsazo'),
    //   ],
    //   orsok: [
    //     new Category(uuidv4(), categoryPath + 'horgaszbotok-bojli.jpg', 'Bojlis', 'bojlis'),
    //     new Category(uuidv4(), categoryPath + 'horgaszbotok-bojli.jpg', 'Feeder', 'feeder', []),
    //     new Category(uuidv4(), categoryPath + 'horgaszbotok-bojli.jpg', 'Harcsázó', 'harcsazo', []),
    //     new Category(uuidv4(), categoryPath + 'horgaszbotok-bojli.jpg', 'Legyező', 'harcsazo', []),
    //     new Category(uuidv4(), categoryPath + 'horgaszbotok-bojli.jpg', 'Match', 'harcsazo', []),
    //     new Category(uuidv4(), categoryPath + 'horgaszbotok-bojli.jpg', 'Pergető', 'harcsazo', []),
    //     new Category(uuidv4(), categoryPath + 'horgaszbotok-bojli.jpg', 'Rakós, Bolognai', 'harcsazo', []),
    //     new Category(uuidv4(), categoryPath + 'horgaszbotok-bojli.jpg', 'Spicc', 'harcsazo', []),
    //     new Category(uuidv4(), categoryPath + 'horgaszbotok-bojli.jpg', 'Teleszkópos', 'harcsazo', []),
    //     new Category(uuidv4(), categoryPath + 'horgaszbotok-bojli.jpg', 'Alkatrészek', 'harcsazo', []),
    //
    //   ],
    //   zsinorok: [
    //
    //   ],
    //   horgok: [
    //
    //   ],
    //   csalik: [
    //
    //   ],
    //   mucsalik: [
    //
    //   ]
    // }
    const categoryPath = '/assets/images/categories/';
    return [
      new Category(0, categoryPath + 'horgaszbotok.jpg', 'Horgászbotok', 'horgaszbot', [
        new Category(1, categoryPath + 'horgaszbotok-bojli.jpg', 'Bojlis', 'bojlis-horgaszbot'),
        new Category(2, categoryPath + 'horgaszbotok-feeder.jpg', 'Feeder', 'feeder-horgaszbot'),
        new Category(3, categoryPath + 'horgaszbotok-match.jpg', 'Match', 'match-horgaszbot'),
        new Category(4, categoryPath + 'horgaszbotok-spicc.jpg', 'Spicc', 'spicc-horgaszbot'),
        new Category(5, categoryPath + 'horgaszbotok-harcsazo.jpg', 'Harcsázó', 'harcsazo-horgaszbot'),
        new Category(6, categoryPath + 'horgaszbotok-pergeto.jpg', 'Pergető', 'pergeto-horgaszbot'),
        new Category(7, categoryPath + 'horgaszbotok-teleszkopos.jpg', 'Teleszkópos', 'teleszkopos-horgaszbot'),
        new Category(8, categoryPath + 'horgaszbotok-legyezo.jpg', 'Legyező', 'legyezo-horgaszbot'),
        new Category(9, categoryPath + 'horgaszbotok-bolognai.jpg', 'Bolognai', 'bolognai-horgaszbot'),
        new Category(10, categoryPath + 'horgaszbotok-rakos.jpg', 'Rakós', 'rakos-horgaszbot'),
        new Category(11, categoryPath + 'horgaszbotok-alkatreszek.jpg', 'Alkatrészek', 'horgaszbot-alkatreszek'),
      ]),
      new Category(12, categoryPath + 'orso.jpg', 'Orsók', 'orsok', [
        new Category(13, categoryPath + 'horgaszbotok-bojli.jpg', 'Feeder', 'feeder'),
        new Category(14, categoryPath + 'horgaszbotok-bojli.jpg', 'Harcsázó', 'harcsazo'),
        new Category(15, categoryPath + 'horgaszbotok-bojli.jpg', 'Legyező', 'legyezo'),
        new Category(16, categoryPath + 'horgaszbotok-bojli.jpg', 'Match', 'match'),
        new Category(17, categoryPath + 'horgaszbotok-bojli.jpg', 'Nagyhalas', 'nagyhalas'),
        new Category(18, categoryPath + 'horgaszbotok-bojli.jpg', 'Nyeletőfékes', 'nyeletofekes'),
        new Category(19, categoryPath + 'horgaszbotok-bojli.jpg', 'Pergető', 'pergeto'),
        new Category(20, categoryPath + 'horgaszbotok-bojli.jpg', 'Távdobó', 'tavdobo'),
        new Category(21, categoryPath + 'horgaszbotok-bojli.jpg', 'Univerzális', 'univerzalis'),
        new Category(22, categoryPath + 'horgaszbotok-bojli.jpg', 'Multiplikátoros', 'multiplikatoros'),
        new Category(23, categoryPath + 'horgaszbotok-bojli.jpg', 'Alkatrészek és pótdobok', 'alkatreszek-potdobok'),
      ]),
      new Category(24, categoryPath + 'horgok.jpg', 'Horgok', 'horgok', [
        new Category(25, categoryPath + 'horgaszbotok-bojli.jpg', 'Békés halaknak', 'bekes-halaknak'),
        new Category(26, categoryPath + 'horgaszbotok-bojli.jpg', 'Ragadozó halaknak', 'ragadozo-halaknak'),
        new Category(27, categoryPath + 'horgaszbotok-bojli.jpg', 'Bojlis', 'bojlis'),
        new Category(28, categoryPath + 'horgaszbotok-bojli.jpg', 'Pergető', 'pergeto'),
        new Category(29, categoryPath + 'horgaszbotok-bojli.jpg', 'Harcsás', 'harcsas'),
        new Category(30, categoryPath + 'horgaszbotok-bojli.jpg', 'Feeder', 'feeder'),
        new Category(31, categoryPath + 'horgaszbotok-bojli.jpg', 'Method előke', 'method-eloke'),
        new Category(32, categoryPath + 'horgaszbotok-bojli.jpg', 'Horogszabadító, kötő, élező', 'horogszabadito-koto-elezo'),
        new Category(33, categoryPath + 'horgaszbotok-bojli.jpg', 'Drót előke', 'drot-eloke'),
      ]),
      new Category(34, categoryPath + 'csalik.jpg', 'Csalik, etetőanyagok', 'csalik', [
        new Category(35, categoryPath + 'horgaszbotok-bojli.jpg', 'Etetőanyagok', 'etetoanyagok'),
        new Category(36, categoryPath + 'horgaszbotok-bojli.jpg', 'Bojli', 'bojli'),
        new Category(37, categoryPath + 'horgaszbotok-bojli.jpg', 'Pellet', 'pellet'),
        new Category(38, categoryPath + 'horgaszbotok-bojli.jpg', 'Magvak', 'magvak'),
        new Category(39, categoryPath + 'horgaszbotok-bojli.jpg', 'Adalékok, aromák', 'adalekok-aromak'),
        new Category(40, categoryPath + 'horgaszbotok-bojli.jpg', 'Imitációk', 'imitaciok'),
        new Category(41, categoryPath + 'horgaszbotok-bojli.jpg', 'Kellékek csalizáshoz', 'kellekek-csalizashoz'),
        new Category(42, categoryPath + 'horgaszbotok-bojli.jpg', 'Kellékek etetéshez', 'kellekek-eteteshez'),
        new Category(43, categoryPath + 'horgaszbotok-bojli.jpg', 'Wafters', 'wafters'),
        new Category(44, categoryPath + 'horgaszbotok-bojli.jpg', 'Paszta', 'paszta'),
      ]),
      new Category(45, categoryPath + 'mucsalik.jpg', 'Műcsalik', 'mucsalik', [
        new Category(46, categoryPath + 'horgaszbotok-bojli.jpg', 'Villantók', 'villantok'),
        new Category(47, categoryPath + 'horgaszbotok-bojli.jpg', 'Wobblerek', 'wobblerek'),
        new Category(48, categoryPath + 'horgaszbotok-bojli.jpg', 'Drótelőkék', 'drotelokek'),
        new Category(49, categoryPath + 'horgaszbotok-bojli.jpg', 'Gumihal, gumibéka, twister', 'gumihal-gumibeka-twister'),
        new Category(50, categoryPath + 'horgaszbotok-bojli.jpg', 'Lárvák, csonti, giliszta', 'larvak-csonti-giliszta'),
        new Category(51, categoryPath + 'horgaszbotok-bojli.jpg', 'Jig', 'jig'),
        new Category(52, categoryPath + 'horgaszbotok-bojli.jpg', 'Pilker', 'pilker'),
        new Category(53, categoryPath + 'horgaszbotok-bojli.jpg', 'Spinner bait', 'spinner-bait'),
        new Category(54, categoryPath + 'horgaszbotok-bojli.jpg', 'Tartozékok', 'tartozekok')
      ]),
      new Category(55, categoryPath + 'uszok.jpg', 'Úszók', 'uszok', [
        new Category(56, categoryPath + 'horgaszbotok-bojli.jpg', 'Állóvízi', 'allovízi'),
        new Category(57, categoryPath + 'horgaszbotok-bojli.jpg', 'Folyóvízi', 'folyovízi'),
        new Category(58, categoryPath + 'horgaszbotok-bojli.jpg', 'Csepp', 'csepp'),
        new Category(59, categoryPath + 'horgaszbotok-bojli.jpg', 'Klasszikus csúszó', 'klasszikus-csuszo'),
        new Category(60, categoryPath + 'horgaszbotok-bojli.jpg', 'Búvár', 'buvar'),
        new Category(61, categoryPath + 'horgaszbotok-bojli.jpg', 'Keszegező', 'keszegezo'),
        new Category(62, categoryPath + 'horgaszbotok-bojli.jpg', 'Pontyozó', 'pontyozo'),
        new Category(63, categoryPath + 'horgaszbotok-bojli.jpg', 'Ragadozó, stupek', 'ragadozó-stupek'),
        new Category(64, categoryPath + 'horgaszbotok-bojli.jpg', 'Spiccbotos szerelék, zsebpeca', 'spiccbotos-szerelek-zsebpeca'),
        new Category(65, categoryPath + 'horgaszbotok-bojli.jpg', 'Waggler', 'waggler'),
        new Category(66, categoryPath + 'horgaszbotok-bojli.jpg', 'Kiegészítők', 'kiegeszitok'),
      ]),
      new Category(67, categoryPath + 'botzsakok-taskak.jpg', 'Botzsákok, táskák', 'botzsakok-taskak', [
        new Category(68, categoryPath + 'horgaszbotok-bojli.jpg', 'Botzsák, hordcső', 'botzsak-hordcso'),
        new Category(69, categoryPath + 'horgaszbotok-bojli.jpg', 'Étel-ital készlet', 'etel-ital-keszlet'),
        new Category(70, categoryPath + 'horgaszbotok-bojli.jpg', 'Etetőkeverő', 'etetokevero'),
        new Category(71, categoryPath + 'horgaszbotok-bojli.jpg', 'Táskák', 'taskak'),
        new Category(72, categoryPath + 'horgaszbotok-bojli.jpg', 'Hátizsákok', 'hatizsakok'),
        new Category(73, categoryPath + 'horgaszbotok-bojli.jpg', 'Vödör', 'vodor')
      ]),
      new Category(74, categoryPath + 'bottarto.jpg', 'Bottartó', 'bottarto', [
        new Category(75, categoryPath + 'horgaszbotok-bojli.jpg', 'Klasszikus bottartók', 'llasszikus-bottartok'),
        new Category(76, categoryPath + 'horgaszbotok-bojli.jpg', 'Rod Pod', 'rod-pod'),
        new Category(77, categoryPath + 'horgaszbotok-bojli.jpg', 'Sátor', 'sator'),
        new Category(78, categoryPath + 'horgaszbotok-bojli.jpg', 'Bot és top set tartó', 'bot-es-top-set-tarto'),
        new Category(79, categoryPath + 'horgaszbotok-bojli.jpg', 'Botfék, botvilla', 'botfek-botvilla'),
        new Category(80, categoryPath + 'horgaszbotok-bojli.jpg', 'Görgő', 'gorgo'),
      ]),
      new Category(81, categoryPath + 'kellekek.jpg', 'Kellékek', 'kellekek', [
        new Category(82, categoryPath + 'horgaszbotok-bojli.jpg', 'Fogó, kés, olló', 'fogó-kes-ollo'),
        new Category(83, categoryPath + 'horgaszbotok-bojli.jpg', 'Horogélező', 'horogelezo'),
        new Category(84, categoryPath + 'horgaszbotok-bojli.jpg', 'Horogszabadító', 'horogszabadito'),
        new Category(85, categoryPath + 'horgaszbotok-bojli.jpg', 'Kellékek csalizáshoz', 'kellekek-csalizashoz'),
        new Category(86, categoryPath + 'horgaszbotok-bojli.jpg', 'Kellékek etetéshez', 'kellekek-eteteshez'),
        new Category(87, categoryPath + 'horgaszbotok-bojli.jpg', 'Kellékek szerelékhez', 'kellekek-szerelekhez'),
        new Category(88, categoryPath + 'horgaszbotok-bojli.jpg', 'Feeder kiegészítők', 'feeder-kiegeszitok'),
        new Category(89, categoryPath + 'horgaszbotok-bojli.jpg', 'Rakósbotos kellékek', 'rakósbotos-kellekek'),
        new Category(90, categoryPath + 'horgaszbotok-bojli.jpg', 'Lékfúró', 'lekfuro'),
        new Category(91, categoryPath + 'horgaszbotok-bojli.jpg', 'Egyéb', 'egyeb')
      ]),
      new Category(92, categoryPath + 'szakok-halok.jpg', 'Szákok, hálók', 'szakok-halok', [
        new Category(93, categoryPath + 'horgaszbotok-bojli.jpg', 'Haltartó szák', 'haltarto-szak'),
        new Category(94, categoryPath + 'horgaszbotok-bojli.jpg', 'Merítőszák', 'meritoszak'),
        new Category(95, categoryPath + 'horgaszbotok-bojli.jpg', 'Csepp', 'csepp'),
        new Category(96, categoryPath + 'horgaszbotok-bojli.jpg', 'Szákfej', 'szakfej'),
        new Category(97, categoryPath + 'horgaszbotok-bojli.jpg', 'Búvár', 'buvar'),
        new Category(98, categoryPath + 'horgaszbotok-bojli.jpg', 'Száknyél', 'szaknyel'),
        new Category(99, categoryPath + 'horgaszbotok-bojli.jpg', 'Halfogó háló', 'halfogo-halo')
      ]),
      new Category(100, categoryPath + 'dobozok-ladak.jpg', 'Dobozok, ládák', 'dobozok-ladak', [
        new Category(101, categoryPath + 'horgaszbotok-bojli.jpg', 'Aprócikk tartó doboz', 'aprocikk-tarto-doboz'),
        new Category(102, categoryPath + 'horgaszbotok-bojli.jpg', 'Csalitartó doboz', 'csalitarto-doboz'),
        new Category(103, categoryPath + 'horgaszbotok-bojli.jpg', 'Előketartó', 'eloketarto'),
        new Category(104, categoryPath + 'horgaszbotok-bojli.jpg', 'Horgos doboz', 'horgos-doboz'),
        new Category(105, categoryPath + 'horgaszbotok-bojli.jpg', 'Műcsalitartó doboz', 'mucsalitarto-doboz'),
        new Category(106, categoryPath + 'horgaszbotok-bojli.jpg', 'Szerelékes doboz', 'szerelekes-doboz'),
        new Category(107, categoryPath + 'horgaszbotok-bojli.jpg', 'Szerelékes táska', 'szerelekes-taska'),
        new Category(108, categoryPath + 'horgaszbotok-bojli.jpg', 'Horgászláda', 'horgaszlada'),
      ]),
      new Category(109, categoryPath + 'olom-etetokosar.jpg', 'Ólom, etetőkosár', 'olom-etetokosar', [
        new Category(110, categoryPath + 'horgaszbotok-bojli.jpg', 'Bojlis', 'bojlis'),
        new Category(111, categoryPath + 'horgaszbotok-bojli.jpg', 'Cseppólom', 'cseppolom'),
        new Category(112, categoryPath + 'horgaszbotok-bojli.jpg', 'Csúszóólom', 'csuszoolom'),
        new Category(113, categoryPath + 'horgaszbotok-bojli.jpg', 'Drop shot', 'drop-shot'),
        new Category(114, categoryPath + 'horgaszbotok-bojli.jpg', 'Etetőkosár', 'etetokosar'),
        new Category(115, categoryPath + 'horgaszbotok-bojli.jpg', 'Sörét', 'sorét'),
        new Category(116, categoryPath + 'horgaszbotok-bojli.jpg', 'Fenékmérő', 'fenekmero')
      ]),
      new Category(117, categoryPath + 'forgok-kapcsok.jpg', 'Forgók, kapcsok', 'forgok-kapcsok', [
        new Category(118, categoryPath + 'horgaszbotok-bojli.jpg', 'Csúszó úszórögzítő', 'csuszo-uszorogzito'),
        new Category(119, categoryPath + 'horgaszbotok-bojli.jpg', 'Forgó', 'forgo'),
        new Category(120, categoryPath + 'horgaszbotok-bojli.jpg', 'Forgókapocs', 'forgokapocs'),
        new Category(121, categoryPath + 'horgaszbotok-bojli.jpg', 'Forgó karika', 'forgo-karika'),
        new Category(122, categoryPath + 'horgaszbotok-bojli.jpg', 'Kapocs', 'kapocs'),
        new Category(123, categoryPath + 'horgaszbotok-bojli.jpg', 'Karika', 'karika'),
        new Category(124, categoryPath + 'horgaszbotok-bojli.jpg', 'Roppantó cső', 'roppanto-cso')
      ]),
      new Category(125, categoryPath + 'kapasjelzok.jpg', 'Kapásjelzők', 'kapasjelzok', [
        new Category(126, categoryPath + 'horgaszbotok-bojli.jpg', 'Elektromos kapásjelző', 'elektromos-kapasjelzo'),
        new Category(127, categoryPath + 'horgaszbotok-bojli.jpg', 'Elektromos kapásjelző szett', 'elektromos-kapasjelzo-szett'),
        new Category(128, categoryPath + 'horgaszbotok-bojli.jpg', 'Hagyományos', 'hagyomanyos'),
        new Category(129, categoryPath + 'horgaszbotok-bojli.jpg', 'Kapásjelző tartó', 'kapasjelzo-tarto'),
        new Category(130, categoryPath + 'horgaszbotok-bojli.jpg', 'Swinger', 'swinger')
      ]),
      new Category(131, categoryPath + 'halak-vedelme.jpg', 'Halak védelme', 'halak-vedelme', [
        new Category(132, categoryPath + 'horgaszbotok-bojli.jpg', 'Fertőtlenítő', 'fertotlenito'),
        new Category(133, categoryPath + 'horgaszbotok-bojli.jpg', 'Forgó', 'forgo'),
        new Category(134, categoryPath + 'horgaszbotok-bojli.jpg', 'Matrac, bölcső', 'matrac-bolcso'),
        new Category(135, categoryPath + 'horgaszbotok-bojli.jpg', 'Mérlegelő zsák', 'merlegelo-zsak')
      ]),
      new Category(136, categoryPath + 'ruhazat.jpg', 'Ruházat', 'ruhazat', [
        new Category(137, categoryPath + 'horgaszbotok-bojli.jpg', 'Dzseki', 'dzseki'),
        new Category(138, categoryPath + 'horgaszbotok-bojli.jpg', 'Anorák', 'anorak'),
        new Category(139, categoryPath + 'horgaszbotok-bojli.jpg', 'Fej és nyakvédő csősál', 'Fej-es-nyakvedo-csosal'),
        new Category(140, categoryPath + 'horgaszbotok-bojli.jpg', 'Ing, póló, pulóver', 'ing-polo-pulover'),
        new Category(141, categoryPath + 'horgaszbotok-bojli.jpg', 'Kesztyű, zokni', 'kesztyu-zokni'),
        new Category(142, categoryPath + 'horgaszbotok-bojli.jpg', 'Mellény', 'melleny'),
        new Category(143, categoryPath + 'horgaszbotok-bojli.jpg', 'Nadrág', 'nadrag'),
        new Category(144, categoryPath + 'horgaszbotok-bojli.jpg', 'Sapka, napellenző, szemüveg', 'sapka-napellenzo-szemuveg'),
        new Category(145, categoryPath + 'horgaszbotok-bojli.jpg', 'Szett', 'szett'),
      ]),
      new Category(146, categoryPath + 'kemping.jpg', 'Kemping', 'kemping', [
        new Category(147, categoryPath + 'horgaszbotok-bojli.jpg', 'Hálózsák', 'halozsak'),
        new Category(148, categoryPath + 'horgaszbotok-bojli.jpg', 'Párna, takaró', 'parna-takaro'),
        new Category(149, categoryPath + 'horgaszbotok-bojli.jpg', 'Sátor', 'sator'),
        new Category(150, categoryPath + 'horgaszbotok-bojli.jpg', 'Sátor kellékek', 'sator-kellekek'),
        new Category(151, categoryPath + 'horgaszbotok-bojli.jpg', 'Asztal, szék, ágy', 'asztal-szek-agy'),
        new Category(152, categoryPath + 'horgaszbotok-bojli.jpg', 'Ernyő', 'ernyo'),
        new Category(153, categoryPath + 'horgaszbotok-bojli.jpg', 'Szúnyog és kullancs riasztók', 'szunyog-es-kullancs-riasztok'),
        new Category(154, categoryPath + 'horgaszbotok-bojli.jpg', 'Lámpák', 'lampak'),
        new Category(155, categoryPath + 'horgaszbotok-bojli.jpg', 'Ásó, talicska', 'aso-talicska'),
      ]),
      new Category(156, categoryPath + 'csonak.jpg', 'Csónak', 'csonak', [
        new Category(157, categoryPath + 'horgaszbotok-bojli.jpg', 'Csónak felszerelés', 'csonak-felszereles'),
        new Category(158, categoryPath + 'horgaszbotok-bojli.jpg', 'Elektromos motor', 'elektromos-motor'),
        new Category(159, categoryPath + 'horgaszbotok-bojli.jpg', 'Motor felszerelés', 'motor-felszereles')
      ]),
    ];

    // return [
    //   new Category(uuidv4(), categoryPath + 'horgaszbotok-bojli.jpg', 'Horgászbotok', 'horgaszbot', sub),
    //   new Category(uuidv4(), categoryPath + 'horgaszbotok-bojli.jpg', 'Egyik', 'egyik', [])
    // ];
  }
}
