import { Component, OnInit } from '@angular/core';
import { Product } from '../../../model/Product';
import { ActivatedRoute } from '@angular/router';
import { ProductState } from '../../../store/product/ProductReducer';
import { Store } from '@ngrx/store';
import { initProductsSelector } from '../../../store/product/ProductSelector';
import { AddProductToCart } from '../../../store/cart/CartActions';
import { ProductInCart } from '../../../model/ProductInCart';
import { CartState } from '../../../store/cart/CartReducer';
import { PropertyState } from '../../../store/property/PropertyReducer';
import { initPropertiesSelector } from '../../../store/property/PropertySelector';
import { RatingService } from '../../../service/rating.service';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import { FormService } from '../../../service/form.service';
import { DataExchangeService } from '../../../service/data-exchange.service';
import { Meta } from '@angular/platform-browser';

@Component({
  selector: 'app-product-details',
  templateUrl: './product-details.component.html'
})
export class ProductDetailsComponent implements OnInit {

  product: Product;

  translations: any;

  amountByProduct: {[k: number]: number} = {};

  reviewForm: FormGroup;

  averageRating: number;

  siteNavigation: boolean;

  nameFormControl = new FormControl('', [
    Validators.required,
  ]);

  revFormControl = new FormControl('', [
    Validators.required,
 //   Validators.minLength(8),
  ]);

  constructor(private readonly cartStore: Store<CartState>,
              private activatedRoute: ActivatedRoute,
              private productStore: Store<ProductState>,
              private propertyStore: Store<PropertyState>,
              private ratingService: RatingService,
              private formService: FormService,
              private dataexchangeService: DataExchangeService,
              private metaService: Meta) {
  }

  ngOnInit(): void {
    if (this.activatedRoute.routeConfig.path.includes('termek')) {
      this.siteNavigation = false;
    } else {
      this.siteNavigation = true;
    }
  //   TODO kivenni az url-ből a product id-t, betölteni a store-ból a productot, ha nincs ott, akkor a serverről
  //   this.product = new Product();
  //   this.product.name = 'testName';
  //   this.product.category = 'horgaszbot';
  //   this.product.grossPrice = 300;
  //   this.product.deliveryPrice = 300;
  //   this.product.netPrice = 300;
  //   this.product.grossPriceCarriage = 30;
  //   this.product.id = 'asdf';
  //   this.product.pictures = '<![CDATA[https://trabucco.../crx_carp_match_5.jpg?v=1610965040]]>';

    // this.activatedRoute.routeConfig.path.includes()
    // console.log(this.activatedRoute.url._value)

    this.averageRating = 0;

    this.activatedRoute.url.subscribe((params) => {
      this.productStore.select(initProductsSelector).subscribe((products) => {
        if (params[0].path === 'termek') {
          this.product = products[params[1].path];
        } else {
          this.product = products[params.length === 3 ? params[2].path : params[1].path];
        }
        this.metaService.addTag({name: 'description', property: this.product.description});
        this.reviewForm = this.formService.getReviewForm(this.product.id);
        this.calculateAverageRating();
      })
    });

    this.product.item.forEach(item => {
      this.amountByProduct[item.id] = 1;
    })

    this.propertyStore.select(initPropertiesSelector).subscribe((properties) => {
      this.translations = properties;
    });
  }

  calculateAverageRating() {
    this.averageRating = Number((this.product.reviews.reduce((a, b) => a + b.rating, 0) / this.product.reviews.length).toFixed(2));
  }

  addToCart(product: Product) {
    this.cartStore.dispatch(new AddProductToCart(product.articleNumber, new ProductInCart(product.id, this.amountByProduct[product.id])));
    this.dataexchangeService.setAddProduct(product);
  }

  increaseAmountForProduct(productId: number) {
    this.amountByProduct[productId] += 1;
  }

  decreaseAmountForProduct(productId: number) {
    this.amountByProduct[productId] -= 1;
  }

  submitReview() {
    this.ratingService.rateProduct(this.reviewForm.value).subscribe(() => {
      //  TODO letiltani az értékelést ehhez a termékhez
      //  hozzáadni a legújabbat
      this.calculateAverageRating();
    }, err => {
      console.log(err);
    })
  }
}
