import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { Product } from '../model/Product';
import { TEST_PRODUCTS } from '../test';
import { Filter } from '../model/Filter';
import { FilterProperty } from '../model/FilterProperty';
import { ProductListState } from '../model/ProductListState';

@Injectable({
  providedIn: 'root'
})
export class ProductService {

  constructor(private httpClient: HttpClient) {
  }

  getProductsByIds(ids: Array<number>) {
    // return this.httpClient.post('/api/products', ids);
    return of(TEST_PRODUCTS.map(tp => Product.buildProduct(tp)).filter(p => ids.includes(p.id)));
  }

  searchProducts(query: string) {
    // return this.httpClient.get<Array<Product>>(`/api/product?${query}`);
    return of(TEST_PRODUCTS.map(tp => Product.buildProduct(tp)).slice(0, 6));
  }

  getProductById(id: number): Observable<Product> {
    return this.httpClient.get<Product>(`/api/product?id=${id}`);
  }
  // main category all products
  getProductsByCategory(category_id: number, query?: string): Observable<Array<Product>> {
    // return this.httpClient.get<Array<Product>>(`/api/product?category=${category_id}`);
    return of(TEST_PRODUCTS.map(tp => Product.buildProduct(tp)));
  }

  getFavouriteProducts() {
    // return this.httpClient.get('/api/products/favourite');
    return of(TEST_PRODUCTS.map(tp => Product.buildProduct(tp)).filter((p, i) => i < 4));
  }

  getProductsAndFiltersByCategory(category_id: number, filter: boolean): Observable<ProductListState> {
    // return this.httpClient.get<Array<Product>>(`/api/product?category=${category_id}&filter=${filter}`);
    const properties = new FilterProperty();
    properties['u_anyaga'] = [
      'PE',
      'Nylon',
      'Gumi',
      'ALU',
      'carbon'
    ];
    properties['u_atmero'] = [
      '2,5 mm',
      '3,0 mm',
      '4 mm',
      '5 mm',
      '6 mm',
      '10 mm'
    ];
    properties['u_hossz'] = [
      '1 m',
      '3 m',
      '35 mm',
      '60 cm',
      '70 mm',
      '90 mm',
      '93 mm',
      '200 cm',
      '390 mm'
    ];
    return of({
      products: TEST_PRODUCTS.map(tp => Product.buildProduct(tp)),
      filter: new Filter({
        min: 255,
        max: 34578
      }, properties)
    });
  }
}
