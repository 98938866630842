import { FilterProperty } from './FilterProperty';

export class Filter {
  priceRange: DoubleRange;
  properties: FilterProperty;

  constructor(priceRange: DoubleRange, properties: FilterProperty) {
    this.priceRange = priceRange;
    this.properties = properties;
  }
}
