import { Component, EventEmitter, OnInit, Output, TemplateRef, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { CartState } from '../../../store/cart/CartReducer';
import { initCartSelector } from '../../../store/cart/CartSelector';
import { SearchService } from '../../../service/search.service';
import { SearchResult } from '../../../model/SearchResult';
import { DataExchangeService } from '../../../service/data-exchange.service';
import { BsDropdownDirective } from 'ngx-bootstrap/dropdown';
import { AuthenticationService } from '../../../service/authentication.service';
import { ToastrService } from 'ngx-toastr';
import { User } from '../../../model/User';
import { CategoryState } from '../../../store/category/CategoryReducer';
import { initCategoriesSelector } from '../../../store/category/CategorySelector';
import { Category } from '../../../model/Category';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { LoginComponent } from '../../user/login/login.component';

@Component({
  selector: 'app-masterhead',
  templateUrl: './masterhead.component.html'
})
export class MasterheadComponent implements OnInit {

  @ViewChild('dp')
  profileDropdown: BsDropdownDirective;

  @Output()
  categoryClicked: EventEmitter<string> = new EventEmitter<string>();

  @Output()
  cartClicked: EventEmitter<string> = new EventEmitter<string>();

  user: User;

  HOME_LINK: string = '';
  CART_LINK: string = '/kosar';

  contOfItem: number;

  query: string;

  searchResult: SearchResult;

  allCategories: Array<Category>;

  searchedCategories: Array<Category>;

  modalType: string;
  modalRef: BsModalRef;
  config = {
    backdrop: true,
    ignoreBackdropClick: false,
    class: 'modal-dialog-centered',
    animated: false
  };

  loginForm: FormGroup;
  passWordFocus: boolean = false;
  userNameFocus: boolean = false;

  showSearchResult: boolean;

  constructor(
    private router: Router,
    private readonly cartStore: Store<CartState>,
    private readonly categoryStore: Store<CategoryState>,
    private readonly searchService: SearchService,
    private dataexchangeService: DataExchangeService,
    private readonly authenticationService: AuthenticationService,
    private readonly toastr: ToastrService,
    private modalService: BsModalService,
    private readonly formBuilder: FormBuilder
  ) {}

  ngOnInit() {
    this.categoryStore.select(initCategoriesSelector).subscribe((categories) => {
      this.allCategories = categories;
    })
    this.cartStore.select(initCartSelector).subscribe((cart) => {
      this.contOfItem = Object.values(cart)
        .map(p => p.amount)
        .reduce((a, b) => a + b, 0);
      this.dataexchangeService.setNumberOfProductsInCart(this.contOfItem);
    });

    this.dataexchangeService.user.subscribe((user) => {
      this.user = user;
    });

    this.loginForm = this.formBuilder.group({
      username: ['', Validators.required],
      password: ['', Validators.required]
    });
    this.router.events.subscribe(() => {
      this.showSearchResult = false;
    })
  }

  clickCategory() {
    this.categoryClicked.emit('open');
  }

  navigate(link: string): void {
    this.router.navigateByUrl(link);
  }

  search(query: string) {
    if (!query || query.length === 0) {
      this.searchResult = null;
      return;
    }
    this.searchService.searchTest(query).subscribe(async (resp) => {
      // this.searchedCategories = this.allCategories.filter(c => resp.categories.includes(c.id));
      // TODO MOCK DATA -> uncomment row above
      this.searchedCategories = this.allCategories.filter((c, i) => i < 2);
      this.searchResult = resp;
      this.showSearchResult = true;
    });
  }

  logout() {
    this.authenticationService.logout().subscribe(() => {
      localStorage.removeItem('user');
      localStorage.removeItem('token');
      this.dataexchangeService.setUser(null);
      this.toastr.success('Sikeres kijelentkezés');
      this.router.navigateByUrl('');
    }, err => {
      console.log(err);
      this.toastr.error(err.error)
    })
  }

  openLogin(modalType: string) {
    const initialState = {
      modalType: modalType
    }
    this.modalRef = this.modalService.show(LoginComponent, {
      backdrop: true,
      ignoreBackdropClick: false,
      class: 'modal-dialog-centered',
      animated: false,
      initialState
    });
  }

  setModalType(string) {
    this.modalType = string;
  }

}
