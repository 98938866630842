import { Component, OnInit } from '@angular/core';
import { UserService } from '../../../service/user.service';
import { Profile } from '../../../model/Profile';
import { FormGroup } from '@angular/forms';
import { FormService } from '../../../service/form.service';
import { ActivatedRoute } from '@angular/router';
import { Store } from '@ngrx/store';
import { User } from '../../../model/User';
import { AddAddress, InitProfile } from '../../../store/user/UserActions';
import { initProfileSelector } from '../../../store/user/UserSelector';
import { DataExchangeService } from '../../../service/data-exchange.service';

@Component({
  selector: 'app-account',
  templateUrl: './account.component.html',
  styleUrls: ['./account.component.scss']
})
export class AccountComponent implements OnInit {

  user: User;

  profile: Profile;

  companyForm: FormGroup;

  addNewAddressState: boolean;

  addressForm: FormGroup;

  isSubmitEnabled: boolean = true;

  constructor(private readonly userService: UserService,
              private readonly formService: FormService,
              private readonly activatedRoute: ActivatedRoute,
              private readonly userStore: Store<User>,
              private readonly dataexchangeService: DataExchangeService) {
  }

  ngOnInit(): void {
    this.profile = this.activatedRoute.snapshot.data.preload;
    this.companyForm = this.formService.getCompanyForm(this.profile.company);
    this.userStore.dispatch(new InitProfile(this.profile));
    this.userStore.select(initProfileSelector).subscribe((profile) => {
      this.profile = profile;
    });
    this.dataexchangeService.user.subscribe((user) => {
      this.user = user;
    });
    this.addressForm = this.formService.getAddressForm();
  }

  changeAddNewAddressState() {
    this.addNewAddressState = true;
  }

  closeAddNewAddress() {
    this.addNewAddressState = false;
    this.addressForm.reset();
  }

  addNewAddress() {
    if (this.addressForm.invalid) {
      return;
    }
    this.userStore.dispatch(new AddAddress(this.addressForm.value));
    this.closeAddNewAddress();
   }
}
