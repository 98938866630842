import { NgModule, Optional, SkipSelf } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule, Routes, UrlMatchResult, UrlSegment } from '@angular/router';
import { HomeComponent } from './home/home.component';
import { MasterheadComponent } from './masterhead/masterhead.component';
import { AdheadComponent } from './adhead/adhead.component';
import { CategoriesComponent } from '../shopping/categories/categories.component';
import { FooterComponent } from './footer/footer.component';
import { AdtopComponent } from './adtop/adtop.component';
import { ProductListComponent } from '../shopping/product-list/product-list.component';
import { ProductDetailsComponent } from '../shopping/product-details/product-details.component';
import { CheckoutComponent } from '../shopping/checkout/checkout.component';
import { ProfileComponent } from '../user/profile/profile.component';
import { SharedModule } from '../shared/shared.module';
import { SidebarCategoriesComponent } from './sidebar-categories/sidebar-categories.component';
import { ShoppingModule } from '../shopping/shopping.module';
import { NotFoundComponent } from './not-found/not-found.component';
import { SearchResultComponent } from './search-result/search-result.component';
import { SubCategoriesComponent } from '../shopping/sub-categories/sub-categories.component';
import { CheckoutGuard } from '../../service/checkout.guard';
import { ProductListResolver } from '../../service/product-list-resolver';
import { LoginComponent } from '../user/login/login.component';
import { RegisterComponent } from '../user/register/register.component';
import { UserModule } from '../user/user.module';
import { AccountComponent } from '../user/account/account.component';
import { MyOrdersComponent } from '../user/my-orders/my-orders.component';
import { UserRatingsComponent } from '../user/user-ratings/user-ratings.component';
import { ProfileResolver } from '../../service/profile-resolver.service';
import { ContactComponent } from '../user/contact/contact.component';
import { MyOrderResolverService } from '../../service/my-order-resolver.service';
import { RatingResolverService } from '../../service/rating-resolver.service';
import { MyOrderDetailsComponent } from '../user/my-order-details/my-order-details.component';
import { MyOrderDetailsResolver } from '../../service/my-order-details-resolver.service';
import { LoginGuard } from '../../service/login.guard';
import { AuthenticationGuard } from '../../service/authentication.guard';
import { FavouriteProductsComponent } from '../shopping/favourite-products/favourite-products.component';
import { FavouriteProductsResolver } from '../../service/favourite-products-resolver.service';
import { ContentModule } from '../content/content.module';
import { ContentCardComponent } from '../content/content-card/content-card.component';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { SidenavLeftComponent } from './sidenav-left/sidenav-left.component';
import { CommonTemplateComponent } from '../content/common-template/common-template.component';


const routes: Routes = [
  {
    path: '',
    component: HomeComponent,
    children: [
      {
        path: '',
        component: CategoriesComponent,
        data: {
          isAdVisible: true
        }
      },
      {
        matcher: contentMatcher,
        component: CommonTemplateComponent,
        data: {
          isAdVisible: false
        }
      },
      // static content
      ///////////////////////////////////////////////////////////////////////////////////////////////////////////////
      ///////////////////////////////////////////////////////////////////////////////////////////////////////////////
      {
        path: 'termek/:id',
        component: ProductDetailsComponent,
        data: {
          isAdVisible: false
        }
      },
      {
        path: 'kapcsolat',
        component: ContactComponent,
        data: {
          isAdVisible: false
        }
      },
      {
        path: 'kereses',
        component: ProductListComponent,
        resolve: {
          preload: ProductListResolver
        },
        data: {
          isAdVisible: true
        }
      },
      {
        path: 'bejelentkezes',
        component: LoginComponent,
        canActivate: [LoginGuard],
        data: {
          isAdVisible: false
        }
      },
      {
        path: 'regisztracio',
        component: RegisterComponent,
        canActivate: [LoginGuard],
        data: {
          isAdVisible: false
        }
      },
      {
        path: 'penztar',
        component: CheckoutComponent,
        // canActivate: [CheckoutGuard],
        data: {
          isAdVisible: false
        }
      },
      {
        path: 'not-found',
        component: NotFoundComponent,
        data: {
          isAdVisible: true
        }
      },
      {
        path: 'profil',
        component: ProfileComponent,
        canActivate: [AuthenticationGuard],
        children: [
          {
            path: 'sajat-fiok',
            component: AccountComponent,
            resolve: {
              preload: ProfileResolver
            }
          },
          {
            path: 'rendeleseim',
            component: MyOrdersComponent,
            resolve: {
              preload: MyOrderResolverService
            }
          },
          {
            path: 'rendeleseim/:id',
            component: MyOrderDetailsComponent,
            resolve: {
              preload: MyOrderDetailsResolver
            }
          },
          {
            path: 'ertekelesek',
            component: UserRatingsComponent,
            resolve: {
              preload: RatingResolverService
            }
          },
          {
            path: 'kedvencek',
            component: FavouriteProductsComponent,
            resolve: {
              preload: FavouriteProductsResolver
            }
          },
          {
            path: '',
            pathMatch: 'full',
            redirectTo: 'sajat-fiok'
          }
        ],
        data: {
          isAdVisible: true
        }
      },
      {
        path: ':category',
        component: SubCategoriesComponent,
        data: {
          isAdVisible: true
        },
        resolve: {
          preload: ProductListResolver
        }
      },
      {
        component: ProductListComponent,
        matcher: subCategoryMatcher,
        data: {
          isAdVisible: true
        },
        resolve: {
          preload: ProductListResolver
        }
      },
      {
        path: ':category/:productId',
        component: ProductDetailsComponent,
        data: {
          isAdVisible: false
        }
      },
      {
        path: ':category/:subCategory/:id',
        component: ProductDetailsComponent,
        data: {
          isAdVisible: false
        }
      },
      {
        path: '',
        pathMatch: 'full',
        redirectTo: 'not-found'
      }
    ]
  }
];

export function subCategoryMatcher(url: UrlSegment[]): UrlMatchResult {
  if (url.length === 2) {
    if (/\d/.test(url[1].path)) {
      return null;
    } else {
      return {consumed: url, posParams: { category: url[0], subCategory: url[1] }};
    }
  }
  return null;
}

export function contentMatcher(url: UrlSegment[]): UrlMatchResult {
  const menuLinks = ['halak', 'felszerelesek', 'modszerek', 'vizek'];
  for (let l of menuLinks) {
    if (url[0].path.includes(l)) {
      console.log(url);
      if (url.length === 2) {
        return {consumed: url, posParams: {subMenu: url[1]}};
      } else {
        return {consumed: url, posParams: {subMenu: url[1], article: url[2]}};
      }
    }
  }
  return null;
}
@NgModule({
  declarations: [
    MasterheadComponent,
    HomeComponent,
    AdheadComponent,
    FooterComponent,
    AdtopComponent,
    SidebarCategoriesComponent,
    NotFoundComponent,
    SearchResultComponent,
    SidenavLeftComponent
  ],
  imports: [
    CommonModule,
    RouterModule.forChild(routes),
    SharedModule,
    ShoppingModule,
    UserModule,
    ContentModule,
    MatFormFieldModule,
    MatInputModule
  ],
  providers: [
    CheckoutGuard,
    ProductListResolver
  ]
})
export class CoreModule {
  constructor(@Optional() @SkipSelf() parentModule: CoreModule) {
    if (parentModule) {
      throw new Error('CoreModule is already loaded. Import it in the AppModule only');
    }
  }
}
