import { FilterPropertyState } from './FilterPropertyState';

export class ActiveFilter {
  priceRange: DoubleRange;
  properties: {[k: string]: Array<FilterPropertyState>};

  constructor() {
    this.priceRange = {};
    this.properties = {};
  }
}
