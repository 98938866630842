import { Component, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { ShoppingCartComponent } from '../shopping-cart/shopping-cart.component';

@Component({
  selector: 'app-checkout-shopping-cart',
  templateUrl: './checkout-shopping-cart.component.html',
  styleUrls: ['./checkout-shopping-cart.component.scss']
})
export class CheckoutShoppingCartComponent extends ShoppingCartComponent implements OnInit, OnChanges {

  // constructor() {
  //   super();
  // }

  ngOnInit(): void {
    super.ngOnInit();
  }

  ngOnChanges(changes: SimpleChanges): void {
    super.ngOnChanges(changes);
  }

}
