<div class="container article-detail">

  <div class="row">
    <div class="col-xl-19 article-content">
      <div *ngIf="content">
        <h1>{{content.title}}</h1>
        <div [innerHTML]="content.content" class="mt-3"></div>
      </div>
    </div>

    <div class="col-xl-5 aside">
      <app-content-navigation
        [title]="subMenu" [navigations]="actualNavigations" [menu]="menu" [subMenu]="subMenu"></app-content-navigation>
    </div>
  </div><!--  row END -->

</div>
