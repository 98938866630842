import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ProductListComponent } from './product-list/product-list.component';
import { CheckoutComponent } from './checkout/checkout.component';
import { ShoppingCartComponent } from './shopping-cart/shopping-cart.component';
import { ProductDetailsComponent } from './product-details/product-details.component';
import { ProductComponent } from './product/product.component';
import { CategoryCardComponent } from './category-card/category-card.component';
import { NgxPaginationModule } from 'ngx-pagination';
import { TranslateModule } from '@ngx-translate/core';
import { SubCategoriesComponent } from './sub-categories/sub-categories.component';
import { SubCategoryCardComponent } from './sub-category-card/sub-category-card.component';
import { SharedModule } from '../shared/shared.module';
import { TooltipModule } from 'ngx-bootstrap/tooltip';
import { CategoriesComponent } from './categories/categories.component';
import { FilterModule } from './filter/filter.module';
import { Ng2FittextModule } from 'ng2-fittext';
import { NgxSliderModule } from '@angular-slider/ngx-slider';
import { SubCategoriesListComponent } from './sub-categories-list/sub-categories-list.component';
import { BrandListComponent } from './brand-list/brand-list.component';
import { ReviewComponent } from './review/review.component';
import { FavouriteProductsComponent } from './favourite-products/favourite-products.component';
import { ProductCommonListingComponent } from './product-common-listing/product-common-listing.component';
import { CheckoutShoppingCartComponent } from './checkout-shopping-cart/checkout-shopping-cart.component';

const components = [
  ProductListComponent,
  CheckoutComponent,
  ShoppingCartComponent,
  ProductDetailsComponent,
  ProductComponent,
  CategoryCardComponent,
  SubCategoriesComponent,
  CategoriesComponent,
  SubCategoryCardComponent
];

@NgModule({
  declarations: [
    components,
    SubCategoriesListComponent,
    BrandListComponent,
    ReviewComponent,
    FavouriteProductsComponent,
    ProductCommonListingComponent,
    CheckoutShoppingCartComponent
  ],
  exports: [
    components
  ],
    imports: [
        CommonModule,
        NgxPaginationModule,
        TranslateModule,
        SharedModule,
        TooltipModule,
        FilterModule,
        Ng2FittextModule,
        NgxSliderModule
    ]
})
export class ShoppingModule {
}
