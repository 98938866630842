import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import * as fishArticles from './fishRulesContent.json';
import { HttpClient } from '@angular/common/http';
import { SeoService } from '../../../service/seo.service';

export class ContentCardModel {
  title: string;
  content: string;

  constructor(title: string, content: string) {
    this.title = title;
    this.content = content;
  }
}

@Component({
  selector: 'app-content-card',
  templateUrl: './content-card.component.html'
})
export class ContentCardComponent implements OnInit {

  content: ContentCardModel;
  contents = (fishArticles as any).default;
  mixedContent: any;

  constructor(private activatedRoute: ActivatedRoute,
              private http: HttpClient,
              private seoService: SeoService) {
  }

  ngOnInit(): void {
    // Object.keys(this.contents).forEach(c => {
    //   this.mixedContent = {
    //     ...this.mixedContent,
    //     ...this.contents[c]
    //   }
    // })
    // this.activatedRoute.url.subscribe((url) => {
    //   this.content = new ContentCardModel(this.mixedContent[url[0].path].title, '');
    //   this.seoService.updateTitle(this.content.title);
    //   if (this.mixedContent[url[0].path].description && this.mixedContent[url[0].path].description.length > 0) {
    //     this.seoService.updateDescription(this.mixedContent[url[0].path].description);
    //   }
    //   this.http.get('assets/content/test' + '.html', {responseType: 'text'}).subscribe((resp: any) => {
    //     this.content.content = resp;
    //   }, error => console.log(error));
    // })

  }
}
