import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Order } from '../model/Order';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class OrderService {

  constructor(private httpClient: HttpClient) {
  }

  getOrders(): Observable<Array<Order>> {
    return this.httpClient.get<Array<Order>>('/api/user/orders');
  }

  convertNameToLink(productName: string): string {
    return productName
      .toLowerCase()
      .trim()
      .replace('á', 'a')
      .replace('é', 'e')
      .replace('ő', 'o')
      .replace('ó', 'o')
      .replace('í', 'i')
      .replace('ű', 'u')
      .replace('ú', 'u')
      .replace(' ', '_');
  }
}
