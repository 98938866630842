
<div class="row flex-column mt-5" *ngFor="let p of products; let last = last">
  <mat-divider class="w-50"></mat-divider>
  <div class="d-flex mt-3">
    <div class="col-2">

      <!--    <img src="{{p.primaryPicture}}" alt="">-->
      <img class="commonListProductPicture" src="/assets/images/product/k-karp_019-25-950_parent_gladio_tx_micro_bite_indicator_set.jpg" alt="">
    </div>
    <div class="col-12 mt-2">
      <a [routerLink]="'/termek/' + p.articleNumber">{{p.name}}</a>
      <div class="d-flex align-items-center">
        <ngx-input-star-rating [disabled]="true" [value]="calculateAverageRating(p)" class="mb-2"></ngx-input-star-rating>
        <span>{{products.length}} Értékelés</span>
      </div>
    </div>
    <div>
      <ng-content></ng-content>
    </div>
  </div>
  <mat-divider class="w-50" *ngIf="last"></mat-divider>
</div>

