<div class="container categories">
  <div class="row">

    <div class="container">
  <div class="row">
<!--    <div class="col-12 col-lg-8 col-xxl-6" style="border: 1px solid green">-->
<!--    <div class="d-flex justify-content-center flex-wrap">-->

      <app-category-card (navigateEvent)="navigateToSubCategory($event)" class="col-12 col-md-12 col-lg-8 col-xxl-6" *ngFor="let c of categories" [category]="c">

      </app-category-card>
<!--      <div class="card w-100 mb-4" (mouseover)="openSubCats()">-->
<!--        <img src="{{image}}" alt="alt">-->

<!--        <div class="card-img-overlay d-flex" (mouseleave)="closeSubCats()">-->

<!--          <div class="align-self-end w-100" [@toggleSubCats]=currentState>-->
<!--            <h2 class="card-title" (click)="navigateToSubCategory()">Horgászbotok</h2>-->
<!--            <div class="card-text">-->
<!--              <ul class="list-inline">-->
<!--                <li (click)="navigateToSubCategory()">Bojlis</li>-->
<!--                <li (click)="navigateToSubCategory()">Feeder</li>-->
<!--                <li (click)="navigateToSubCategory()">Harcsázó, kuttyogató</li>-->
<!--                <li (click)="navigateToSubCategory()">Legyező</li>-->
<!--                <li (click)="navigateToSubCategory()">Match</li>-->
<!--                <li (click)="navigateToSubCategory()">Pergető</li>-->
<!--                <li (click)="navigateToSubCategory()">Rakós, Bolognai</li>-->
<!--                <li (click)="navigateToSubCategory()">Spicc</li>-->
<!--                <li (click)="navigateToSubCategory()">Teleszkópos</li>-->
<!--              </ul>-->
<!--            </div>-->

<!--          </div>-->
<!--        </div>-->
<!--      </div>&lt;!&ndash; card END &ndash;&gt;-->

<!--    </div>&lt;!&ndash; col END &ndash;&gt;-->


  </div>
    </div>

  </div>
</div>
<!--container-fluid-->
