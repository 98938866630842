import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BsDropdownDirective, BsDropdownModule } from 'ngx-bootstrap/dropdown';
import { TooltipModule } from 'ngx-bootstrap/tooltip';
import { ButtonsModule } from 'ngx-bootstrap/buttons';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgxMatIntlTelInputModule } from 'ngx-mat-intl-tel-input';
import { RouterModule } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';
import { MatButtonModule } from '@angular/material/button';
import { SiteNavigationComponent } from './site-navigation/site-navigation.component';
import { NgxSliderModule } from '@angular-slider/ngx-slider';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatRippleModule } from '@angular/material/core';
import { MatRadioModule } from '@angular/material/radio';
import { BrowserModule } from '@angular/platform-browser';
import { AvatarModule } from 'ngx-avatar';
import { MatDividerModule } from '@angular/material/divider';
import { MatListModule } from '@angular/material/list';
import { MatTabsModule } from '@angular/material/tabs';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatExpansionModule } from '@angular/material/expansion';
import { ToastrModule } from 'ngx-toastr';
import { NgxInputStarRatingModule } from 'ngx-input-star-rating';
import { ModalModule } from 'ngx-bootstrap/modal';
import { OutsideClickDirective } from './outside-click.directive';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { MatStepperModule } from '@angular/material/stepper';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';

const importExport = [
  BrowserModule,
  BrowserAnimationsModule,
  FormsModule,
  ReactiveFormsModule,
  NgxMatIntlTelInputModule,
  RouterModule,
  TranslateModule,
  MatButtonModule,
  NgxSliderModule,
  MatCheckboxModule,
  MatRippleModule,
  TooltipModule,
  MatRadioModule,
  ButtonsModule,
  CommonModule,
  AvatarModule,
  MatDividerModule,
  MatListModule,
  MatTabsModule,
  MatSidenavModule,
  MatExpansionModule,
  ToastrModule,
  NgxInputStarRatingModule,
  MatButtonToggleModule,
  MatStepperModule,
  MatFormFieldModule,
  MatInputModule,
  BrowserAnimationsModule

];

@NgModule({
  declarations: [
    SiteNavigationComponent,
    OutsideClickDirective
  ],
  imports: [
    CommonModule,
    BsDropdownModule.forRoot(),
    TooltipModule.forRoot(),
    ButtonsModule.forRoot(),
    ModalModule.forRoot(),
    importExport
  ],
  exports: [
    BsDropdownModule,
    importExport,
    SiteNavigationComponent,
    OutsideClickDirective
  ],
  providers: [
    BsDropdownDirective,
  ]
})
export class SharedModule { }
