import { Injectable } from '@angular/core';
import { ActiveFilter } from '../model/ActiveFilter';
import { Filter } from '../model/Filter';
import { FilterPropertyState } from '../model/FilterPropertyState';

@Injectable({
  providedIn: 'root'
})
export class FilterService {

  constructor() {
  }

  initActiveFilter(filter: Filter): ActiveFilter {
    const activeFilter = new ActiveFilter();

    Object.keys(filter.properties).forEach(k => {
      activeFilter.properties[k] = new Array<FilterPropertyState>();
      filter.properties[k].forEach(p => activeFilter.properties[k].push(new FilterPropertyState(p, false)));
    });

    this.initPriceRange(activeFilter, filter);

    return activeFilter;
  }

  getFilterIsEmpty(activeFilter: ActiveFilter, filter: Filter): boolean {
    if (activeFilter.priceRange.min !== filter.priceRange.min ||
      activeFilter.priceRange.max !== filter.priceRange.max ||
      Object.keys(activeFilter.properties).some(k => activeFilter.properties[k].some(prop => prop.isChecked))) {
      return false;
    } else {
      return true;
    }
  }

  initPriceRange(activeFilter: ActiveFilter, filter: Filter): void {
    activeFilter.priceRange.min = filter.priceRange.min;
    activeFilter.priceRange.max = filter.priceRange.max;

    Object.keys(activeFilter.properties).forEach(k => {
      activeFilter.properties[k].forEach(p => {
        p.isChecked = false;
      });
    });
  }
}
