<mat-accordion>
  <mat-expansion-panel *ngFor="let c of categories"
                       (opened)="panelOpenState = true"
                       (closed)="panelOpenState = false">
    <mat-expansion-panel-header matRipple [matRippleUnbounded]="false" [matRippleCentered]="true" [matRippleRadius]="200">
      <mat-panel-title>
        {{c.name}}
      </mat-panel-title>
    </mat-expansion-panel-header>
    <ul *ngIf="c.subCategories && c.subCategories.length > 0" class="list-unstyled" role="menu">
      <li *ngFor="let sc of c.subCategories" role="menuitem" matRipple><span class="dropdown-item" (click)="navigateToSubcategory(c.link + '/' + sc.link)">{{sc.name}}</span></li>
    </ul>
  </mat-expansion-panel>
</mat-accordion>
