<div class="p-2 d-flex justify-content-between align-items-center bg-gradient-secondary text-white">

  <div>
  <i class="fa fa-2x fa-shopping-cart"></i>
    <sup class="badge badge-danger badge-pill badge-amount">{{numberOfProducts}}</sup>
  <span class="pl-5 lead">{{ 'kosár' }}</span>
  </div>

  <div>
  <i class="fa fa-2x fa-times" matRipple [matRippleUnbounded]="false" [matRippleCentered]="true" (click)="cartSidenav.close()"></i>

  </div>

</div>

<div *ngIf="productsInCart.length > 0" class="d-flex justify-content-between flex-column sidecart-container">


      <div *ngFor="let p of productsInCart">
      <div class="media pt-4">

        <img src="assets/images/thumbs/k-karp_019-25-950_parent_gladio_tx_micro_bite_indicator_set.jpg" class="align-self-start mr-3" alt="{{productsById[p.productId].name}}">

        <div class="media-body">

          <button mat-icon-button color="warn" class="float-right" aria-label="Termék törlése gomb" (click)="removeProductFromCart(productsById[p.productId].articleNumber)" tooltip="Termék törlése" [delay]="1000">
            <i class="fa fa-lg fa-trash"></i>
          </button>

<!--          <span class="float-right pt-3" (click)="removeProductFromCart(productsById[p.productId].articleNumber)" tooltip="Termék törlése" [delay]="1000"><i class="fa fa-trash text-danger"></i></span>-->
          <h5 class="mt-0">{{productsById[p.productId].name}}</h5>
          <p>Hossz m 20 / Mérete	50</p>
          {{p.amount}} x <span class="text-danger">{{productsById[p.productId].salePrice || productsById[p.productId].grossPrice}} Ft</span>
        </div>

        </div><!-- media END-->
        <hr>
      </div><!-- ngif END-->

      <div class="sidecart-bottom fixed-bottom">
       <hr>
        <div class="d-flex justify-content-between w-100">
        <div class="lead">Részösszeg:</div>
        <div class="text-danger">26 782 Ft</div>
        </div>

        <button class="btn btn-danger btn-rounded w-100" *ngIf="numberOfProducts > 0" (click)="navigateToCheckout()"><i class="fa fa-chevron-right"></i> {{'cart.toCheckout' | translate}}</button>



      </div>



<!--  <div *ngIf="(productsInCart | keyvalue).length === 0">-->
<!--    {{"cart.empty" | translate}}-->
<!--  </div>-->

</div>
<!--sidecart-container END-->

