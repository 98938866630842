<form [formGroup]="personalDataForm" (submit)="savePersonalData()">
  <div class="row d-flex flex-column form-group">
    <input class="col-6 form-control form-control-sm mb-4" type="text" formControlName="name" [placeholder]="'Name'">
    <input class="col-6 form-control form-control-sm mb-4" type="text" formControlName="email" [placeholder]="'Email'">
    <input class="col-6 form-control form-control-sm mb-4" type="text" formControlName="phoneNumber" [placeholder]="'Phone number'">
  </div>
  <div class="row">
    <div>
      <button class="btn btn-primary mr-5" type="submit" [disabled]="!personalDataForm.dirty && !personalDataForm.invalid || personalDataForm.dirty && personalDataForm.invalid">Mentés</button>
    </div>
    <div>
      <button class="btn btn-danger ml-5" [disabled]="!personalDataForm.dirty" (click)="resetPersonalData()">Mégse</button>
    </div>
  </div>
</form>
