<div class="col-24">
  <div class="col-24 d-flex flex-column" *ngIf="company">
    <div class="col-6 d-flex flex-row justify-content-between">
      <div>Cégnév</div>
      <div>{{company.name}}</div>
    </div>
    <div class="col-6 d-flex flex-row justify-content-between">
      <div>Adószám</div>
      <div>{{company.taxNumber}}</div>
    </div>
    <div class="col-6 d-flex flex-row justify-content-between">
      <div>Cím</div>
      <div>{{company.address.streetAddress}}</div>
    </div>
  </div>
</div>
