import { UserActions, UserActionTypes } from './UserActions';
import { Profile } from '../../model/Profile';
import { Order } from '../../model/Order';
import { UserReview } from '../../model/UserReview';
import { User } from '../../model/User';

export interface UserState {
  isLoggedIn: boolean;
  user: User;
  profile: Profile;
  orders: Array<Order>;
  ratings: Array<UserReview>;
}

export const initialState: UserState = {
  isLoggedIn: false,
  user: null,
  profile: null,
  orders: [],
  ratings: []
};

export function userReducer(state: UserState = initialState, action: UserActions): UserState {
  switch (action.type) {
    case UserActionTypes.Login: {
      return {
        ...state,
        isLoggedIn: true,
        user: action.user
      };
    }
    case UserActionTypes.Logout: {
      return {
        ...state,
        isLoggedIn: false,
        orders: null,
        profile: null,
        ratings: null
      };
    }
    case UserActionTypes.InitProfile: {
      return {
        ...state,
        profile: action.profile
      };
    }
    case UserActionTypes.UpdatePersonalData: {
      return {
        ...state,
        profile: {
          ...state.profile,
          personalData: action.personalData
        }
      };
    }
    case UserActionTypes.InitOrders: {
      return {
        ...state,
        orders: action.orders
      };
    }
    case UserActionTypes.InitRatings: {
      return {
        ...state,
        ratings: action.ratings
      };
    }
    case UserActionTypes.AddAddress: {
      return {
        ...state,
        profile: {
          ...state.profile,
          addresses: [
            action.address,
            ...state.profile.addresses
          ]
        }
      };
    }
    case UserActionTypes.RemoveAddress: {
      return {
        ...state,
        profile: {
          ...state.profile,
          addresses: state.profile.addresses.filter(a => a.id !== action.addressId)
        }
      };
    }
    case UserActionTypes.ModifyAddress: {
      return {
        ...state,
        profile: {
          ...state.profile,
          addresses: state.profile.addresses.map(a => a.id == action.address.id ?
            {
              ...a,
              streetAddress: action.address.streetAddress,
              city: action.address.city,
              postalCode: action.address.postalCode,
              email: action.address.email,
              country: action.address.country
            } : a)
        }
      };
    }
  }
  return state;
}

export const isLoggedIn = (state: UserState) => state.isLoggedIn;
export const initUser = (state: UserState) => state.user;
export const initProfile = (state: UserState) => state.profile;
export const initOrders = (state: UserState) => state.orders;
export const initRatings = (state: UserState) => state.ratings;
