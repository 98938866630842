import { CartActions, CartActionTypes } from './CartActions';
import { ProductInCart } from '../../model/ProductInCart';
import { Cart } from '../../model/Cart';

export interface CartState {
  productsInCart: Cart
}

export const initialState: CartState = {
  productsInCart: {}
};

export function cartReducer(state: CartState = initialState, action: CartActions): CartState {
  switch (action.type) {
    case CartActionTypes.AddProductToCart: {
      const existingProductInCart: ProductInCart = state.productsInCart[action.articleNumber];
      return {
        ...state,
        productsInCart: {
          ...state.productsInCart,
          [action.articleNumber]: existingProductInCart ? {
            ...existingProductInCart,
            amount: existingProductInCart.amount + action.productInCart.amount
          } : action.productInCart
        }
      };
    }
    case CartActionTypes.RemoveProductFromCart: {
      const { [action.articleNumber]: toRemoved, ...rest} = state.productsInCart;
      return {
        ...state,
        productsInCart: {
          ...rest
        }
      };
    }
    case CartActionTypes.ChangeAmountOfProduct: {
      return {
        ...state,
        productsInCart: {
          ...state.productsInCart,
          [action.articleNumber]: {
            ...state.productsInCart[action.articleNumber],
            amount: action.amount
          }
        }
      };
    }
  }
  return state;
}

export const initCart = (state: CartState) => state.productsInCart;

