import { Component, Input, OnInit } from '@angular/core';
import { UserReview } from '../../../model/UserReview';

@Component({
  selector: 'app-user-rating-item',
  templateUrl: './user-rating-item.component.html',
  styleUrls: ['./user-rating-item.component.scss']
})
export class UserRatingItemComponent implements OnInit {

  @Input()
  rating: UserReview;

  constructor() { }

  ngOnInit(): void {
  }
}
