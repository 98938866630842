<nav aria-label="breadcrumb" *ngIf="navigations && navigations.length > 0">
  <ol class="breadcrumb breadcrumb-arrow">
    <li><a [routerLink]="'/'"><i class="fa fa-lg fa-home"></i>Kezdőlap</a></li>
    <li *ngFor="let n of navigations; index as i; let last = last">
      <a *ngIf="n.link !== ''" [routerLink]="'/' + n.link"><i class="fa fa-lg fa-home"></i>{{n.name}}</a>
      <span *ngIf="n.link === ''">{{n.name}}</span>
    </li>
  </ol>
</nav>



