import * as navigations from './navigations.json';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ContentCardModel } from '../content-card/content-card.component';
import { SeoService } from '../../../service/seo.service';
import { HttpClient } from '@angular/common/http';
import { ContentService } from '../content.service';
import { NavigationElement } from '../../../model/NavigationElement';

@Component({
  selector: 'app-common-template',
  templateUrl: './common-template.component.html',
  styleUrls: ['./common-template.component.scss']
})
export class CommonTemplateComponent implements OnInit {

  title: string;
  navigations: {[menu: string]: {[subMenu: string]: { [article: string]: NavigationElement }}} = (navigations as any).default;
  actualNavigations: Array<NavigationElement>;

  menu: string;
  subMenu: string;

  content: ContentCardModel;

  selectedArticle: string;
  // mixedNavigations: any;

  constructor(private readonly activatedRoute: ActivatedRoute,
              private readonly seoService: SeoService,
              private readonly http: HttpClient,
              private readonly contentService: ContentService,
              private router: Router) { }

  ngOnInit(): void {
    this.title = this.activatedRoute.snapshot.url[1].path;
    this.content = new ContentCardModel('', '');
    try {
      this.actualNavigations = Object.values(this.navigations[this.activatedRoute.snapshot.url[0].path][this.activatedRoute.snapshot.url[1].path]);
    } catch (err) {
      console.log(err);
    }

    this.menu = this.activatedRoute.snapshot.url[0].path;
    this.subMenu = this.activatedRoute.snapshot.url[1].path;
    if (!this.activatedRoute.snapshot.url[2]) {
      this.router.navigateByUrl(this.menu + '/' + this.subMenu + '/' + Object.keys(this.navigations[this.menu][this.subMenu])[0]);
    }

    // Object.keys(this.navigations).forEach(m => {
    //   Object.keys(this.navigations[m]).forEach((sm) => {
    //     this.mixedNavigations = {
    //       ...this.mixedNavigations,
    //       ...this.navigations[m][sm]
    //     }
    //   })
    // })
    this.activatedRoute.url.subscribe((url) => {
      // this.content = new ContentCardModel(this.mixedNavigations[url[0].path].title, '');
      this.menu = url[0].path;
      this.subMenu = url[1].path;
      // if (!url[2]) {
      //   return;
      // }
      // const selectedArticle = url[2] ? url[2].path : Object.keys(this.navigations[this.menu][this.subMenu])[0];
      this.selectedArticle = url[2] ? url[2].path : Object.keys(this.navigations[this.menu][this.subMenu])[0];

      this.actualNavigations = Object.values(this.navigations[this.menu][this.subMenu]);
      // const selectedArticle = url[2].path;
      // const test = Object.values(this.navigations[url[0].path][url[1].path]);
      // this.content = new ContentCardModel(this.navigations[url[0].path][url[1].path][selectedArticle].name, '');
      this.content.title = this.navigations[this.menu][this.subMenu][this.selectedArticle].title;
      this.seoService.updateTitle(this.content.title);
      // if (this.mixedNavigations[url[0].path].description && this.mixedNavigations[url[0].path].description.length > 0) {
      //   this.seoService.updateDescription(this.mixedNavigations[url[0].path].description);
      // }
      this.http.get(`assets/content/${this.selectedArticle}` + '.html', {responseType: 'text'}).subscribe((resp: any) => {
        this.content.content = resp;
      }, error => console.log(error));
    })
  }
}
