import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve, RouterStateSnapshot } from '@angular/router';
import { Observable } from 'rxjs';
import { Order } from '../model/Order';

@Injectable({
  providedIn: 'root'
})
export class MyOrderResolverService implements Resolve<any> {

  constructor() {
  }

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<any> | Promise<any> | any {
    return MyOrderResolverService.getTestOrders();
  }

  private static getTestOrders(): Array<Order> {
    return [
      {
        orderId: 'asd',
        status: 'DONE',
        address: {
          id: 56,
          country: 'HU',
          streetAddress: 'Address',
          city: 'Miskolc',
          postalCode: 3525,
          email: 'email'
        },
        billingAddress: {
          id: 28,
          country: 'HU',
          streetAddress: 'Address',
          city: 'Miskolc',
          postalCode: 3525,
          email: 'email'
        },
        creationDate: new Date(),
        products: [],
        total: 34550,
        comment: 'comment'
      },
      {
        orderId: 'qwe',
        status: 'NEW',
        address: {
          id: 232,
          country: 'HU',
          streetAddress: 'Address',
          city: 'Miskolc',
          postalCode: 3525,
          email: 'email'
        },
        billingAddress: {
          id: 111,
          country: 'HU',
          streetAddress: 'Address',
          city: 'Miskolc',
          postalCode: 3525,
          email: 'email'
        },
        creationDate: new Date(),
        products: [],
        total: 24550,
        comment: 'comment'
      }
    ]
  }
}
