import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { APP_INITIALIZER, NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { StoreModule } from '@ngrx/store';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { HttpClient, HttpClientModule } from '@angular/common/http';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { productReducer } from './store/product/ProductReducer';
import { CoreModule } from './modules/core/core.module';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatExpansionModule } from '@angular/material/expansion';
import { NgxPaginationModule } from 'ngx-pagination';
import { LocationStrategy, PathLocationStrategy } from '@angular/common';
import { CategoryService } from './service/category.service';
import { categoryReducer } from './store/category/CategoryReducer';
import { paginationReducer } from './store/pagination/PaginationReducer';
import { cartReducer } from './store/cart/CartReducer';
import { userReducer } from './store/user/UserReducer';
import { ApplicationService } from './service/application.service';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { propertyReducer } from './store/property/PropertyReducer';
import { filterReducer } from './store/filter/FilterReducer';
import { RouteReuseStrategy } from '@angular/router';
import { ReuseStrategyService } from './service/reuse.strategy.service';
import { ToastrModule } from 'ngx-toastr';
import { AvatarModule } from 'ngx-avatar';
import { SharedModule } from './modules/shared/shared.module';

export function initApplicationFactory(applicationService: ApplicationService) {
  return async (): Promise<void> => await applicationService.initApplication();
}

export function translateHttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http, './assets/i18n/', '.json');
}



@NgModule({
  declarations: [
    AppComponent
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    CoreModule,
    NgxPaginationModule,
    StoreModule.forRoot({}),
    StoreModule.forFeature('product', productReducer),
    StoreModule.forFeature('property', propertyReducer),
    StoreModule.forFeature('filter', filterReducer),
    StoreModule.forFeature('category', categoryReducer),
    StoreModule.forFeature('cart', cartReducer),
    StoreModule.forFeature('user', userReducer),
    StoreModule.forFeature('pagination', paginationReducer),
    StoreDevtoolsModule.instrument({
      maxAge: 25, // Retains last 25 states
      // logOnly: environment.production, // Restrict extension to log-only mode
    }),
    HttpClientModule,
    FormsModule,
    MatExpansionModule,
    MatSidenavModule,
    SharedModule,
    ReactiveFormsModule,
    AvatarModule.forRoot(),
    ToastrModule.forRoot(),
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: translateHttpLoaderFactory,
        deps: [HttpClient]
      }
    })
  ],
  providers: [
    {
      provide: LocationStrategy,
      useClass: PathLocationStrategy
    },
    {
      provide: APP_INITIALIZER,
      useFactory: initApplicationFactory,
      deps: [ApplicationService, CategoryService],
      multi: true
    },
    {
      provide: RouteReuseStrategy,
      useClass: ReuseStrategyService,
    }
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
