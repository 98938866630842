<div>
  <div *ngFor="let rating of ratings; let last = last;">
    <mat-divider class="w-50"></mat-divider>
    <div class="d-flex mt-3">
      <div class="col-2">

        <!--    <img src="{{p.primaryPicture}}" alt="">-->
        <img class="commonListProductPicture" src="/assets/images/product/k-karp_019-25-950_parent_gladio_tx_micro_bite_indicator_set.jpg" alt="">
      </div>
      <div class="col-12 mt-2">
        <a [routerLink]="'/termek/' + rating.product.articleNumber">{{rating.product.name}}</a>
        <div class="d-flex align-items-center">
          <ngx-input-star-rating [disabled]="true" [value]="calculateAverageRating(rating.product)" class="mb-2"></ngx-input-star-rating>
          <span>{{rating.product.reviews.length}} Értékelés</span>
        </div>
        <div>
          {{rating.review}}
        </div>
      </div>
    </div>
    <mat-divider class="w-50" *ngIf="last"></mat-divider>
  </div>
</div>
