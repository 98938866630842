import { createFeatureSelector, createSelector } from '@ngrx/store';
import { FilterState, getDedicatedFilters, getFilters } from './FilterReducer';

export const selectFilterState = createFeatureSelector<FilterState>('filter');

export const initFiltersSelector = createSelector(
  selectFilterState,
  getFilters
);

export const initDedicatedFiltersSelector = createSelector(
  selectFilterState,
  getDedicatedFilters
);
