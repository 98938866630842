<div class="card w-100 mb-4 card-sub" (click)="navigateToCategory()">
  <img src="{{category.image}}" alt="{{(category.link).replace('-', ' ')}}">

  <div class="card-img-overlay d-flex">

    <div class="align-self-end w-100">
      <h2 class="card-title">{{category.name}}</h2>
      <div class="card-text">
        <ul class="list-inline">
          <li *ngFor="let sc of category.subCategories" (click)="navigateToSubcategory(category.link, sc.link)">{{sc.name}}</li>
        </ul>
      </div>
    </div>
  </div>
</div><!-- card END -->
