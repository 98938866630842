import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { initCartSelector } from '../../../store/cart/CartSelector';
import { Store } from '@ngrx/store';
import { CartState } from '../../../store/cart/CartReducer';
import { Router } from '@angular/router';
import { FormService } from '../../../service/form.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-checkout',
  templateUrl: './checkout.component.html',
  styleUrls: ['./checkout.component.scss']
})
export class CheckoutComponent implements OnInit, OnDestroy {

  fullForm: FormGroup;
  isDeliveryAddressTheSame: boolean = false;
  isCompany: boolean;
  cart: any;
  selectedCountry: string = 'hu';
  selectedAddress: any = '';
  selectedPhoneCountry = ''
  //   = Examples.find(e => {
  //   console.log(e)
  //   return e.name === 'hu'
  // });

  private subscriptions: Array<Subscription> = new Array<Subscription>();

  constructor(private fb: FormBuilder,
              private readonly cartStore: Store<CartState>,
              private readonly router: Router,
              private readonly formService: FormService) {
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach(s => s.unsubscribe());
  }

  ngOnInit(): void {
    this.subscriptions.push(
      this.cartStore.select(initCartSelector).subscribe((cart) => {
        this.cart = cart;
        if (Object.values(this.cart).length === 0) {
          this.router.navigateByUrl('');
        }
      })
    )
    this.fullForm = this.formService.getCheckoutForm();
  }

  continue() {
    console.log(this.fullForm);
  }

  changeValue(e) {
    this.isDeliveryAddressTheSame = e;
  }
}
