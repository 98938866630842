export class User {
  userId: string;
  username: string;
  isFacebookUser: boolean;
  photoUrl: string;

  constructor(userId: string, username: string, isFacebookUser?: boolean, photoUrl?: string) {
    this.userId = userId;
    this.username = username;
    this.isFacebookUser = isFacebookUser;
    this.photoUrl = photoUrl;
  }
}

export class FacebookUser {
  photoUrl: string;
}
