import { Injectable } from '@angular/core';
import { CategoryService } from './category.service';
import { Store } from '@ngrx/store';
import { CartState } from '../store/cart/CartReducer';
import { ProductState } from '../store/product/ProductReducer';
import { LoadProducts } from '../store/product/ProductActions';
import { TEST_PRODUCTS } from '../test';
import { Product } from '../model/Product';
import { ProductKey } from '../model/ProductKey';
import { HttpClient } from '@angular/common/http';
import { of } from 'rxjs';
import { PROPERTIES_BY_CODE } from '../PropertiesByCode';
import { CategoryState } from '../store/category/CategoryReducer';
import { InitCategories } from '../store/category/CategoryActions';
import { TranslateService } from '@ngx-translate/core';
import { PropertyState } from '../store/property/PropertyReducer';
import { InitProperties } from '../store/property/PropertyActions';
import { Properties } from '../model/Properties';
import { FilterState } from '../store/filter/FilterReducer';
import { InitFilters } from '../store/filter/FilterActions';
import { FILTERS } from '../mock/FiltersByCategories';

@Injectable({
  providedIn: 'root'
})
export class ApplicationService {

  constructor(private categoryService: CategoryService,
              private readonly cartStore: Store<CartState>,
              private readonly productStore: Store<ProductState>,
              private readonly categoryStore: Store<CategoryState>,
              private readonly propertyStore: Store<PropertyState>,
              private readonly filterStore: Store<FilterState>,
              private readonly http: HttpClient,
              private translateService: TranslateService) {
  }

  async initApplication() {
    const preload = await this.preload();
    // setTimeout(() => {
    //   console.log('this.translateService.translations');
    //   console.log(this.translateService.translations);
    //   const transl = this.translateService.translations;
    //
    //   console.log(preload.properties)
    // }, 1000);

    // this.translateService.getTranslation('hu_HU').subscribe((resp) => {
    //   console.log(resp);
    //   this.translateService.set()
    // })

    setTimeout(() => {
      Object.keys(preload.properties).forEach((k: string) => {
        // this.translateService.set(k, preload.properties[k].hu_HU, 'hu_HU');
        this.translateService.set(k, preload.properties[k].hu_HU);
        // this.translateService.set(k, preload.properties[k].en_EN, 'en_EN');
      })
    }, 1000)


    this.propertyStore.dispatch(new InitProperties(preload.properties))
    this.categoryStore.dispatch(new InitCategories(preload.categories));
    this.filterStore.dispatch(new InitFilters(preload.filters.filters, preload.filters.dedicatedFilters));


    // this.categoryService.initCategories();


    // this.productStore.dispatch(new LoadProducts(new Map<string, Product>TEST_PRODUCTS.map(tp => Product.buildProduct(tp)).map(p => [p.id, p])))

    // TEST PRODUCTS
    const products = TEST_PRODUCTS.map(tp => Product.buildProduct(tp));
    const subProducts: Array<Product> = new Array<Product>();
    products.forEach(p => {
      p.item.forEach(sp => {
        subProducts.push(sp);
      })
    })
    this.productStore.dispatch(new LoadProducts(new ProductKey(
      [
        ...products,
        ...subProducts
      ]
    )));
  }

  getProperties() {
    // return this.http.get('/api/properties');
    return of(PROPERTIES_BY_CODE);
  }

  preload() {
    // return this.http.get('/api/preload');
    return of ({
      // @ts-ignore
      properties: PROPERTIES_BY_CODE as Properties,
      categories: this.categoryService.getCategories(),
      filters: FILTERS
    }).toPromise();
  }
}
