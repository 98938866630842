import { LabelsByLanguageCode } from './LabelsByLanguageCode';

export class Properties {
  [k: string]: LabelsByLanguageCode;

  constructor(props: any) {
    Object.keys(props).forEach(p => {
      this[p] = props[p];
    });
  }
}
