import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve, RouterStateSnapshot } from '@angular/router';
import { Observable } from 'rxjs';
import { ProductService } from './product.service';
import { CategoryState } from '../store/category/CategoryReducer';
import { Store } from '@ngrx/store';
import { initCategoriesSelector } from '../store/category/CategorySelector';
import { Category } from '../model/Category';

@Injectable()
export class ProductListResolver implements Resolve<any> {

  categories: Array<Category>;

  constructor(private productService: ProductService,
              private store: Store<CategoryState>) {
  }

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<any> | Promise<any> | any {
    let categoryParam;
    let subCategoryParam;
    let category;

    if (route.routeConfig.path === 'kereses') {
      // TODO test query
      return this.productService.searchProducts('category.id');
    }
    this.store.select(initCategoriesSelector).subscribe((categories) => {
      categoryParam = route.params['category'];
      subCategoryParam = route.params['subCategory'];
      category = categories.find(c => c.link === categoryParam);
    })
    if (!subCategoryParam) {
      return this.productService.getProductsByCategory(category.id);
    } else {
      const subCategory = category.subCategories.find(c => c.link === subCategoryParam);
      return this.productService.getProductsByCategory(subCategory.id);
    }
  }
}
