import { Component, OnInit } from '@angular/core';
import { SiteNavigationModel } from '../../../model/SiteNavigationModel';
import { ActivatedRoute, Router } from '@angular/router';
import { initCategoriesSelector } from '../../../store/category/CategorySelector';
import { Category } from '../../../model/Category';
import { Store } from '@ngrx/store';
import { CategoryState } from '../../../store/category/CategoryReducer';
import { ProductState } from '../../../store/product/ProductReducer';
import { initProductsSelector } from '../../../store/product/ProductSelector';
import { ProductKey } from '../../../model/ProductKey';

@Component({
  selector: 'app-site-navigation',
  templateUrl: './site-navigation.component.html'
})
export class SiteNavigationComponent implements OnInit {

  navigations: Array<SiteNavigationModel>;

  allCategories: Category[] = [];
  categoriesMap: {[k: string]: Category};
  categories: {[k: string]: Category};
  subCategories: {[k: string]: Category};

  products: ProductKey;

  constructor(private activatedRoute: ActivatedRoute,
              private router: Router,
              private readonly categoryStore: Store<CategoryState>,
              private readonly productStore: Store<ProductState>) {
  }

  ngOnInit(): void {
    this.productStore.select(initProductsSelector).subscribe((products) => {
      this.products = products;
    });
    this.categoryStore.select(initCategoriesSelector).subscribe((categories: Array<Category>) => {
      this.allCategories = categories;
      this.categories = {};
      this.subCategories = {};
      this.allCategories.forEach(c =>  {
        this.categories[c.link] = c;
        c.subCategories.forEach(sc => {
          this.subCategories[sc.link] = sc;
        });
      });
    });
    this.activatedRoute.url.subscribe((res) => {
      if (res[0].path === 'kereses') {
        return;
      }
      if (res.length === 1) {
        this.navigations = [new SiteNavigationModel(this.allCategories.find(c => c.link === res[0].path).name, '')];
        return;
      }
      if (res.length === 2) {
        if (/\d/.test(res[1].path)) {
          const mainCategory = this.categories[res[0].path];
          this.navigations = [
            new SiteNavigationModel(mainCategory.name, mainCategory.link)
          ];
        } else {
          this.navigations = [
            new SiteNavigationModel(this.categories[res[0].path].name, res[0].path),
            new SiteNavigationModel(this.subCategories[res[1].path].name, '')
          ];
        }
        return;
      }
      if (res.length === 3) {
        const product = this.products[res[2].path];
        if (!product) {
          this.router.navigateByUrl('not-found');
          return;
        }

        const mainCategory = this.categories[res[0].path];
        const subCategory = this.subCategories[res[1].path];
        this.navigations = [
          new SiteNavigationModel(mainCategory.name, mainCategory.link),
          new SiteNavigationModel(subCategory.name, mainCategory.link + '/' + subCategory.link)
        ];
        return;
      }
    })
  }

}
