<div>
  <div>
    {{rating.creationDate | date}}
  </div>
  <div>
    Termék neve:
    {{rating.product.name}}
  </div>
  <div>
<!--    csillagok helye: rating.rating -->
    Értékelés:
  </div>
  <div>
    {{rating.review}}
  </div>
</div>
<mat-divider></mat-divider>
