<div *ngIf="productsInCart.length > 0" class="d-flex justify-content-between flex-column sidecart-container">


  <div *ngFor="let p of productsInCart">
    <div class="media pt-4">

      <img src="assets/images/thumbs/k-karp_019-25-950_parent_gladio_tx_micro_bite_indicator_set.jpg" class="align-self-start mr-3" alt="{{productsById[p.productId].name}}">

      <div class="media-body">
        <span class="float-right pt-3" (click)="removeProductFromCart(productsById[p.productId].articleNumber)" tooltip="Termék törlése" [delay]="1000"><i class="fa fa-trash text-danger"></i></span>
        <h5 class="mt-0">{{productsById[p.productId].name}}</h5>
        <p>Hossz m 20 / Mérete	50</p>
        {{p.amount}} x <span class="text-danger">{{productsById[p.productId].salePrice || productsById[p.productId].grossPrice}} Ft</span>
      </div>

    </div>
    <hr>
  </div>
</div>
