import { Component, Input, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { PersonalData } from '../../../model/PersonalData';
import { FormService } from '../../../service/form.service';
import { Store } from '@ngrx/store';
import { UserState } from '../../../store/user/UserReducer';
import { UpdatePersonalData } from '../../../store/user/UserActions';

@Component({
  selector: 'app-personal-data',
  templateUrl: './personal-data.component.html',
  styleUrls: ['./personal-data.component.scss']
})
export class PersonalDataComponent implements OnInit {

  @Input()
  personalData: PersonalData;

  personalDataForm: FormGroup;

  constructor(private formService: FormService,
              private userStore: Store<UserState>) { }

  ngOnInit(): void {
    this.personalDataForm = this.formService.getPersonalDataForm(this.personalData);
  }

  savePersonalData() {
    this.userStore.dispatch(new UpdatePersonalData(this.personalDataForm.value))
    this.personalDataForm.reset(this.personalDataForm.value);
  }

  resetPersonalData() {
    this.personalDataForm.reset(this.personalData);
  }
}
