import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, of } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class AuthenticationService {

  constructor(private httpClient: HttpClient) {
  }

  login(username: string, password: string): Observable<void> {
    return this.httpClient.post<void>('/api/login', {username: username, password: password});
  }

  logout(): Observable<any> {
    // return this.httpClient.post<void>('/api/logout', {});
    return of({});
  }
}
