<div class="container-fluid product-list">

    <div class="row">
      <div class="col-xl-19">

        <div class="row">
          <div class="col-md-7 d-flex align-items-center p-0">
            <app-site-navigation></app-site-navigation>
          </div>


          <div class="col-md-10 d-flex align-items-end justify-content-center">
            <h1>{{title}}</h1>
          </div>

          <div class="col-md-7 d-flex justify-content-end align-items-center btn-nav">
            <app-order></app-order>
            <app-page-sizer [(ngModel)]="pagination.itemsPerPage" (ngModelChange)="updatePageSize($event)"></app-page-sizer>
          </div>

        </div>
        <hr>


          <div class="d-flex align-items-end justify-content-center mt-5">

            <mat-button-toggle-group *ngIf="dedicatedFilter" appearance="legacy" name="groups" aria-label="Csoportok">
              <mat-button-toggle value="all">Összes</mat-button-toggle>
              <mat-button-toggle value="{{p}}" *ngFor="let p of dedicatedFilter">{{p}}</mat-button-toggle>
            </mat-button-toggle-group>


          </div>


        <div class="row mt-5">
          <app-product class="col-12 col-md-8 col-lg-6 mb-5 d-flex" *ngFor="let p of products | paginate: pagination"
                       [product]="p"
                       [link]="categoryParam && subCategoryParam ? '/' + categoryParam + '/' + subCategoryParam : '/termek'"
          ></app-product>

          <div class="col-24">
            <pagination-controls [nextLabel]="''" #paginationControl class="pt-5 pb-5 text-center" (pageChange)="pageChanged($event)"></pagination-controls>
          </div>

        </div>

        <!--BRANDS-------------------------------------->
        <div class="brands">
          <app-brand-list></app-brand-list>
        </div>


      </div>

      <div class="col-xl-5 aside">

        <div class="row">
          <!--FILTERS-------------------------------------->
          <div class="col-md-12 col-xl-24" *ngIf="filter">
            <app-filter [(ngModel)]="activeFilter" [filter]="filter" (ngModelChange)="updateParams($event)"></app-filter>
          </div>

          <!--SUBCATEGORIES-------------------------------->
          <div class="col-md-12 col-xl-24">
        <div class="list-group" *ngIf="mainCategory">
          <app-sub-categories-list [category]="mainCategory" [activeSubcategory]="subCategoryParam"></app-sub-categories-list>
        </div>
          </div>

        </div>


      </div><!--  row END-->

    </div><!--  aside END-->


  </div>
  <!--  container-fluid END-->


