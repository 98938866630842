import { Component, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { OrderService } from '../../../service/order.service';
import { ActivatedRoute } from '@angular/router';
import { Order } from '../../../model/Order';

@Component({
  selector: 'app-my-orders',
  templateUrl: './my-orders.component.html',
  styleUrls: ['./my-orders.component.scss']
})
export class MyOrdersComponent implements OnInit {

  private subscriptions: Array<Subscription> = new Array<Subscription>();

  orders: Array<Order>;

  constructor(private activatedRoute: ActivatedRoute,
              private orderService: OrderService) {
  }

  ngOnInit(): void {
    this.orders = this.activatedRoute.snapshot.data.preload;
  }
}

