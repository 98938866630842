export class Category {
  id: number;
  name: string;
  longName: string;
  link: string;
  image: string;
  subCategories: Array<Category> | null;

  constructor(id: number, image: string, name: string, link: string, subCategories: Array<Category> = null) {
    this.id = id;
    this.name = name;
    this.longName = name;
    this.image = image;
    this.link = link;
    this.subCategories = subCategories;
  }
}
