import Amplify from '@aws-amplify/auth';
import { NgModule } from '@angular/core';
import { ProfileComponent } from './profile/profile.component';
import { AddressComponent } from './address/address.component';
import { ContactComponent } from './contact/contact.component';
import { LoginComponent } from './login/login.component';
import { RegisterComponent } from './register/register.component';
import { SharedModule } from '../shared/shared.module';
import { PersonalDataComponent } from './personal-data/personal-data.component';
import { AccountComponent } from './account/account.component';
import { MyOrdersComponent } from './my-orders/my-orders.component';
import { UserRatingsComponent } from './user-ratings/user-ratings.component';
import { CompanyComponent } from './company/company.component';
import { MyOrderItemComponent } from './my-order-item/my-order-item.component';
import { MyOrderDetailsComponent } from './my-order-details/my-order-details.component';
import { FacebookLoginProvider, SocialAuthServiceConfig, SocialLoginModule } from 'angularx-social-login';
import { UserRatingItemComponent } from './user-rating-item/user-rating-item.component';

Amplify.configure({Auth: {
    mandatorySignIn: true,
    region: 'eu-west-1',
    userPoolId: 'eu-west-1_rp5CvovCd',
    userPoolWebClientId: '2f46q83k0vsssbhr360nck09v2',
    identityPoolId: 'eu-west-1:54fb6939-97b7-438b-a011-31a23bcaa48d'
  }
});

@NgModule({
  declarations: [
    ProfileComponent,
    AddressComponent,
    ContactComponent,
    LoginComponent,
    RegisterComponent,
    PersonalDataComponent,
    AccountComponent,
    MyOrdersComponent,
    UserRatingsComponent,
    MyOrderItemComponent,
    UserRatingItemComponent,
    CompanyComponent,
    MyOrderDetailsComponent
  ],
  imports: [
    SharedModule,
    SocialLoginModule
  ],
  providers: [
    {
      provide: 'SocialAuthServiceConfig',
      useValue: {
        autoLogin: false,
        providers: [
          // {
          //   id: GoogleLoginProvider.PROVIDER_ID,
          //   provider: new GoogleLoginProvider(
          //     '323846816947-pd0uljsmgsm5t7p83tg1qeusmanktr1p.apps.googleusercontent.com'
          //   )
          // },
          {
            id: FacebookLoginProvider.PROVIDER_ID,
            provider: new FacebookLoginProvider('739353570092644')
          }
        ]
      } as SocialAuthServiceConfig,
    }
  ]
})
export class UserModule { }
