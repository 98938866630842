import { Action } from '@ngrx/store';
import { ProductInCart } from '../../model/ProductInCart';

export enum CartActionTypes {
  AddProductToCart = 'Add product to cart',
  RemoveProductFromCart = 'Remove product',
  ChangeAmountOfProduct = 'Change amount ',
}

export class AddProductToCart implements Action {
  readonly type = CartActionTypes.AddProductToCart;

  constructor(public articleNumber: string, public productInCart: ProductInCart) {
  }
}

export class RemoveProductFromCart implements Action {
  readonly type = CartActionTypes.RemoveProductFromCart;

  constructor(public articleNumber: string) {
  }
}

export class ChangeAmountOfProduct implements Action {
  readonly type = CartActionTypes.ChangeAmountOfProduct;

  constructor(public articleNumber: string, public amount: number) {
  }
}


export type CartActions =
  AddProductToCart |
  RemoveProductFromCart |
  ChangeAmountOfProduct;
